import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  webWrapper: {
    width: '70%',
    padding: 10,
  },
  wrapper: {
    padding: 10,
    width: '100%',
  },
  generalExerciseTitle: {
    fontSize: 18,
    fontFamily: 'gilroy-semibold',
  },
  listSection: {
    borderWidth: 1,
    borderColor: 'rgb(229,229,299)',
    overflow: 'hidden',
    borderTopColor: 'transparent',
  },
  accordionFilterContainer: {
    backgroundColor: 'transparent',
    width: 170,
    marginTop: 10,
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: 'rgb(229,229,229)',
  },
  accordionTitle: {
    fontFamily: 'gilroy-semibold',
    margin: 0,
    fontSize: 14,
    padding: 0,
  },
  filterText: {
    padding: 10,
    fontFamily: 'gilroy-regular',
  },
  timeFilterText: {
    borderWidth: 1,
    borderColor: 'transparent',
    borderBottomColor: 'rgb(229,229,229)',
  },
  filterAccordion: {
    width: 170,
    margin: 0,
    padding: 0,
    paddingRight: 5,
    borderWidth: 1,
    borderColor: 'rgb(229,229,229)',
  },
});
