import React, { useState, useRef, useCallback } from 'react';
import { ScrollView, View, Text, TextInput } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Formik } from 'formik';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import formResetPasswordSchema from 'forms/schemas/formResetPasswordSchema';
import CustomButton from 'components/CustomButton';
import Spinner from 'components/Spinner';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/forms/formRegister';

export default ({ route }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [message, setMessage] = useState('');
  const formikForm = useRef(null);
  const token = route.params?.token;
  const isMediumScreen = useMediumScreen();

  useFocusEffect(
    useCallback(() => {
      return () => {
        formikForm.current.resetForm();
        setMessage('');
        setFormError(false);
        setIsLoading(false);
      };
    }, []),
  );

  const onSubmit = async (values) => {
    try {
      setFormError(false);
      setMessage('');
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'put',
        url: '/user/reset-password',
        data: { ...values, token },
      });
      setFormError(false);
      setMessage(data.message);
      formikForm?.current?.resetForm();
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setMessage(error.response.data.errors[0]);
      } else {
        setMessage(GENERIC_ERROR_MESSAGE);
      }
      setFormError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
      <View style={globalStyles.viewContainer}>
        <View style={globalStyles.titleContainer}>
          <Text style={globalStyles.title}>Resetear contraseña</Text>
        </View>
        <View style={[globalStyles.formContainer, !isMediumScreen && globalStyles.formContainerMobile]}>
          <Formik
            innerRef={formikForm}
            validationSchema={formResetPasswordSchema}
            initialValues={{
              password: '',
              password_confirmation: '',
            }}
            onSubmit={onSubmit}
          >
            {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
              <>
                <View style={styles.formContainer}>
                  <View style={styles.firstContainer}>
                    {formError ? (
                      <Text style={globalStyles.inputErrorText}>{message}</Text>
                    ) : (
                      <Text style={globalStyles.successFormText}>{message}</Text>
                    )}
                    {!isLoading ? (
                      <>
                        <TextInput
                          style={[globalStyles.textInput, outlineStyle]}
                          name="password"
                          placeholder="Contraseña nueva"
                          onChangeText={handleChange('password')}
                          onBlur={handleBlur('password')}
                          value={values.password}
                          secureTextEntry
                        />
                        {errors.password && touched.password && (
                          <Text style={globalStyles.inputErrorText}>{errors.password}</Text>
                        )}
                        <TextInput
                          style={[globalStyles.textInput, outlineStyle]}
                          name="password_confirmation"
                          placeholder="Confirmar contraseña nueva"
                          onChangeText={handleChange('password_confirmation')}
                          onBlur={handleBlur('password_confirmation')}
                          value={values.password_confirmation}
                          secureTextEntry
                        />
                        {errors.password_confirmation && touched.password_confirmation && (
                          <Text style={globalStyles.inputErrorText}>{errors.password_confirmation}</Text>
                        )}
                        <View style={[styles.submitButton, globalStyles.contentCenteredColumn]}>
                          <CustomButton
                            title="Enviar"
                            buttonColor="#bbebeb"
                            textColor="#3f3a40"
                            marginTop={15}
                            alignSelf="center"
                            onPress={handleSubmit}
                            disabled={!isValid || isLoading}
                          />
                        </View>
                      </>
                    ) : (
                      <View style={styles.registerContainerSpinner}>
                        <Spinner />
                      </View>
                    )}
                  </View>
                </View>
              </>
            )}
          </Formik>
        </View>
      </View>
    </ScrollView>
  );
};
