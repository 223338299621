import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  iconAndDescriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    minWidth: 100,
  },
  multipleActionIconsContainer: {
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
  containerMultipleDaysSelected: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
  },
  trainingDayRowCheck: {
    paddingLeft: 0,
  },
  trainingDayCell: {
    maxWidth: 50,
  },
  trainingDayRow: {
    justifyContent: 'flex-start',
    minWidth: 40,
  },
  calendarIcon: {
    alignSelf: 'flex-start',
  },
  arrowUpwardIcon: {
    alignItems: 'center',
  },
});
