import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  picker: {
    minHeight: 45,
    maxHeight: 'auto',
    backgroundColor: '#f8f4f4',
    paddingLeft: 12,
    borderRadius: 3,
    marginBottom: 5,
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    color: '#3f3a40',
    textTransform: 'capitalize',
  },
  pickerIOS: {
    marginBottom: 5,
    paddingHorizontal: 10,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: '#3f3a40',
    minHeight: 45,
    maxHeight: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  modalContainer: {
    backgroundColor: 'white',
    height: 300,
  },
  iosContainer: { position: 'absolute', bottom: 15, right: 0 },
});
