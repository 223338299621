import React from 'react';
import { TextInput } from 'react-native';
import { outlineStyle } from 'src/styles/global';
import { styles } from 'styles/components/customInput';

export default ({ title, onChangeText, onBlur, value, name, secureTextEntry }) => (
  <TextInput
    style={[styles.textInputStyle, outlineStyle]}
    placeholder={title}
    placeholderTextColor={'#8a8a8a'}
    onChangeText={onChangeText}
    onBlur={onBlur}
    value={value}
    name={name}
    secureTextEntry={secureTextEntry}
  />
);
