import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  buttonImageContainer: {
    flex: 1,
    margin: 20,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    width: 260,
  },
  bigButtonContainer: {
    width: 285,
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 50,
    overflow: 'hidden',
  },
  titleContainer: {
    flex: 1,
    marginLeft: 22,
  },
});
