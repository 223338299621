import React from 'react';
import { Image, Platform, TouchableOpacity, View } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Spinner from 'components/Spinner';
import DefaultUserImage from 'assets/default-user-image.png';
import CameraButtonImage from 'assets/camera-button.png';
import { styles } from 'styles/components/imageCrop';
import { BASE_URL } from '@env';

const ImageCrop = ({ disabled = false, hasSmallSize = false, image, isLoading = false, onChange = () => {} }) => {
  const isMobile = Platform.OS !== 'web';

  const requestPermissions = async () => {
    try {
      if (isMobile) {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        return status === 'granted';
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const pickImage = async () => {
    try {
      const hasPermissions = await requestPermissions();
      if (hasPermissions) {
        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
          base64: true,
          exif: true,
        });
        if (!result.cancelled) {
          let uri = result.uri;
          if (isMobile) {
            let extension = uri.substr(uri.lastIndexOf('.') + 1);
            extension = extension === 'jpg' ? 'jpeg' : extension;
            uri = `data:image/${extension};base64,${result.base64}`;
          }
          onChange(uri);
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('Debes aceptar los permisos para poder realizar esta acción');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <Image
        source={image ? { uri: BASE_URL + image } : DefaultUserImage}
        style={hasSmallSize ? styles.smallImage : styles.image}
      />
      {isLoading && (
        <View style={styles.loading}>
          <Spinner />
        </View>
      )}
      {!disabled && (
        <TouchableOpacity onPress={pickImage} style={styles.cameraButtonContainer}>
          <Image source={CameraButtonImage} style={styles.cameraButton} />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default ImageCrop;
