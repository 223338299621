import React, { useContext, useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  FlatList,
  TouchableOpacity,
  Text,
  TextInput,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { DataTable } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import CustomButton from 'components/CustomButton';
import Student from 'components/Student';
import CustomInput from 'components/CustomInput';
import FormStudents from 'forms/FormStudents';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import Spinner from 'components/Spinner';
import CustomTable from 'components/CustomTable';
import ConfirmationModal from 'components/ConfirmationModal';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/screens/private/students';

export default ({ navigation }) => {
  const { isSubmitting, setIsSubmitting, userData, iosInit } = useContext(appContext);
  const [message, setMessage] = useState('');
  const [students, setStudents] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [searchGuestStudent, setSearchGuestStudent] = useState('');
  const [searchInvitedStudent, setSearchInvitedStudent] = useState('');
  const [studentsInvited, setStudentsInvited] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [studentIdSelected, setStudentIdSelected] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isMediumScreen = useMediumScreen();
  const isWeb = Platform.OS === 'web';

  const plan = userData?.user?.plan || userData?.user?.gym?.plan;

  const hasActiveSubscription = plan || false;

  const getStudentsInvited = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: 'students',
      });
      setStudentsInvited(data.students);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStudentsInvited();
  }, []);

  const disabledCreateStudentBtn = () => {
    if (studentsInvited.length === plan?.guest_students_cant) return true;
    if (isSubmitting) return true;
    return false;
  };

  const createStudent = () => {
    if (!name.trim() || !email.trim()) {
      setMessage('Los campos son requeridos');
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setMessage('El formato de email no es válido');
      return;
    }

    if (students.some((student) => student.email === email)) {
      setMessage('El email: ' + email + ' ya existe en la lista de invitaciones, por favor ingrese otro');
      return;
    }

    const student = { email, name };
    setStudents([...students, student]);
    setName('');
    setEmail('');
    setMessage('');
  };

  const updateStudent = (updateName, updateEmail, currentEmail) => {
    if (students.some((student) => student.email === updateEmail) && updateEmail !== currentEmail) {
      setMessage('El email: ' + updateEmail + ' ya existe en la lista de invitaciones, por favor ingrese otro');
      return false;
    }

    const newStudents = students.map((student) => {
      if (student.email === currentEmail) {
        student.name = updateName;
        student.email = updateEmail;
      }
      return student;
    });
    setStudents(newStudents);
    return true;
  };

  const deleteStudent = (studentEmail) => {
    const filteredStudents = students.filter((student) => student.email !== studentEmail);
    setStudents(filteredStudents);
  };

  const findUser = (student, handleSearch) => {
    return (
      student.name.toLowerCase().includes(handleSearch.toLowerCase()) ||
      student.email.toLowerCase().includes(handleSearch.toLowerCase())
    );
  };

  const filterGuestStudents = students.filter((student) => findUser(student, searchGuestStudent));
  const filterInvitedStudents = studentsInvited.filter((student) => findUser(student.user, searchInvitedStudent));

  const sendInvitation = async () => {
    try {
      setMessage('');
      setIsSubmitting(true);
      await axiosInstance({
        method: 'post',
        url: 'invite-user',
        data: { students },
      });
      setMessage('Invitación enviada de manera exitosa!');
      setStudents([]);
      setTimeout(function () {
        setMessage('');
      }, 3000);
      getStudentsInvited();
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setMessage(error.response.data.errors[0]);
      } else {
        setMessage(GENERIC_ERROR_MESSAGE);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const profileStudent = (data) => {
    if (hasActiveSubscription) {
      navigation.navigate('StudentProfileScreen', { userId: data.id, userName: data.name });
    }
  };

  const deleteStudentById = async (studentId) => {
    try {
      setIsModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: 'students/' + studentId,
      });
      const newStudents = studentsInvited.filter((student) => student.user_id !== studentId);
      setStudentsInvited(newStudents);
    } catch (error) {
      console.log(error);
    } finally {
      setStudentIdSelected(null);
      setIsLoading(false);
    }
  };

  const showConfirmationModal = (studentId) => {
    setStudentIdSelected(studentId);
    setIsModalVisible(true);
  };
  const renderRow = (student) => (
    <DataTable.Row key={`student-${student.user_id}`}>
      <DataTable.Cell style={globalStyles.tableCell}>
        <Text onPress={() => profileStudent(student.user)} style={globalStyles.tableText}>
          {student.user.name}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <Text onPress={() => profileStudent(student.user)} style={globalStyles.tableText}>
          {student.user.email}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <Text style={globalStyles.normalText}>{student.active ? 'Activo' : 'Pendiente'}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        {hasActiveSubscription ? (
          <>
            <TouchableOpacity onPress={() => showConfirmationModal(student.user_id)}>
              <Icon name="delete" size={22} color="#f2a172" />
            </TouchableOpacity>
            {isMediumScreen && (
              <TouchableOpacity disabled={!student.active} onPress={() => profileStudent(student.user)}>
                <Icon
                  name="visibility"
                  size={22}
                  color={student.active ? '#f2a172' : 'grey'}
                  style={globalStyles.tableIcon}
                />
              </TouchableOpacity>
            )}
          </>
        ) : (
          <Text style={globalStyles.normalText}>No disponible</Text>
        )}
      </DataTable.Cell>
    </DataTable.Row>
  );

  return (
    <KeyboardAvoidingView behavior={'height'} style={{ flex: 1 }}>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <Text
              style={[
                isWeb ? globalStyles.headerTitle : globalStyles.headerTitleForMobileNavigation,
                !isMediumScreen && globalStyles.headerTitleMobile,
              ]}
            >
              Alumnos
            </Text>
            <TouchableOpacity disabled={isLoading} onPress={getStudentsInvited}>
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          <View style={globalStyles.tableContainer}>
            {hasActiveSubscription && <Text style={globalStyles.tableTitle}>Enviar invitación a un alumno</Text>}
            <View style={styles.studentsSection}>
              {!hasActiveSubscription && iosInit && (
                <View style={[globalStyles.noSubscriptionContainer, globalStyles.marginTop10]}>
                  <Text style={globalStyles.tableTitle}>
                    Para invitar alumnos es requerida una suscripción de personal trainer o estar relacionado a un
                    gimnasio
                  </Text>
                  <CustomButton
                    title="Ver suscripciones"
                    buttonColor="#bbebeb"
                    textColor="#3f3a40"
                    marginTop={30}
                    alignSelf="center"
                    onPress={() => {
                      navigation.navigate('MySubscriptionsScreen');
                    }}
                  />
                </View>
              )}
              {isSubmitting ? (
                <View style={styles.studentContainerSpinner}>
                  <Spinner />
                </View>
              ) : (
                <View style={[styles.studentsContainer, hasActiveSubscription && globalStyles.marginTop20]}>
                  {hasActiveSubscription && (
                    <View
                      style={isMediumScreen ? styles.studentsContainerSection : styles.studentsContainerSectionMobile}
                    >
                      {!!message && <Text style={globalStyles.normalText}>{message}</Text>}
                      <FormStudents
                        students={students}
                        setStudents={setStudents}
                        createStudent={createStudent}
                        setName={setName}
                        setEmail={setEmail}
                        email={email}
                        name={name}
                        disabled={disabledCreateStudentBtn()}
                      />
                      <View style={styles.sendInvitationContainer}>
                        <CustomInput
                          title={!students.length ? 'No hay alumnos' : 'Buscar alumnos...'}
                          onChangeText={setSearchGuestStudent}
                        />
                        <FlatList
                          data={filterGuestStudents}
                          keyExtractor={(student) => student.email}
                          renderItem={({ item }) => (
                            <Student item={item} deleteStudent={deleteStudent} updateStudent={updateStudent} />
                          )}
                        />
                        <CustomButton
                          title="Enviar invitación"
                          buttonColor="#bbebeb"
                          textColor="#3f3a40"
                          marginTop={8}
                          alignSelf="center"
                          onPress={sendInvitation}
                          disabled={isSubmitting || !students.length}
                        />
                      </View>
                    </View>
                  )}
                  <Text style={[globalStyles.tableTitle, styles.myStudentsTitle]}>Mis Alumnos</Text>
                  <View
                    style={[
                      styles.searchInputContainer,
                      isMediumScreen ? styles.searchWebContainer : styles.searchMobileContainer,
                    ]}
                  >
                    <TextInput
                      onChangeText={setSearchInvitedStudent}
                      placeholder="Buscar alumno..."
                      style={[styles.searchInput, outlineStyle]}
                      value={searchInvitedStudent}
                    />
                  </View>

                  <View
                    style={[
                      globalStyles.tableContainer,
                      isMediumScreen ? styles.webTableContainer : styles.tableContainer,
                    ]}
                  >
                    <CustomTable
                      titles={['Nombre', 'Email', 'Estado', 'Acciones']}
                      rows={filterInvitedStudents}
                      renderRow={renderRow}
                      isLoading={isLoading}
                    />
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <ConfirmationModal
        visible={isModalVisible}
        title="Atención"
        description="¿Estás seguro de eliminar al estudiante?"
        onDismiss={() => setIsModalVisible(false)}
        onConfirm={() => deleteStudentById(studentIdSelected)}
      />
    </KeyboardAvoidingView>
  );
};
