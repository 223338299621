export const ROLES = {
  admin: { label: 'Admin', value: 1 },
  gym: { label: 'Gimnasio', value: 2 },
  trainer: { label: 'Personal trainer', value: 3 },
  student: { label: 'Alumno', value: 4 },
};

export const isAdmin = (userData) => userData?.user?.perfil_id === ROLES.admin.value;
export const isGym = (userData) => userData?.user?.perfil_id === ROLES.gym.value;
export const isTrainer = (userData) => userData?.user?.perfil_id === ROLES.trainer.value;
export const isStudent = (userData) => userData?.user?.perfil_id === ROLES.student.value;

export const rolesDropdown = Object.values(ROLES).filter((role) => role.value !== 1);
