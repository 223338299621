import { StyleSheet } from 'react-native';

export const outlineStyle = { outlineStyle: 'none' };

export const globalStyles = StyleSheet.create({
  hidden: {
    display: 'none',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  contentCenteredRow: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  contentCenteredColumn: {
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  successFormText: {
    fontFamily: 'gilroy-regular',
    fontSize: 12,
    color: '#4BB543',
  },
  inputErrorText: {
    fontFamily: 'gilroy-regular',
    fontSize: 12,
    color: '#ff0000',
  },
  textInput: {
    fontFamily: 'gilroy-regular',
    fontSize: 14,
    color: '#3f3a40',
    textAlign: 'left',
    borderColor: 'grey',
    backgroundColor: 'white',
    borderWidth: 1,
    borderRadius: 20,
    height: 45,
    width: '90%',
    margin: 5,
    paddingHorizontal: 16,
  },
  searchInputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'grey',
    width: 170,
    height: 40,
    borderRadius: 10,
    marginTop: 15,
    paddingHorizontal: 16,
    alignSelf: 'center',
  },
  searchTextInput: {
    width: 120,
    height: 40,
    textAlign: 'left',
    marginRight: 5,
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
  textArea: {
    height: 90,
    padding: 10,
    marginTop: 20,
  },
  loadingContainer: {
    width: '100%',
    height: 200,
  },
  scrollViewContainer: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  viewContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  },
  titleContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  title: {
    fontFamily: 'gilroy-bold',
    fontSize: 24,
    textAlign: 'center',
    color: '#c33e1d',
    padding: 16,
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  headerContainer: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  headerTitle: {
    fontFamily: 'gilroy-bold',
    fontSize: 25,
    color: '#c33e1d',
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  headerTitleMobile: {
    width: '80%',
  },
  headerTitleIcon: {
    backgroundColor: '#3f3a40',
    borderRadius: 15,
    padding: 2,
  },
  headerEmptyIcon: {
    width: 20,
  },
  tableContainer: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    marginVertical: 20,
  },
  mobileTableContainer: {
    width: '100%',
  },
  tableTitle: {
    fontFamily: 'gilroy-bold',
    fontSize: 14,
    marginTop: 10,
    textAlign: 'center',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'gilroy-regular',
  },
  tableCell: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
  tableText: {
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
  tableIcon: {
    marginLeft: 5,
  },
  cardContainer: {
    width: '70%',
    marginBottom: 20,
    paddingBottom: 8,
  },
  cardContainerMobile: {
    width: '100%',
    marginBottom: 20,
    paddingBottom: 8,
  },
  noSubscriptionContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  },
  noSubscriptionText: {
    textAlign: 'center',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 30,
    width: '50%',
  },
  formContainerMobile: {
    width: '80%',
  },
  errorContainer: {
    width: '90%',
  },
  bold: {
    fontFamily: 'gilroy-bold',
  },
  marginTop0: {
    marginTop: 0,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop40: {
    marginTop: 40,
  },
  marginRight10: {
    marginRight: 10,
  },
  normalText: {
    fontFamily: 'gilroy-regular',
    color: '#3f3a40',
  },
  upperMarginForNoHeaderScreens: {
    marginTop: 20,
  },
  headerTitleForMobileNavigation: {
    fontFamily: 'gilroy-bold',
    fontSize: 25,
    color: '#c33e1d',
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    paddingLeft: 65,
  },
  ml7: {
    marginRight: 7,
  },
  buttonColor: {
    color: 'rgb(32, 137, 220)',
  },
  dialogActionsPadding: {
    paddingRight: 0,
  },
  w100: {
    width: '100%',
  },
  flex1: {
    flex: 1,
  },
});
