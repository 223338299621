import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  containerWeb: {
    width: '50%',
    maxHeight: '80%',
    alignSelf: 'center',
  },
  containerMobile: {
    width: '80%',
    maxHeight: '80%',
    alignSelf: 'center',
  },
  header: {
    padding: 20,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  trainingStepContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  trainingStep: {
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 0,
    flex: 1.3,
  },
  scrollContainer: {
    padding: 15,
  },
  trainingStepObservations: {
    marginBottom: 10,
  },
  noExercisesTitle: {
    textAlign: 'center',
    fontSize: 17,
    marginTop: 10,
    marginBottom: 10,
    color: '#424242',
  },
});
