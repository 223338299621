import React, { useState, useCallback, useRef } from 'react';
import { ScrollView, View, Text, TextInput, TouchableOpacity, KeyboardAvoidingView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Icon } from 'react-native-elements';
import { Formik } from 'formik';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import Breadcrumb from 'components/Breadcrumb';
import CustomErrors from 'components/CustomErrors';
import CustomButton from 'components/CustomButton';
import Spinner from 'components/Spinner';
import { globalStyles, outlineStyle } from 'styles/global';
import formTrainingStepSchema from './schemas/formTrainingStepSchema';

export default ({ navigation, route }) => {
  const {
    routineId,
    routineDescription,
    trainingDayId,
    trainingDayDescription,
    trainingStepId,
    trainingStepDescription,
    trainingStepObservations,
  } = route.params;

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const isMediumScreen = useMediumScreen();
  const formikForm = useRef(null);

  const initialValues = { description: trainingStepDescription, observations: trainingStepObservations ?? '' };

  const breadcrumbRoutes = [
    {
      label: routineDescription,
      onPress: () => {
        navigation.navigate('RoutinesListScreen', {});
      },
    },
    {
      label: trainingDayDescription,
      onPress: () => {
        navigation.navigate('TrainingDaysListScreen', { routineId, routineDescription, refreshList: true });
      },
    },
    {
      label: 'Partes de entrenamiento',
      onPress: navigation.goBack,
    },
  ];

  useFocusEffect(
    useCallback(() => {
      return () => {
        formikForm.current.resetForm();
        setFormError('');
        setIsLoading(false);
      };
    }, []),
  );

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await axiosInstance({
        method: trainingStepId ? 'put' : 'post',
        url: trainingStepId
          ? `training-days/${trainingDayId}/training-steps/${trainingStepId}`
          : `training-days/${trainingDayId}/training-steps`,
        data: {
          descripcion: values.description,
          observations: values.observations,
        },
      });
      setIsLoading(false);
      navigation.navigate('TrainingStepsScreen', {
        routineId,
        routineDescription,
        trainingDayId,
        trainingDayDescription,
        refreshList: true,
      });
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setFormError(error.response.data.errors[0]);
      } else {
        setFormError(GENERIC_ERROR_MESSAGE);
      }
      setIsLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView behavior={'height'} style={{ flex: 1 }}>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              {trainingStepId ? 'Editar' : 'Crear'} partes de entrenamiento
            </Text>
            <View style={globalStyles.headerEmptyIcon} />
          </View>
          <Breadcrumb routes={breadcrumbRoutes} />
          <View style={[globalStyles.formContainer, !isMediumScreen && globalStyles.formContainerMobile]}>
            <Formik
              innerRef={formikForm}
              validationSchema={formTrainingStepSchema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
                <>
                  {!!formError && (
                    <View style={globalStyles.errorContainer}>
                      <CustomErrors msg={formError} />
                    </View>
                  )}
                  {!isLoading ? (
                    <>
                      <Text style={globalStyles.normalText}>Ej: Calentamiento, estiramiento...</Text>
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle]}
                        name="description"
                        placeholder="Descripción"
                        onChangeText={handleChange('description')}
                        onBlur={handleBlur('description')}
                        value={values.description}
                      />
                      {errors.description && touched.description && (
                        <Text style={globalStyles.inputErrorText}>{errors.description}</Text>
                      )}
                      <TextInput
                        style={[globalStyles.textInput, globalStyles.textArea, outlineStyle]}
                        name="observations"
                        placeholder="Observaciones"
                        onChangeText={handleChange('observations')}
                        onBlur={handleBlur('observations')}
                        value={values.observations}
                        multiline
                        textAlignVertical="top"
                      />
                      {errors.observations && touched.observations && (
                        <Text style={globalStyles.inputErrorText}>{errors.observations}</Text>
                      )}
                      <CustomButton
                        title={
                          <Text style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Icon name="save" color="black" size={15} style={{ marginRight: 6 }} />
                            {trainingStepId ? 'Guardar' : 'Crear'}
                          </Text>
                        }
                        buttonColor="#bbebeb"
                        textColor="#3f3a40"
                        marginTop={30}
                        alignSelf="center"
                        onPress={handleSubmit}
                        disabled={!isValid || isLoading}
                      />
                      {trainingStepId ? (
                        <CustomButton
                          title={'Añadir ejercicios'}
                          buttonColor="#bbebeb"
                          textColor="#3f3a40"
                          marginTop={30}
                          alignSelf="center"
                          onPress={() => {
                            navigation.navigate('CreateExerciseScreen', {
                              routineId,
                              routineDescription,
                              trainingDayId,
                              trainingDayDescription,
                              trainingStepId,
                              trainingStepDescription,
                            });
                          }}
                          disabled={!isValid || isLoading}
                        />
                      ) : null}
                    </>
                  ) : (
                    <View style={globalStyles.loadingContainer}>
                      <Spinner />
                    </View>
                  )}
                </>
              )}
            </Formik>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};
