import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ScrollView, View, Text, TouchableOpacity, TextInput, KeyboardAvoidingView, Platform } from 'react-native';
import { DataTable } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import { updateFolder, storeFolder } from 'services/folderService';
import useMediumScreen from 'hooks/useMediumScreen';
import { getDescriptionName } from 'utils/parseDescriptionName';
import { hasTrainerSubscription } from 'helpers/plans';
import Spinner from 'components/Spinner';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import ConfirmationModal from 'components/ConfirmationModal';
import RoutinePreviewModal from 'components/RoutinePreviewModal';
import FolderFormModal from 'components/FolderFormModal';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/students';
import { outlineStyle } from 'styles/global';

export default ({ navigation, route }) => {
  const [genericFoldersAndRoutines, setGenericFoldersAndRoutines] = useState([]);
  const [myFoldersAndRoutines, setMyFoldersAndRoutines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [isFolderFormModalVisible, setIsFolderFormModalVisible] = useState(false);
  const [isRoutinePreviewModalVisible, setIsRoutinePreviewModalVisible] = useState(false);
  const [routineSelected, setRoutineSelected] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [routineGenericSelected, setRoutineGenericSelected] = useState(null);
  const [searchGenericFolderOrRoutine, setSearchGenericFolderOrRoutine] = useState('');
  const [searchMyFolderOrRoutine, setSearchMyFolderOrRoutine] = useState('');

  const { userData, iosInit } = useContext(appContext);

  const isMediumScreen = useMediumScreen();
  const isWeb = Platform.OS === 'web';
  const hasActiveSubscription = hasTrainerSubscription(userData);

  useEffect(() => {
    getRoutines();
  }, [getRoutines]);

  useEffect(() => {
    !!route?.params?.refreshList && getRoutines();
  }, [getRoutines, route]);

  const itemIsARoutine = (item) => item.hasOwnProperty('folder_id');

  const findRoutine = (routine, handleSearch) => {
    return routine.descripcion.toLowerCase().includes(handleSearch.toLowerCase());
  };

  const findFolder = (folder, handleSearch) => folder.description.toLowerCase().includes(handleSearch.toLowerCase());

  const checkAndFindFolderOrRoutine = (item, search) => {
    if (itemIsARoutine(item)) {
      // si tiene la propiedad folder_id significa que es una rutina
      return findRoutine(item, search);
    } else {
      return findFolder(item, search);
    }
  };

  const filteredMyFoldersAndRoutines = myFoldersAndRoutines.filter((item) =>
    checkAndFindFolderOrRoutine(item, searchMyFolderOrRoutine),
  );

  const filteredGenericFoldersAndRoutines = genericFoldersAndRoutines.filter((item) =>
    checkAndFindFolderOrRoutine(item, searchGenericFolderOrRoutine),
  );

  const renderFolderOrRoutineFolder = (item) => {
    if (itemIsARoutine(item)) {
      return renderRoutineRow(item);
    } else {
      return renderFolderRow(item);
    }
  };

  const getRoutines = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: '/routines',
      });
      const myFoldersAndRoutinesSortedByDate = sortFoldersOrRoutinesByDate(data.foldersAndRoutines);
      const genericFoldersAndRoutinesSortedByDate = sortFoldersOrRoutinesByDate(data.genericFoldersAndRoutines);
      setMyFoldersAndRoutines(myFoldersAndRoutinesSortedByDate);
      setGenericFoldersAndRoutines(genericFoldersAndRoutinesSortedByDate);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const sortFoldersOrRoutinesByDate = (items) => {
    const itemsSortedByDate = items.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.created_at) - new Date(a.created_at);
    });

    return itemsSortedByDate;
  };

  const onRoutinePreview = async (routine) => {
    try {
      const { data } = await axiosInstance({
        method: 'get',
        url: `routines/${routine.id}/training-days?isPreview=true`,
      });
      routine.trainingDays = data.trainingDays;
      setRoutineSelected(routine);
      setIsRoutinePreviewModalVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteConfirmationModal = (routine) => {
    setRoutineSelected(routine);
    setIsDeleteModalVisible(true);
  };

  const showCopyConfirmationModal = (item) => {
    if (hasActiveSubscription) {
      if (itemIsARoutine(item)) {
        setRoutineGenericSelected(item);
        setIsCopyModalVisible(true);
      }
    }
  };

  const deleteRoutineById = async (routineId) => {
    try {
      setIsDeleteModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: 'routines/' + routineId,
      });
      getRoutines();
    } catch (error) {
      console.log(error);
    } finally {
      setRoutineSelected(null);
      setIsLoading(false);
    }
  };

  const copyRoutineGeneric = async (routine) => {
    try {
      setIsCopyModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'post',
        url: 'routines/create',
        data: { routineId: routine.id },
      });
      getRoutines();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setRoutineGenericSelected(null);
    }
  };

  const onSubmitFolderForm = async (folderData) => {
    if (!userData.user) {
      return;
    }
    const folder = {
      ...folderData,
      trainerId: userData.user.id,
    };
    let result = null;
    setIsLoading(true);
    if (folder.id) {
      result = await updateFolder(folder);
    } else {
      result = await storeFolder(folder);
    }
    if (result) {
      getRoutines();
    }
    setIsFolderFormModalVisible(false);
    setIsLoading(false);
  };

  const onEditFolder = (folder) => {
    setSelectedFolder(folder);
    setIsFolderFormModalVisible(true);
  };

  const getParseName = (name) => {
    return getDescriptionName(name);
  };
  const renderFolderRow = (folder) => (
    <DataTable.Row key={`folder-${folder.id}`}>
      <DataTable.Cell
        style={[
          globalStyles.tableCell,
          { justifyContent: 'flex-start', paddingLeft: isMediumScreen ? 200 : 0, minWidth: 110 },
        ]}
      >
        <TouchableOpacity
          style={styles.iconAndDescriptionContainer}
          onPress={() => {
            if (hasActiveSubscription) {
              navigation.navigate('RoutinesByFolderScreen', {
                folderId: folder.id,
              });
            }
          }}
        >
          <Icon name="folder" size={22} color="#f2a172" style={{ alignSelf: 'flex-start' }} />
          <View style={{ flex: 1 }}>
            <Text style={[globalStyles.tableText, { paddingLeft: 10, width: '100%' }]}>
              {getParseName(folder.description)}
            </Text>
          </View>
        </TouchableOpacity>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        {hasActiveSubscription ? (
          <>
            <TouchableOpacity onPress={() => onEditFolder(folder)}>
              <Icon name="edit" size={22} color="#f2a172" />
            </TouchableOpacity>
          </>
        ) : (
          <Text style={globalStyles.normalText}>No disponible</Text>
        )}
      </DataTable.Cell>
    </DataTable.Row>
  );

  const renderRoutineRow = (routine) => (
    <DataTable.Row key={`routine-${routine.id}`}>
      <DataTable.Cell
        style={[
          globalStyles.tableCell,
          { justifyContent: 'flex-start', paddingLeft: isMediumScreen ? 200 : 0, minWidth: 65 },
        ]}
      >
        <TouchableOpacity
          style={styles.iconAndDescriptionContainer}
          onPress={() => {
            if (hasActiveSubscription) {
              navigation.navigate('TrainingDaysListScreen', {
                routineId: routine.id,
                routineDescription: routine.descripcion,
                refreshList: true,
              });
            }
          }}
        >
          <Icon name="fitness-center" size={22} style={{ alignSelf: 'flex-start' }} color="#f2a172" />
          <View style={{ flex: 1 }}>
            <Text style={[globalStyles.tableText, { paddingLeft: 10, width: '100%' }]}>
              {getParseName(routine.descripcion)}
            </Text>
          </View>
        </TouchableOpacity>
      </DataTable.Cell>
      <DataTable.Cell style={[globalStyles.tableCell]}>
        {hasActiveSubscription ? (
          <>
            <TouchableOpacity
              onPress={() => {
                const params = {
                  id: routine.id,
                  description: routine.descripcion,
                };
                if (routine.folder_id) {
                  params.folderId = routine.folder_id;
                }

                navigation.navigate('EditRoutineFormScreen', params);
              }}
            >
              <Icon name="edit" size={22} color="#f2a172" />
            </TouchableOpacity>
            {isMediumScreen && (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('TrainingDaysListScreen', {
                    routineId: routine.id,
                    routineDescription: routine.descripcion,
                    refreshList: true,
                  });
                }}
              >
                <Icon name="visibility" size={22} color="#f2a172" style={globalStyles.tableIcon} />
              </TouchableOpacity>
            )}
            <TouchableOpacity onPress={() => showCopyConfirmationModal(routine)}>
              <Icon name="file-copy" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onRoutinePreview(routine)}>
              <Icon name="preview" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => showDeleteConfirmationModal(routine)}>
              <Icon name="delete" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
          </>
        ) : (
          <Text style={globalStyles.normalText}>No disponible</Text>
        )}
      </DataTable.Cell>
    </DataTable.Row>
  );

  const renderGenericFolderOrRoutineRow = (item, index) => (
    <DataTable.Row key={`item-generic-${index}`}>
      <DataTable.Cell
        style={[
          globalStyles.tableCell,
          { justifyContent: 'flex-start', paddingLeft: isMediumScreen ? 200 : 0, minWidth: 110 },
        ]}
      >
        <TouchableOpacity
          style={styles.iconAndDescriptionContainer}
          onPress={() => {
            if (!hasActiveSubscription) {
              return;
            }
            if (itemIsARoutine(item)) {
              navigation.navigate('TrainingDaysListScreen', {
                routineId: item.id,
                routineDescription: item.descripcion,
                refreshList: true,
                routineIsGeneric: true,
              });
            } else {
              // Accede acá cuando la rutina es una carpeta
              navigation.navigate('RoutinesByFolderScreen', {
                folderId: item.id,
              });
            }
          }}
        >
          <Icon
            name={itemIsARoutine(item) ? 'fitness-center' : 'folder'}
            size={22}
            style={{ alignSelf: 'flex-start' }}
            color="#f2a172"
          />
          <View style={{ flex: 1 }}>
            <Text
              style={[
                globalStyles.tableText,
                { paddingLeft: 10, width: '100%' },
                !itemIsARoutine(item) && { color: '#f2a172' },
              ]}
            >
              {getParseName(itemIsARoutine(item) ? item.descripcion : item.description)}
            </Text>
          </View>
        </TouchableOpacity>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        {hasActiveSubscription && itemIsARoutine(item) ? (
          <>
            <TouchableOpacity onPress={() => showCopyConfirmationModal(item)}>
              <Icon name="file-copy" size={22} color="#f2a172" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onRoutinePreview(item)}>
              <Icon name="preview" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
          </>
        ) : (
          <Text style={globalStyles.normalText}>No disponible</Text>
        )}
      </DataTable.Cell>
    </DataTable.Row>
  );

  return (
    <KeyboardAvoidingView behavior={'height'} style={{ flex: 1 }}>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <Text
              style={[
                isWeb ? globalStyles.headerTitle : globalStyles.headerTitleForMobileNavigation,
                !isMediumScreen && globalStyles.headerTitleMobile,
              ]}
            >
              Programaciones
            </Text>
            <TouchableOpacity
              disabled={isLoading}
              onPress={() => {
                getRoutines();
              }}
            >
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          {isLoading ? (
            <View style={globalStyles.loadingContainer}>
              <Spinner />
            </View>
          ) : (
            <>
              <View style={globalStyles.tableContainer}>
                {hasActiveSubscription ? (
                  <>
                    <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                      <Text style={[globalStyles.tableTitle, globalStyles.marginTop0, globalStyles.marginRight10]}>
                        Programaciones creadas por ti
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate('CreateRoutineFormScreen', {});
                        }}
                      >
                        <Icon name="add-circle" size={22} color="#f2a172" />
                      </TouchableOpacity>
                    </View>
                    <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                      <Text style={[globalStyles.tableTitle, globalStyles.marginTop0, globalStyles.marginRight10]}>
                        Crear una carpeta nueva
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          setSelectedFolder(null);
                          setIsFolderFormModalVisible(true);
                        }}
                      >
                        <Icon name="add-circle" size={22} color="#f2a172" />
                      </TouchableOpacity>
                    </View>
                  </>
                ) : !hasActiveSubscription && iosInit ? (
                  <View style={globalStyles.noSubscriptionContainer}>
                    <Text style={globalStyles.tableTitle}>
                      Para crear programaciones es requerida una suscripción de personal trainer o estar relacionado a
                      un gimnasio
                    </Text>
                    <CustomButton
                      title="Ver suscripciones"
                      buttonColor="#bbebeb"
                      textColor="#3f3a40"
                      marginTop={30}
                      alignSelf="center"
                      onPress={() => {
                        navigation.navigate('MySubscriptionsScreen');
                      }}
                    />
                  </View>
                ) : (
                  <></>
                )}

                <View
                  style={[
                    styles.searchInputContainer,
                    isMediumScreen ? styles.searchWebContainer : styles.searchMobileContainer,
                  ]}
                >
                  <TextInput
                    onChangeText={setSearchMyFolderOrRoutine}
                    placeholder="Buscar  carpetas o programaciones..."
                    style={[styles.searchInput, outlineStyle]}
                    value={searchMyFolderOrRoutine}
                  />
                </View>
                <CustomTable
                  titles={['Descripción', 'Acciones']}
                  rows={filteredMyFoldersAndRoutines}
                  renderRow={renderFolderOrRoutineFolder}
                />
              </View>
              <View style={globalStyles.tableContainer}>
                <Text style={globalStyles.tableTitle}>Programaciones genéricas</Text>
                <View
                  style={[
                    styles.searchInputContainer,
                    isMediumScreen ? styles.searchWebContainer : styles.searchMobileContainer,
                  ]}
                >
                  <TextInput
                    onChangeText={setSearchGenericFolderOrRoutine}
                    placeholder="Buscar programación..."
                    style={[styles.searchInput, outlineStyle]}
                    value={searchGenericFolderOrRoutine}
                  />
                </View>
                <CustomTable
                  titles={['Descripción', 'Acciones']}
                  rows={filteredGenericFoldersAndRoutines}
                  renderRow={renderGenericFolderOrRoutineRow}
                />
              </View>
            </>
          )}
        </View>
      </ScrollView>
      {isRoutinePreviewModalVisible && routineSelected && (
        <RoutinePreviewModal
          visible={isRoutinePreviewModalVisible}
          routine={routineSelected}
          onDismiss={() => setIsRoutinePreviewModalVisible(false)}
        />
      )}
      <ConfirmationModal
        visible={isDeleteModalVisible}
        title="Atención"
        description="¿Estás seguro de eliminar la programación?"
        onDismiss={() => setIsDeleteModalVisible(false)}
        onConfirm={() => deleteRoutineById(routineSelected.id)}
      />
      <ConfirmationModal
        visible={isCopyModalVisible}
        title={routineGenericSelected?.descripcion}
        description={'Estás a punto de generar una copia de esta programación, desea continuar?'}
        onDismiss={() => setIsCopyModalVisible(false)}
        onConfirm={() => copyRoutineGeneric(routineGenericSelected)}
      />
      <FolderFormModal
        folder={selectedFolder ?? null}
        visible={isFolderFormModalVisible}
        onDismiss={() => setIsFolderFormModalVisible(false)}
        onSubmit={onSubmitFolderForm}
        title={`${selectedFolder ? 'Editar' : 'Crear'} carpeta`}
      />
    </KeyboardAvoidingView>
  );
};
