import axiosInstance from './axios';

export const getMethods = async () => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'methods',
    });
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
