import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { DataTable, Checkbox } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import Breadcrumb from 'components/Breadcrumb';
import Spinner from 'components/Spinner';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import ConfirmationModal from 'components/ConfirmationModal';
import { reSortItemsByOrder, getRequestToReOrder } from 'helpers/utils';
import { getDescriptionName } from 'utils/parseDescriptionName';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/trainingDays';

export default ({ navigation, route }) => {
  const { routineId, routineDescription, refreshList, routineIsGeneric } = route.params;
  const [trainingDays, setTrainingDays] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trainingDayIdSelected, setTrainingDayIdSelected] = useState(null);
  const [showSaveOrderBtn, setShowSaveOrderBtn] = useState(false);
  const { userData } = useContext(appContext);

  const selectedDaysLength = trainingDays.filter((day) => day.checked === true).length;

  const isMediumScreen = useMediumScreen();

  const breadcrumbRoutes = [
    {
      label: routineDescription,
      onPress: () => {
        navigation.navigate('RoutinesListScreen', {});
      },
    },
    {
      label: 'Días de entrenamiento',
      onPress: () => {},
    },
  ];

  useEffect(() => {
    getTrainingDays();
  }, [getTrainingDays, routineId]);

  useEffect(() => {
    !!refreshList && getTrainingDays();
  }, [getTrainingDays, route]);

  const getTrainingDays = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: 'routines/' + routineId + '/training-days',
      });
      data.trainingDays.map((trainingDay) => (trainingDay.checked = false));
      setTrainingDays(data.trainingDays);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [routineId]);

  const createTrainingDay = async () => {
    try {
      setIsLoading(true);
      await axiosInstance({
        method: 'post',
        url: `routines/${routineId}/training-days`,
      });
      getTrainingDays();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const multipleActionsTrainingDay = async (action) => {
    const days = trainingDays.filter((day) => day.checked === true).map((day) => day.id);

    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'post',
        url: `routines/${routineId}/training-days/multiple-actions`,
        data: { trainingDaysId: days, action },
      });
      data.trainingDays.map((trainingDay) => (trainingDay.checked = false));
      setTrainingDays([...data.trainingDays]);

      setIsModalVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const canDoActions = () => {
    if (!routineIsGeneric) {
      //routineIsGeneric solamente se setea cuando la rutina es genérica
      // entonces si no se setea significa que la rutina tiene dueño
      return true;
    }
    return parseInt(routineIsGeneric, 10) === userData?.user?.id;
  };

  const copyTrainingDay = async (trainingDayId) => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: `routines/${routineId}/training-days/${trainingDayId}/edit`,
      });
      data.trainingDay.checked = false;
      setTrainingDays([...trainingDays, data.trainingDay]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showConfirmationModal = (trainingDayId) => {
    setTrainingDayIdSelected(trainingDayId);
    setIsModalVisible(true);
  };

  const deleteTrainingDaysById = async (trainingDayId) => {
    try {
      setIsModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: `routines/${routineId}/training-days/${trainingDayId}`,
      });
      const newTrainingDays = trainingDays.filter((trainingDay) => trainingDay.id !== trainingDayId);
      setTrainingDays(newTrainingDays);
    } catch (error) {
      console.log(error);
    } finally {
      setTrainingDayIdSelected(null);
      setIsLoading(false);
    }
  };

  const reSortTrainingDays = (trainingDayId) => {
    const index = trainingDays.findIndex((trainingDay) => trainingDay.id === trainingDayId);
    const newTrainingDays = reSortItemsByOrder(trainingDays, index);
    setShowSaveOrderBtn(true);
    setTrainingDays(newTrainingDays);
  };

  const getColumnTitles = () => {
    const titles = ['Número', 'Acciones'];

    if (canDoActions()) {
      titles.push('Orden');
    }

    return titles;
  };

  const getIconColor = () => {
    if (!canDoActions()) {
      return '#b9aeae';
    }

    return selectedDaysLength > 0 ? '#b9aeae' : '#f2a172';
  };

  const saveTrainingStepExercisesOrder = async () => {
    const request = getRequestToReOrder(trainingDays);
    try {
      setIsLoading(true);
      await axiosInstance({
        method: 'post',
        url: `routines/${routineId}/training-days/re-order`,
        data: { trainingDays: request },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setShowSaveOrderBtn(false);
    }
  };

  const renderTraningDayRow = (trainingDay) => (
    <DataTable.Row key={`training-day-${trainingDay.id}`} style={styles.trainingDayRowCheck}>
      {canDoActions() ? (
        <DataTable.Cell style={styles.trainingDayCell}>
          <Checkbox.Android
            color="#f2a172"
            status={trainingDay.checked ? 'checked' : 'unchecked'}
            uncheckedColor={'#f2a172'}
            onPress={() => {
              const days = trainingDays;
              const indexDay = trainingDays.findIndex((selectedDay) => selectedDay.id === trainingDay.id);
              days[indexDay].checked = !days[indexDay].checked;
              setTrainingDays([...days]);
            }}
          />
        </DataTable.Cell>
      ) : null}
      <DataTable.Cell
        style={[globalStyles.tableCell, styles.trainingDayRow, { paddingLeft: isMediumScreen ? 200 : 0 }]}
      >
        <TouchableOpacity
          style={styles.iconAndDescriptionContainer}
          onPress={() => {
            navigation.navigate('TrainingStepsScreen', {
              routineId,
              routineDescription,
              trainingDayId: trainingDay.id,
              trainingDayDescription: trainingDay.day,
              refreshList: true,
              routineIsGeneric,
            });
          }}
        >
          <Icon name={'event'} size={22} style={styles.calendarIcon} color="#f2a172" />
          <Text style={[globalStyles.tableText]}>{getDescriptionName(trainingDay.day, 12)}</Text>
        </TouchableOpacity>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('EditTrainingDayScreen', {
              routineId,
              routineDescription,
              trainingDayId: trainingDay.id,
              trainingDayDescription: trainingDay.day,
            });
          }}
          disabled={!canDoActions()}
        >
          <Icon name="edit" size={22} color={getIconColor()} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => copyTrainingDay(trainingDay.id)}
          disabled={selectedDaysLength > 0 || !canDoActions()}
        >
          <Icon name="file-copy" size={22} color={getIconColor()} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => showConfirmationModal(trainingDay.id)}
          disabled={selectedDaysLength > 0 || !canDoActions()}
        >
          <Icon name="delete" size={22} color={getIconColor()} />
        </TouchableOpacity>
        {isMediumScreen && (
          <>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('TrainingStepsScreen', {
                  routineId,
                  routineDescription,
                  trainingDayId: trainingDay.id,
                  trainingDayDescription: trainingDay.day,
                  refreshList: true,
                });
              }}
              disabled={!canDoActions()}
            >
              <Icon name="visibility" size={22} color={getIconColor()} style={globalStyles.tableIcon} />
            </TouchableOpacity>
          </>
        )}
      </DataTable.Cell>
      {canDoActions() ? (
        <DataTable.Cell style={globalStyles.tableCell}>
          {trainingDay.order !== 1 ? (
            <TouchableOpacity onPress={() => reSortTrainingDays(trainingDay.id)} style={styles.arrowUpwardIcon}>
              <Icon name="arrow-upward" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
          ) : null}
        </DataTable.Cell>
      ) : null}
    </DataTable.Row>
  );

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Días de entrenamiento
            </Text>
            <TouchableOpacity disabled={isLoading} onPress={getTrainingDays}>
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          <Breadcrumb routes={breadcrumbRoutes} />
          {isLoading ? (
            <View style={globalStyles.loadingContainer}>
              <Spinner />
            </View>
          ) : (
            <>
              {selectedDaysLength > 0 && (
                <View style={styles.containerMultipleDaysSelected}>
                  <Text style={{ fontSize: 20 }}>Hay {selectedDaysLength} días seleccionados</Text>
                  <View style={styles.multipleActionIconsContainer}>
                    <TouchableOpacity style={globalStyles.ml7} onPress={() => multipleActionsTrainingDay('copy')}>
                      <Icon name="file-copy" size={22} color="#f2a172" />
                    </TouchableOpacity>
                    <TouchableOpacity style={globalStyles.ml7} onPress={() => showConfirmationModal()}>
                      <Icon name="delete" size={22} color="#f2a172" />
                    </TouchableOpacity>
                  </View>
                </View>
              )}
              <View style={globalStyles.tableContainer}>
                {canDoActions() ? (
                  <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                    <Text style={[globalStyles.tableTitle, globalStyles.marginTop0, globalStyles.marginRight10]}>
                      Crear un nuevo día de entrenamiento
                    </Text>
                    <TouchableOpacity onPress={createTrainingDay}>
                      <Icon name="add-circle" size={22} color="#f2a172" />
                    </TouchableOpacity>
                  </View>
                ) : null}

                {showSaveOrderBtn && (
                  <View style={globalStyles.contentCenteredRow}>
                    <CustomButton
                      title={'Guardar orden'}
                      buttonColor="#bbebeb"
                      textColor="#3f3a40"
                      marginTop={25}
                      onPress={() => saveTrainingStepExercisesOrder()}
                    />
                  </View>
                )}
                <CustomTable
                  titles={getColumnTitles()}
                  rows={trainingDays}
                  renderRow={renderTraningDayRow}
                  isLoading={isLoading}
                  withPagination={false}
                />
              </View>
            </>
          )}
        </View>
      </ScrollView>
      <ConfirmationModal
        visible={isModalVisible}
        title="Atención"
        description={
          selectedDaysLength > 0
            ? '¿Estás seguro de que deseas eliminar los días de entrenamiento seleccionados?'
            : '¿Estás seguro de eliminar el día de entrenamiento?'
        }
        onDismiss={() => setIsModalVisible(false)}
        onConfirm={() => {
          if (selectedDaysLength > 0) {
            multipleActionsTrainingDay('delete');
          } else {
            deleteTrainingDaysById(trainingDayIdSelected);
          }
        }}
      />
    </>
  );
};
