import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  containerForSafeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
  chatItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  contactProfileImage: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  scrollViewChatList: {},
  profileImageContainer: {
    width: 50,
  },
  profileImage: {
    width: 50,
    height: 50,
  },
  contactInfoContainer: {
    flex: 6,
    padding: 5,
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  contactName: {
    fontSize: 18,
    fontFamily: 'gilroy-semibold',
    marginBottom: 3,
  },
  lastMessage: {
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
  unreadedMessage: {
    fontSize: 14,
    fontFamily: 'gilroy-bold',
  },
  commentIcon: {
    marginTop: 10,
    color: '#f00',
  },
  newMessageButton: {
    alignSelf: 'flex-end',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.2)',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    position: 'absolute',
    bottom: 20,
    right: 20,
    height: 70,
    backgroundColor: '#fff',
    borderRadius: 100,
  },
  noMessagesText: {
    textAlign: 'center',
    marginTop: 80,
    fontFamily: 'gilroy-bold',
  },
  messageContainer: { flex: 6 },
  unreadedMessageDotContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  unreadedMessageDot: {
    width: 9,
    height: 9,
    borderRadius: 5,
    backgroundColor: '#c33e1d',
  },
});
