import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '90%',
  },
  containerForSafeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
  text: {
    fontFamily: 'gilroy-bold',
    fontSize: 18,
    textAlign: 'center',
  },
});
