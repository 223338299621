import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  containerWeb: {
    width: '50%',
    maxHeight: '80%',
    alignSelf: 'center',
  },
  containerMobile: {
    width: '80%',
    maxHeight: '80%',
    alignSelf: 'center',
  },
  scrollContainer: {
    padding: 15,
    minHeight: 200,
    maxHeight: 300,
    overflowY: 'auto',
  },
  dayTitle: { fontSize: 20, fontFamily: 'gilroy-regular', marginBottom: 3 },
  trainingDayContainer: {
    marginBottom: 15,
  },
  trainingStepContainer: {
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 3,
  },
  trainingStepDescription: {
    fontSize: 16,
    fontFamily: 'gilroy-regular',
    textTransform: 'capitalize',
  },
  trainingStepStatus: {
    textTransform: 'capitalize',
  },
  text: {
    fontFamily: 'gilroy-regular',
    width: '90%',
    fontSize: 16,
  },
  marksHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 60,
    justifyContent: 'space-between',
  },
  titleStep: { marginHorizontal: 0, width: '100%', textAlign: 'center' },
  exerciseMarkTitle: {
    fontFamily: 'gilroy-regular',
    fontSize: 12,
    paddingLeft: 8,
    opacity: 0.7,
    color: '#3f3a40',
  },
  seeMarks: { textDecorationLine: 'underline', opacity: 0.7, fontSize: 14, fontFamily: 'gilroy-regular' },
});
