import * as yup from 'yup';

export default () =>
  yup.object().shape({
    current_password: yup.string().required('El campo de contraseña actual es obligatorio'),
    password: yup
      .string()
      .required('El campo contraseña nueva es obligatorio')
      .min(8, ({ min }) => `Debe tener al menos ${min} caracteres`)
      .notOneOf([yup.ref('current_password'), null], 'La contraseña nueva no debe coincidir con la actual'),
    password_confirmation: yup
      .string()
      .required('El campo confirmar contraseña nueva es obligatorio')
      .oneOf([yup.ref('password'), null], 'La contraseña debe coincidir'),
  });
