import React, { useContext, useState } from 'react';
import { ScrollView, Text, TouchableOpacity, View, SafeAreaView, Platform } from 'react-native';
import { StackActions } from '@react-navigation/native';
import { Icon } from 'react-native-elements';
import { Card, Title } from 'react-native-paper';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import useMediumScreen from 'hooks/useMediumScreen';
import { isGym, isTrainer, isStudent } from 'helpers/roles';
import { getDateString } from 'helpers/dates';
import ImageCrop from 'components/ImageCrop';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/profile';
import ConfirmationModal from 'src/components/ConfirmationModal';

const Profile = (props) => {
  const { navigation } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isDeleteAccountEmailSent, setIsDeleteAccountEmailSent] = useState(false);
  const [imageError, setImageError] = useState('');
  const { iosInit, clearUserData, setUserData, userData } = useContext(appContext);
  const isMediumScreen = useMediumScreen();
  const isWeb = Platform.OS === 'web';
  const user = userData?.user;
  const userImage = user?.image;
  const trainer = user?.trainer?.name;
  const trainers = user?.trainers;
  const subscription = user?.subscription;
  const onChange = async (uri) => {
    try {
      setIsLoading(true);
      setImageError('');
      const formData = new FormData();
      formData.append('image', uri);
      const { data } = await axiosInstance({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        url: '/user/update-image',
        data: formData,
      });
      setUserData({ ...userData, user: { ...user, image: data.image } });
    } catch (error) {
      const response = error?.response;
      const status = response?.status;
      if (status === 422 && !!response?.data?.errors?.length) {
        setImageError(error.response.data.errors[0]);
      } else if (status === 413) {
        setImageError('El archivo es muy pesado, por favor ingrese uno menor a 5 MB.');
      } else {
        setImageError(GENERIC_ERROR_MESSAGE);
      }
      setTimeout(() => setImageError(''), 5000);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAccount = async () => {
    try {
      setIsLoading(true);
      await axiosInstance({
        method: 'get',
        url: '/user/delete-account',
      });
      setIsDeleteAccountEmailSent(true);
    } catch (error) {
      console.log('error', error);
      setIsDeleteAccountEmailSent(false);
    } finally {
      setIsLoading(false);
    }
  };

  const clearSession = async () => {
    await clearUserData();
    StackActions.popToTop();
    navigation.navigate('Main', { screen: 'Login' });
  };

  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={[styles.headerContainer, isWeb && globalStyles.contentCenteredRow]}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[isWeb ? globalStyles.title : styles.title, !isMediumScreen && globalStyles.titleMobile]}>
              Perfil
            </Text>
          </View>
          <ImageCrop image={userImage} isLoading={isLoading} onChange={onChange} />
          {!!imageError && <Text style={styles.error}>{imageError}</Text>}
          <View
            style={[
              !isStudent(userData) && { height: 70 },
              isWeb && isStudent(userData) && { height: 150 },
              isWeb && isMediumScreen && isStudent(userData) && { height: 180 },
            ]}
          >
            <View style={styles.textContainer}>
              <Text style={[styles.text, styles.bold, !isMediumScreen && styles.textMobile]}>{user?.name}</Text>
            </View>
            <View style={styles.textContainer}>
              <Text style={[styles.text, !isMediumScreen && styles.textMobile]}>{user?.email}</Text>
            </View>
            <View style={styles.textContainer}>
              {!!trainer && trainers?.length === 1 ? (
                <>
                  <Text style={[styles.text, !isMediumScreen && styles.textMobile]}>Personal trainer:</Text>
                  <Text style={[styles.text, styles.bold, !isMediumScreen && styles.textMobile]}>{trainer}</Text>
                </>
              ) : (
                !!trainers &&
                trainers?.length > 1 && (
                  <>
                    <Text style={[styles.text, !isMediumScreen && styles.textMobile]}>Personal trainers:</Text>
                    {trainers.map((trainer) => (
                      <Text key={trainer.id} style={[styles.text, styles.bold, !isMediumScreen && styles.textMobile]}>
                        {trainer.name}
                      </Text>
                    ))}
                  </>
                )
              )}
            </View>
          </View>
          {subscription?.status === 'trialing' && !!subscription?.trial_end && (
            <View style={styles.textContainer}>
              <Text style={[styles.text, !isMediumScreen && styles.textMobile]}>El período de prueba termina el:</Text>
              <Text style={[styles.text, styles.bold, !isMediumScreen && styles.textMobile]}>
                {getDateString(subscription.trial_end)}
              </Text>
            </View>
          )}
          <View
            style={[
              styles.container,
              globalStyles.contentCenteredColumn,
              { marginBottom: 100 },
              (isTrainer(userData) || isGym(userData)) && { marginTop: 80 },
            ]}
          >
            {iosInit && (isTrainer(userData) || isGym(userData)) && (
              <TouchableOpacity
                key="show-my-subscriptions"
                style={[isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile]}
              >
                <Card
                  onPress={() => {
                    navigation.navigate('MySubscriptionsScreen');
                  }}
                >
                  <Card.Content>
                    <Title style={[styles.text, !isMediumScreen && styles.textMobile]}>Suscripciones</Title>
                  </Card.Content>
                </Card>
              </TouchableOpacity>
            )}
            {iosInit && (isTrainer(userData) || isGym(userData)) && !!user?.plan && (
              <TouchableOpacity
                key="show-my-subscription"
                style={[isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile]}
              >
                <Card
                  onPress={() => {
                    navigation.navigate('MySubscriptionScreen');
                  }}
                >
                  <Card.Content>
                    <Title style={[styles.text, !isMediumScreen && styles.textMobile]}>Mi suscripción</Title>
                  </Card.Content>
                </Card>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              key="update-password"
              style={[isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile]}
            >
              <Card
                onPress={() => {
                  navigation.navigate('UpdatePasswordScreen');
                }}
              >
                <Card.Content>
                  <Title style={[styles.text, !isMediumScreen && styles.textMobile]}>Actualizar contraseña</Title>
                </Card.Content>
              </Card>
            </TouchableOpacity>
            {isStudent(userData) && (
              <TouchableOpacity
                key="my-exercises-historial"
                style={[isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile]}
              >
                <Card
                  onPress={() => {
                    navigation.navigate('ExercisesHistorialScreen', { studentId: user.id });
                  }}
                >
                  <Card.Content>
                    <Title style={[styles.text, !isMediumScreen && styles.textMobile]}>Historial de Ejercicios</Title>
                  </Card.Content>
                </Card>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              key="logout"
              style={[isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile]}
            >
              <Card onPress={clearSession}>
                <Card.Content>
                  <Title style={[styles.text, !isMediumScreen && styles.textMobile]}>Cerrar Sesión</Title>
                </Card.Content>
              </Card>
            </TouchableOpacity>

            <View>
              <Text
                style={[styles.deleteAccountText, !isMediumScreen && styles.textMobile]}
                onPress={() => {
                  setIsConfirmationModalVisible(true);
                }}
              >
                Eliminar cuenta
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
      <ConfirmationModal
        visible={isConfirmationModalVisible}
        title="Atención"
        description="¿Estás seguro de que deseas eliminar tu cuenta? "
        onDismiss={() => {
          setIsConfirmationModalVisible(false);
        }}
        onConfirm={() => {
          deleteAccount();
          setIsConfirmationModalVisible(false);
        }}
      />
      <ConfirmationModal
        visible={isDeleteAccountEmailSent}
        title="Eliminar cuenta"
        description="Vas a recibir un email para confirmar la eliminación de tu cuenta."
        onConfirm={() => {
          setIsDeleteAccountEmailSent(false);
          clearSession();
        }}
        dismissable={false}
      />
    </SafeAreaView>
  );
};

export default Profile;
