import React, { useState, useEffect } from 'react';
import { Button, Dialog, Subheading, Portal, Provider as PaperProvider, DefaultTheme, List } from 'react-native-paper';
import { View, Text, VirtualizedList } from 'react-native';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/components/userTrainingStepsModal';
import { Pressable } from 'react-native';
import { getStudentTrainingStepMarks } from 'src/services/markService';
import { Icon } from 'react-native-elements';
import { ScrollView } from 'react-native';
import MarkItem from './MarkItem';

const UserTrainingStepsModal = (props) => {
  const { visible, routine, studentId, onDismiss } = props;
  const [trainingDays, setTrainingDays] = useState([]);
  const [exerciseResumeVisible, setExerciseResumeVisible] = useState(false);
  const [trainingStepMarks, setTrainingStepMarks] = useState([]);
  const isMediumScreen = useMediumScreen();
  const [loading, setLoading] = useState(false);
  const [titleStep, setTitleStep] = useState('');
  const getTrainingDays = async () => {
    try {
      const { data } = await axiosInstance({
        method: 'get',
        url: `routines/${routine.id}/training-days?studentId=${studentId}`,
      });
      setTrainingDays(data.trainingDays);
    } catch (error) {
      console.log(error);
    }
  };
  const getStudentMarks = async (studentId, trainingStepId) => {
    setLoading(true);
    const marks = await getStudentTrainingStepMarks(studentId, trainingStepId);
    setTrainingStepMarks(marks);

    setLoading(false);
  };
  useEffect(() => {
    getTrainingDays();
  }, [routine, studentId]);

  return (
    <PaperProvider theme={DefaultTheme}>
      <Portal theme={DefaultTheme}>
        <Dialog
          onDismiss={onDismiss}
          visible={visible}
          style={isMediumScreen ? styles.containerWeb : styles.containerMobile}
        >
          {exerciseResumeVisible && (
            <View style={styles.marksHeader}>
              <Pressable onPress={() => setExerciseResumeVisible(false)}>
                <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
              </Pressable>
              <Dialog.Title style={[globalStyles.normalText, styles.titleStep]}>{titleStep}</Dialog.Title>
            </View>
          )}
          <Dialog.ScrollArea style={[styles.scrollContainer]}>
            <ScrollView>
              {loading && <Text style={globalStyles.normalText}>Cargando...</Text>}
              {trainingDays.length > 0 ? (
                exerciseResumeVisible ? (
                  trainingStepMarks.length > 0 ? (
                    <MarkItem
                      marks={trainingStepMarks}
                      title={true}
                      titleStyles={{ fontFamily: 'gilroy-semibold', fontSize: 16 }}
                      dateStyles={{ fontFamily: 'gilroy-regular', fontSize: 12 }}
                    />
                  ) : (
                    !loading && <Text style={styles.text}>No hay ejercicios agregados.</Text>
                  )
                ) : (
                  <VirtualizedList
                    data={trainingDays}
                    renderItem={({ item }) => (
                      <View style={styles.trainingDayContainer}>
                        <Text style={styles.dayTitle}>{item.day}</Text>
                        {item.training_steps.length > 0 ? (
                          item.training_steps.map((trainingStep) => (
                            <View style={styles.trainingStepContainer} key={trainingStep.id}>
                              <Text style={styles.trainingStepDescription}>
                                {trainingStep.descripcion}:
                                <Text
                                  style={[
                                    styles.trainingStepStatus,
                                    { color: trainingStep.completed ? '#007bff' : '#fb3441' },
                                  ]}
                                >
                                  {trainingStep.completed ? ' Completado' : ' Incompleto'}{' '}
                                </Text>
                              </Text>
                              <Pressable
                                onPress={() => {
                                  getStudentMarks(studentId, trainingStep.id);
                                  setExerciseResumeVisible(true);
                                  setTitleStep(`${item.day} - ${trainingStep.descripcion}`);
                                }}
                              >
                                <Text style={styles.seeMarks}>Ver resultados</Text>
                              </Pressable>
                            </View>
                          ))
                        ) : (
                          <>
                            <Subheading style={[styles.text]}>Este día no contiene partes de entrenamiento</Subheading>
                          </>
                        )}
                      </View>
                    )}
                    keyExtractor={(item, index) => `item-${item.id}-${index}`}
                    getItemCount={() => trainingDays.length}
                    getItem={(data, index) => data[index]}
                  />
                )
              ) : (
                <View style={styles.row}>
                  <Subheading style={styles.text}>No se encontraron resultados</Subheading>
                </View>
              )}
            </ScrollView>
          </Dialog.ScrollArea>

          <Dialog.Actions>
            <Button
              onPress={onDismiss}
              labelStyle={globalStyles.normalText}
              mode="text"
              color={globalStyles.buttonColor.color}
            >
              Aceptar
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </PaperProvider>
  );
};

export default UserTrainingStepsModal;
