import React, { useState, useRef } from 'react';
import { ScrollView, Text, View, Image, SafeAreaView, Platform, TextInput, TouchableOpacity } from 'react-native';
import { Formik } from 'formik';
import { Icon } from 'react-native-elements';
import formPublicationSchema from 'forms/schemas/formPublicationSchema';
import axiosInstance from 'services/axios';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'src/styles/screens/private/publicationForm';
import Spinner from 'src/components/Spinner';
import useMediumScreen from 'src/hooks/useMediumScreen';
import CustomButton from '../../components/CustomButton';
import { pickImage } from 'src/helpers/pickImage';
import { GENERIC_ERROR_MESSAGE } from 'src/constants/generic';
import { BASE_URL } from '@env';

const PublicationForm = (props) => {
  const { navigation, route } = props;
  const isMediumScreen = useMediumScreen();
  const [publicationImage, setPublicationImage] = useState(null);
  const [imageError, setImageError] = useState('');
  const [publication, setPublication] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isWeb = Platform.OS === 'web';
  const formikForm = useRef(null);
  const initialValues = { title: publication?.title ?? '', content: publication?.content ?? '' };

  React.useEffect(() => {
    getPublication();
  }, [route]);

  const getPublication = async () => {
    if (!route?.params?.publicationId) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: '/publications/' + route.params.publicationId,
      });

      setPublication(data.publication);
      if (data.publication.image.startsWith('storage')) {
        setPublicationImage(BASE_URL + data.publication.image);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('content', values.content);
    if (!publicationImage.includes('storage')) {
      formData.append('image', publicationImage);
    }

    try {
      setIsLoading(true);
      setImageError('');
      const method = 'post';
      const url = publication ? `publications/${publication.id}?_method=put` : 'publications';
      await axiosInstance({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method,
        url,
        data: formData,
      });

      navigation.navigate('PublicationsScreen', {
        refreshList: true,
      });
    } catch (error) {
      const response = error?.response;
      const status = response?.status;
      if (status === 422 && !!response?.data?.errors?.length) {
        setImageError(error.response.data.errors[0]);
      } else if (status === 413) {
        setImageError('El archivo es muy pesado, por favor ingrese uno menor a 5 MB.');
      } else {
        setImageError(GENERIC_ERROR_MESSAGE);
      }
      setTimeout(() => setImageError(''), 5000);
    } finally {
      setPublicationImage(null);
      setIsLoading(false);
    }
  };

  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      {isLoading ? (
        <View style={globalStyles.loadingContainer}>
          <Spinner />
        </View>
      ) : (
        <ScrollView contentContainerStyle={styles.scrollViewChatList} keyboardShouldPersistTaps="handled">
          <View style={globalStyles.viewContainer}>
            <View style={globalStyles.headerContainer}>
              <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
                <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
              </TouchableOpacity>
              <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
                {publication ? 'Editar' : 'Crear'} noticia
              </Text>
              <View style={globalStyles.headerEmptyIcon} />
            </View>
          </View>

          <View style={styles.publicationFormContainer}>
            <Formik
              innerRef={formikForm}
              validationSchema={formPublicationSchema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values) => onSubmit(values)}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
                <>
                  <TextInput
                    style={[outlineStyle, globalStyles.textInput]}
                    name="title"
                    placeholder="Título"
                    onChangeText={handleChange('title')}
                    onBlur={handleBlur('title')}
                    value={values.title}
                  />
                  {errors.title && touched.title && <Text style={globalStyles.inputErrorText}>{errors.title}</Text>}
                  <TextInput
                    style={[outlineStyle, globalStyles.textInput, styles.contentTextarea]}
                    name="content"
                    placeholder="Contenido"
                    onChangeText={handleChange('content')}
                    onBlur={handleBlur('content')}
                    value={values.content}
                    multiline={true}
                  />
                  {errors.content && touched.content && (
                    <Text style={globalStyles.inputErrorText}>{errors.content}</Text>
                  )}

                  <TouchableOpacity
                    onPress={async () => {
                      try {
                        const image = await pickImage();

                        if (image) {
                          setPublicationImage(image);
                        }
                      } catch (error) {
                        console.log('error al capturar imagen');
                      }
                    }}
                    style={styles.publicationImageBtn}
                  >
                    <Text style={styles.addImageBtnText}>Agregar imagen</Text>
                    <Icon name="image" color="black" size={20} />
                  </TouchableOpacity>
                  <Text style={styles.recommendedImageFormat}>
                    Se recomiendan imágenes con un formato aproximado de 1080x400px
                  </Text>

                  {publicationImage && (
                    <Image source={{ uri: publicationImage }} style={styles.publicationImagePreview} />
                  )}

                  <CustomButton
                    title={publication ? 'Editar' : 'Publicar'}
                    buttonColor="#bbebeb"
                    textColor="#3f3a40"
                    marginTop={30}
                    alignSelf="center"
                    onPress={handleSubmit}
                    disabled={!isValid || !publicationImage}
                  />
                  {!!imageError && <Text style={styles.error}>{imageError}</Text>}
                </>
              )}
            </Formik>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default PublicationForm;
