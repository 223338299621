import axiosInstance from './axios';

export const getTags = async () => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'tags',
    });
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
