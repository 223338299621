import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
  container: {
    width: 300,
    alignSelf: 'center',
  },
  fontFamily: {
    fontFamily: 'gilroy-regular',
  },
  marksContainer: { maxHeight: 220, paddingRight: 5 },
  historialItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  markTitle: {
    marginRight: 130,
    fontFamily: 'gilroy-semibold',
    color: '#000',
    fontSize: 16,
  },
  webMarkTitle: { color: '#000', fontSize: 16, fontFamily: 'gilroy-regular' },
  description: {
    display: 'flex',
    flexDirection: 'column',
  },

  itemContainer: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    fontFamily: 'gilroy-regular',
    width: '100%',
    padding: 10,
    borderRadius: 5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'gilroy-regular',
  },
  markTitle: {
    fontSize: 18,
    fontFamily: 'gilroy-semibold',
    color: 'rgb(63, 58, 64)',
  },
  time: {
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
  infoContainer: {
    fontFamily: 'gilroy-regular',
  },
  secondary: {
    fontSize: 16,
    color: 'gray',
    fontFamily: 'gilroy-regular',
  },
  description: {
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
});
