import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  spinner: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinnerMessage: {
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    marginTop: 20,
  },
});
