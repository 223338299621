import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { DataTable } from 'react-native-paper';
import Spinner from 'components/Spinner';
import { styles } from 'styles/components/customTable';
import { globalStyles } from 'styles/global';

const CustomTable = (props) => {
  const { titles, rows, renderRow, isLoading, withPagination = true } = props;
  const [page, setPage] = useState(0);

  const getItemsPerPage = () => {
    // si la tabla no requiere paginación los items per page será igual al total de items
    // sino la paginación será de 5 items per page
    if (!withPagination) {
      return rows.length;
    }
    return rows.length > 5 ? 5 : rows.length;
  };

  const itemsPerPage = getItemsPerPage();
  const from = page * itemsPerPage;
  const to = (page + 1) * itemsPerPage;
  const items = rows.slice(from, to);

  return isLoading ? (
    <View style={styles.spinner}>
      <Spinner />
    </View>
  ) : rows.length > 0 ? (
    <DataTable>
      <DataTable.Header>
        {titles.map((title) => (
          <DataTable.Title key={`title-${title}`} style={styles.title}>
            <Text style={globalStyles.normalText}>{title}</Text>
          </DataTable.Title>
        ))}
      </DataTable.Header>
      {items.map((item, index) => renderRow(item, index))}
      {withPagination && (
        <DataTable.Pagination
          page={page}
          numberOfPages={Math.ceil(rows.length / itemsPerPage)}
          onPageChange={setPage}
          label={`${from + 1} - ${to - (itemsPerPage - items.length)} de ${rows.length}`}
          style={globalStyles.normalText}
        />
      )}
    </DataTable>
  ) : (
    <DataTable.Title>No se encontraron resultados disponibles</DataTable.Title>
  );
};

export default CustomTable;
