import axiosInstance from './axios';

export const getNotifications = async () => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: '/notifications',
    });
    return data.notifications;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const updateStatus = async (id) => {
  try {
    await axiosInstance({
      method: 'post',
      url: '/notifications/update-status',
      data: { id },
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const markAllAsReaded = async (id) => {
  try {
    await axiosInstance({
      method: 'post',
      url: '/notifications/all-readed',
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};
