import React from 'react';
import { StackActions, useRoute, useLinkBuilder } from '@react-navigation/native';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';

const CustomDrawerContent = (props) => {
  const { state, userData, clearUserData, navigation, descriptors } = props;
  const route = useRoute();
  const screen = route?.params?.screen;

  const hiddenRoutes = [
    'Invitacion',
    'InvitacionEntrenador',
    'Estudiante',
    'CrearProgramacion',
    'EditarProgramacion',
    'DiasDeEntrenamiento',
    'ProgramacionesPorCarpeta',
    'EditarDiaDeEntrenamiento',
    'PartesDeEntrenamiento',
    'EditarParteDeEntrenamiento',
    'CrearParteDeEntrenamiento',
    'Ejercicios',
    'AgregarEjercicio',
    'EditarEjercicio',
    'CompraFinalizada',
    'MiSuscripcion',
    'ActualizarContrasena',
    'OlvideMiContrasena',
    'ResetearContrasena',
    'Chat',
    'CrearNoticia',
    'EditarNoticia',
    'DetalleNoticia',
  ];
  const filteredRoutes = state.routes.filter((item) => !hiddenRoutes.includes(item.name));

  const buildLink = useLinkBuilder();

  const customDrawerLabelStyle = { fontFamily: 'gilroy-regular' };

  return (
    <DrawerContentScrollView {...props}>
      {filteredRoutes.map((filteredRoute) => {
        const defaultRoute = 'Login';
        const focused = screen ? filteredRoute.name === screen : filteredRoute.name === defaultRoute;
        const {
          title,
          drawerLabel,
          drawerIcon,
          drawerActiveTintColor,
          drawerInactiveTintColor,
          drawerActiveBackgroundColor,
          drawerInactiveBackgroundColor,
          drawerLabelStyle,
          drawerItemStyle,
        } = descriptors[filteredRoute.key].options;

        return (
          <DrawerItem
            key={filteredRoute.key}
            label={drawerLabel !== undefined ? drawerLabel : title !== undefined ? title : filteredRoute.name}
            icon={drawerIcon}
            focused={focused}
            activeTintColor={drawerActiveTintColor}
            inactiveTintColor={drawerInactiveTintColor}
            activeBackgroundColor={drawerActiveBackgroundColor}
            inactiveBackgroundColor={drawerInactiveBackgroundColor}
            labelStyle={{ ...drawerLabelStyle, ...customDrawerLabelStyle }}
            style={drawerItemStyle}
            to={buildLink(filteredRoute.name, filteredRoute.params)}
            onPress={() => {
              if (focused) {
                navigation.closeDrawer();
              } else {
                navigation.navigate('Main', { screen: filteredRoute.name });
              }
            }}
          />
        );
      })}
      {!!userData && (
        <DrawerItem
          key="Logout"
          label="Cerrar sesión"
          labelStyle={customDrawerLabelStyle}
          onPress={async () => {
            navigation.closeDrawer();
            await clearUserData();
            StackActions.popToTop();
            navigation.navigate('Main', { screen: 'Login' });
          }}
        />
      )}
    </DrawerContentScrollView>
  );
};

export default CustomDrawerContent;
