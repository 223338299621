import { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import axios from 'axios';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { SSO_LOGIN } from 'constants/enums/ssoLogin';
import { GOOGLE_EXPO_CLIENT_ID, GOOGLE_ANDROID_CLIENT_ID, GOOGLE_IOS_CLIENT_ID, GOOGLE_WEB_CLIENT_ID } from '@env';

WebBrowser.maybeCompleteAuthSession();

const useGoogleAuth = () => {
  const [androidAuthResponse, setAndroidAuthResponse] = useState(null);
  const [userResponse, setUserResponse] = useState(null);
  const [, response, promptAsync] = Google.useAuthRequest({
    expoClientId: GOOGLE_EXPO_CLIENT_ID,
    androidClientId: GOOGLE_ANDROID_CLIENT_ID,
    iosClientId: GOOGLE_IOS_CLIENT_ID,
    webClientId: GOOGLE_WEB_CLIENT_ID,
  });

  useEffect(() => {
    const initAsync = async () => {
      try {
        if (Platform.OS === 'android' && Constants.appOwnership !== 'expo') {
          // await GoogleSignIn.initAsync();
        }
      } catch (error) {
        console.log(error);
      }
    };

    initAsync();
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      if (androidAuthResponse?.type === 'success' || response?.type === 'success') {
        const authResponse = androidAuthResponse ?? response;
        try {
          const {
            authentication: { accessToken },
          } = authResponse;
          const { data } = await axios.get('https://www.googleapis.com/oauth2/v2/userinfo', {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          setUserResponse({
            email: data.email,
            name: data.name,
            platform_id: data.id,
            platform_type: SSO_LOGIN.google,
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    getUserData();
  }, [androidAuthResponse, response, setUserResponse]);

  const googlePromptAsync = async () => {
    if (Platform.OS === 'android' && Constants.appOwnership !== 'expo') {
      promptAsync();
      /*const { type, user } = await GoogleSignIn.signInAsync();
      if (type === 'success') {
        setAndroidAuthResponse({
          type,
          authentication: {
            accessToken: user.auth.accessToken,
          },
        });
      }*/
    } else {
      promptAsync();
    }
  };

  const googleResponse = userResponse;

  return { googleResponse, googlePromptAsync };
};

export default useGoogleAuth;
