import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  message: {
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    textAlign: 'center',
  },
  downloadContainer: {
    marginTop: 20,
    width: 350,
    height: 120,
    alignSelf: 'center',
  },
  downloadTitle: {
    fontFamily: 'gilroy-regular',
    fontSize: 21,
    alignSelf: 'center',
    marginBottom: 10,
  },
  buttonsContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 100,
    width: 350,
  },
  storeImage: {
    width: 150,
    height: 50,
    position: 'relative',
  },
  loginButtonContainer: {
    marginTop: 15,
    width: 350,
    alignItems: 'center',
    alignSelf: 'center',
  },
  instructionsContainer: {
    marginTop: 10,
    fontFamily: 'gilroy-regular',
    fontSize: 17,
  },
  loginButton: {
    color: '#c33e1d',
  },
  webAppContainer: {
    fontFamily: 'gilroy-regular',
    fontSize: 21,
    alignSelf: 'center',
  },
  webAppText: {
    color: '#c33e1d',
  },
});
