import React, { useContext, useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import useMediumScreen from 'hooks/useMediumScreen';
import appContext from 'contexts/appContext';
import { ROLES, rolesDropdown } from 'src/helpers/roles';
import SearchableDropDown from 'components/SearchableDropDown';
import CustomButton from 'components/CustomButton';
import FormRegister from 'forms/FormRegister';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/screens/auth/Register';

export default ({ navigation }) => {
  const [isRolesModalVisible, setIsRolesModalVisible] = useState(false);
  const [roleSelected, setRoleSelected] = useState(null);
  const [isSecondForm, setIsSecondForm] = useState(false);
  const isMediumScreen = useMediumScreen();
  const { isSubmitting, setIsSubmitting } = useContext(appContext);

  const currentRole = rolesDropdown.find((role) => role.value === roleSelected);
  const roleStyle = { color: currentRole ? '#3f3a40' : 'gray' };

  const onRoleConfirm = (value) => {
    setRoleSelected(value);
    setIsRolesModalVisible(false);
  };

  const defaultInfo = () => {
    return (
      <Text>
        Bienvenido/a a met app, la plataforma que te ayudará a aumentar tu productividad elaborando programaciones para
        tus clientes de forma profesional en poco tiempo. Regístrate y haz crecer tu negocio
      </Text>
    );
  };

  const studentInfo = () => {
    return (
      <Text>Bienvenido/a a met app, la plataforma donde podrás acceder a tus programaciones de entrenamiento.</Text>
    );
  };

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={styles.titleInfoContainer}>
          <Text style={globalStyles.title}>Registro</Text>
          <Text style={styles.registerDescription}>
            {roleSelected === ROLES.student.value ? studentInfo() : defaultInfo()}
          </Text>
        </View>
        {!isSecondForm ? (
          <View
            style={[
              globalStyles.alignSelfCenter,
              isMediumScreen ? styles.registerContainer : styles.registerContainerMobile,
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                setIsRolesModalVisible(true);
                setRoleSelected(null);
              }}
              style={[globalStyles.textInput, globalStyles.justifyContentCenter, outlineStyle]}
            >
              <Text style={[globalStyles.normalText, roleStyle]}>
                {currentRole?.label || 'Seleccionar un tipo de usuario*'}
              </Text>
            </TouchableOpacity>
            <CustomButton
              title="Continuar"
              buttonColor="#bbebeb"
              textColor="#3f3a40"
              marginTop={30}
              alignSelf="center"
              onPress={() => setIsSecondForm(true)}
              disabled={!roleSelected || isSubmitting}
            />
          </View>
        ) : (
          <View
            style={[
              globalStyles.contentCenteredColumn,
              isMediumScreen ? styles.registerContainer : styles.registerContainerMobile,
            ]}
          >
            <FormRegister
              navigation={navigation}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              onReturnPress={() => setIsSecondForm(false)}
              roleId={roleSelected}
            />
          </View>
        )}
      </ScrollView>
      <SearchableDropDown
        items={rolesDropdown}
        onConfirm={onRoleConfirm}
        onDismiss={() => setIsRolesModalVisible(false)}
        visible={isRolesModalVisible}
      />
    </>
  );
};
