import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  itemContainer: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    fontFamily: 'gilroy-regular',
    width: '100%',
    padding: 10,
    borderRadius: 5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'gilroy-regular',
  },
  markTitle: {
    fontSize: 18,
    fontFamily: 'gilroy-semibold',
    color: 'rgb(63, 58, 64)',
  },
  time: {
    color: 'rgb(63, 58, 64)',
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
  infoContainer: {
    fontFamily: 'gilroy-regular',
  },
  secondary: {
    fontSize: 16,
    color: 'gray',
    fontFamily: 'gilroy-regular',
    textTransform: 'capitalize',
  },
  description: {
    textTransform: 'capitalize',
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
  exerciseMarkTitle: {
    fontFamily: 'gilroy-regular',
    fontSize: 12,
    paddingLeft: 8,
    opacity: 0.7,
    color: '#3f3a40',
  },
});
