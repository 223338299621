import React from 'react';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import useMediumScreen from 'hooks/useMediumScreen';
import Spinner from 'components/Spinner';
import { styles } from 'styles/components/iframe';

const Iframe = (props) => {
  const { url } = props;

  const isMediumScreen = useMediumScreen();

  const iframe = `
    <body style='margin: 0; padding: 0;'>
      <iframe
        width="${isMediumScreen ? 500 : '100%'}"
        height="${isMediumScreen ? 300 : '100%'}"
        margin="${isMediumScreen && '0 auto'}"
        style="${isMediumScreen && 'margin: 0 auto;display:block;margin-top:10px;'}"
        src=${encodeURI(url)}
        frameborder="0"
        allow="autoplay; encrypted-media" 
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowtransparency="true"
      />
    </body>
  `;

  return Platform.OS === 'web' ? (
    <div dangerouslySetInnerHTML={{ __html: iframe }} />
  ) : (
    <WebView
      originWhitelist={['*']}
      source={{ html: iframe }}
      scalesPageToFit={Platform.OS === 'ios'}
      allowsFullscreenVideo
      renderLoading={() => <Spinner />}
      startInLoadingState
      mediaPlaybackRequiresUserAction={false}
      style={styles.webViewMobile}
    />
  );
};

export default Iframe;
