import React, { useContext, useState } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { isGym, isTrainer, isStudent } from 'helpers/roles';
import appContext from 'contexts/appContext';
import PricingBanner from 'components/PricingBanner';
import StockExercises from 'components/StockExercises';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/dashboard';

export default ({ navigation }) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const { iosInit, userData } = useContext(appContext);

  const isGymUser = isGym(userData);
  const isTrainerUser = isTrainer(userData);
  const isStudentUser = isStudent(userData);

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  const onScroll = ({ nativeEvent }) => {
    if (isCloseToBottom(nativeEvent)) {
      setIsLoadingMore(true);
      setTimeout(() => {
        setPage(page + 1);
        setIsLoadingMore(false);
      }, 1000);
    }
  };

  return (
    <>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.scrollViewContainer}
        onScroll={onScroll}
        scrollEventThrottle={400}
      >
        <View style={globalStyles.viewContainer}>
          {(isTrainerUser || isStudentUser) && (
            <View style={globalStyles.titleContainer}>
              <Text style={[globalStyles.title, styles.stockTitle]}>Stock de ejercicios</Text>
              <StockExercises isLoadingMore={isLoadingMore} page={page} />
            </View>
          )}
        </View>
      </ScrollView>
      {iosInit && <PricingBanner navigation={navigation} />}
    </>
  );
};
