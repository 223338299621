import React, { useState, useContext, useCallback, useEffect } from 'react';
import { ScrollView, View, Text, TextInput, TouchableOpacity, SafeAreaView, Platform, LogBox } from 'react-native';
import { Icon } from 'react-native-elements';
import appContext from 'src/contexts/appContext';
import useMediumScreen from 'hooks/useMediumScreen';
import { getStudentMarks } from 'src/services/markService';
import { allWordsIncluded } from 'src/utils/search';
import { styles } from 'src/styles/screens/private/exercisesHistorial';
import { globalStyles, outlineStyle } from 'styles/global';
import MarksItems from 'src/components/MarksItems';

const AllStudentsMarks = ({ navigation, route }) => {
  const isWeb = Platform.OS === 'web';
  const isMediumScreen = useMediumScreen();
  const [allMyMarkss, setAllMyMarkss] = useState([]);
  const [filteredExercises, setFilteredExercises] = useState([]);
  const { studentId } = route.params;

  const [search, setSearch] = useState('');
  const { userData } = useContext(appContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSearchValue, setCurrentSearchValue] = useState('');

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);
  const getMyMarks = useCallback(async () => {
    if (!userData.user) return;
    startLoading();

    const marks = await getStudentMarks(studentId);
    console.log(marks);
    setAllMyMarkss(
      Object.values(
        marks.reduce((result, obj) => {
          (result[obj.exercise.descripcion] || (result[obj.exercise.descripcion] = [])).push(obj);
          return result;
        }, {}),
      ).sort((a, b) => a.id - b.id),
    );

    setFilteredExercises(
      Object.values(
        marks.reduce((result, obj) => {
          (result[obj.exercise.descripcion] || (result[obj.exercise.descripcion] = [])).push(obj);
          return result;
        }, {}),
      ),
    );
    stopLoading();
  }, []);
  useEffect(() => {
    getMyMarks();
  }, [getMyMarks]);

  useEffect(() => {
    setFilteredExercises(() =>
      allMyMarkss.filter((item) =>
        allWordsIncluded(search.toLowerCase().trim().split(' '), item[0].exercise.descripcion.toLowerCase()),
      ),
    );
  }, [search]);

  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View
            style={[
              styles.headerContainer,
              isWeb && globalStyles.contentCenteredRow,
              { width: '100%', paddingHorizontal: 30 },
            ]}
          >
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[isWeb ? globalStyles.title : styles.title, !isMediumScreen && globalStyles.titleMobile]}>
              Historial de ejercicios
            </Text>
          </View>
          <View style={globalStyles.searchInputContainer}>
            <TextInput
              name="searchExercises"
              placeholder="Buscar ejercicios"
              onChangeText={(value) => setCurrentSearchValue(value)}
              onSubmitEditing={() => setSearch(currentSearchValue)}
              style={[globalStyles.searchTextInput, outlineStyle]}
              value={currentSearchValue}
            />
            {!search ? (
              <Icon name="search" size={18} color="#3e3a40" onPress={() => setSearch(currentSearchValue)} />
            ) : (
              <Icon
                name="close"
                size={18}
                color="#3e3a40"
                onPress={() => {
                  setCurrentSearchValue('');
                  setSearch('');
                }}
              />
            )}
          </View>
          <MarksItems isLoading={isLoading} filteredExercises={filteredExercises} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default AllStudentsMarks;
