import React, { useContext } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import * as Notifications from 'expo-notifications';
import { useNavigation } from '@react-navigation/native';
import { isTrainer, isStudent, isGym } from 'helpers/roles';
import { Icon } from 'react-native-elements';
import Login from 'screens/auth/Login';
import Register from 'screens/auth/Register';
import ForgotPassword from 'screens/auth/ForgotPassword';
import ResetPassword from 'screens/auth/ResetPassword';
import StockExercisesScreen from 'src/screens/private/StockExercisesScreen';
import Students from 'src/screens/private/Students';
import StudentTrainingDaysList from 'screens/private/StudentTrainingDays';
import StudentTrainingStepExercisesList from 'screens/private/StudentTrainingStepExercisesList';
import StudentProfile from 'screens/private/StudentProfile';
import RoutinesByFolder from 'screens/private/RoutinesByFolder';
import StudentRoutines from 'screens/private/StudentRoutines';
import RoutinesList from 'screens/private/Routines';
import TrainingDaysList from 'screens/private/TrainingDays';
import TrainingStepsList from 'screens/private/TrainingSteps';
import TrainingStepExercisesList from 'screens/private/TrainingStepExercises';
import Trainers from 'src/screens/private/Trainers';
import RoutinesForm from 'forms/FormRoutine';
import TrainingDayForm from 'forms/FormTrainingDay';
import TrainingStepForm from 'forms/FormTrainingStep';
import TrainingStepExercisesForm from 'forms/FormTrainingStepExercise';
import appContext from 'contexts/appContext';
import Header from 'src/components/Header';
import CustomDrawerContent from 'components/CustomDrawerContent';
import ChatList from 'src/screens/private/ChatList';
import Chat from 'src/screens/private/Chat';
import Publications from 'src/screens/private/Publications';
import PublicationForm from 'src/screens/private/PublicationForm';
import PublicationDetail from 'src/screens/private/PublicationDetail';

const BottomTab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

export default function BottomTabNavigator() {
  const { userData, clearUserData } = useContext(appContext);
  const navigation = useNavigation();
  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  React.useEffect(() => {
    if (lastNotificationResponse) {
      const data = lastNotificationResponse.notification.request.content.data;

      if (data.notificationType === 'Noticia') {
        navigation.navigate('Noticias', {
          screen: 'PublicationDetailScreen',
          params: { publicationId: data.referenceId },
        });
      }
    }
  }, [lastNotificationResponse, navigation]);

  return (
    <>
      <Header navigation={navigation} userData={userData} />
      {userData ? (
        <>
          <BottomTab.Navigator
            initialRouteName="Home"
            screenOptions={{ activeTintColor: '#3f3a40', tabStyle: { height: '90%' } }}
          >
            {!isGym(userData) && (
              <>
                <BottomTab.Screen
                  name="Home"
                  component={HomeNavigator}
                  options={{
                    tabBarLabel: 'Rutinas',
                    tabBarLabelStyle: { fontFamily: 'gilroy-bold' },
                    tabBarIcon: ({ color }) => <Icon name="assignment" size={22} color={color} />,
                    headerShown: false,
                  }}
                />
                <BottomTab.Screen
                  name="Video"
                  component={StockExercisesNavigator}
                  options={{
                    tabBarLabel: 'Vídeos',
                    tabBarLabelStyle: { fontFamily: 'gilroy-bold' },
                    tabBarIcon: ({ color }) => <Icon name="movie" size={22} color={color} />,
                    headerShown: false,
                  }}
                />
                <BottomTab.Screen
                  name="Mensajes"
                  component={ChatNavigator}
                  options={{
                    tabBarLabel: 'Mensajes',
                    tabBarLabelStyle: { fontFamily: 'gilroy-bold' },
                    tabBarIcon: ({ color }) => <Icon name="mail" size={22} color={color} />,
                    headerShown: false,
                  }}
                />
              </>
            )}
            {isTrainer(userData) && (
              <BottomTab.Screen
                name="Student"
                component={StudentsNavigator}
                options={{
                  tabBarLabel: 'Alumnos',
                  tabBarLabelStyle: { fontFamily: 'gilroy-bold' },
                  tabBarIcon: ({ color }) => <Icon name="school" size={22} color={color} />,
                  headerShown: false,
                }}
              />
            )}
            {isGym(userData) && (
              <BottomTab.Screen
                name="Trainers"
                component={TrainersNavigator}
                options={{
                  tabBarLabel: 'Entrenadores',
                  tabBarLabelStyle: { fontFamily: 'gilroy-bold' },
                  tabBarIcon: ({ color }) => <Icon name="accessibility" size={22} color={color} />,
                  headerShown: false,
                }}
              />
            )}
            <BottomTab.Screen
              name="Noticias"
              component={PublicationsNavigator}
              options={{
                tabBarLabel: 'Noticias',
                tabBarLabelStyle: { fontFamily: 'gilroy-bold' },
                tabBarIcon: ({ color }) => <Icon name="description" size={22} color={color} />,
                headerShown: false,
              }}
            />
          </BottomTab.Navigator>
        </>
      ) : (
        <Drawer.Navigator
          screenOptions={{
            headerShown: false,
          }}
          backBehavior={'history'}
          drawerContent={(props) => (
            <CustomDrawerContent userData={userData} clearUserData={clearUserData} {...props} />
          )}
        >
          <>
            <Drawer.Screen name="Login" component={Login} />
            <Drawer.Screen name="Registrarse" component={Register} />
            <Drawer.Screen name="OlvideMiContrasena" component={ForgotPassword} />
            <Drawer.Screen name="ResetearContrasena" component={ResetPassword} />
          </>
        </Drawer.Navigator>
      )}
    </>
  );
}

const HomeStack = createStackNavigator();
const StockExercisesStack = createStackNavigator();
const StudentsStack = createStackNavigator();
const TrainersStack = createStackNavigator();
const ChatStack = createStackNavigator();
const PublicationsStack = createStackNavigator();

const HomeNavigator = () => {
  const { userData } = useContext(appContext);

  return (
    <HomeStack.Navigator>
      {isStudent(userData) && (
        <>
          <HomeStack.Screen
            name="StudentsRoutinesScreen"
            component={StudentRoutines}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen
            name="StudentTrainingDaysListScreen"
            component={StudentTrainingDaysList}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen
            name="StudentTrainingStepExercisesListScreen"
            component={StudentTrainingStepExercisesList}
            options={{ headerShown: false }}
          />
        </>
      )}
      {(isTrainer(userData) || isGym(userData)) && (
        <>
          <HomeStack.Screen name="RoutinesListScreen" component={RoutinesList} options={{ headerShown: false }} />
          <HomeStack.Screen name="CreateRoutineFormScreen" component={RoutinesForm} options={{ headerShown: false }} />
          <HomeStack.Screen name="EditRoutineFormScreen" component={RoutinesForm} options={{ headerShown: false }} />
          <HomeStack.Screen
            name="RoutinesByFolderScreen"
            component={RoutinesByFolder}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen
            name="TrainingDaysListScreen"
            component={TrainingDaysList}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen name="TrainingStepsScreen" component={TrainingStepsList} options={{ headerShown: false }} />
          <HomeStack.Screen name="EditTrainingDayScreen" component={TrainingDayForm} options={{ headerShown: false }} />
          <HomeStack.Screen
            name="ExercisesScreen"
            component={TrainingStepExercisesList}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen
            name="CreateTrainingPartFormScreen"
            component={TrainingStepForm}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen
            name="EditTrainingPartFormScreen"
            component={TrainingStepForm}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen
            name="CreateExerciseScreen"
            component={TrainingStepExercisesForm}
            options={{ headerShown: false }}
          />
          <HomeStack.Screen
            name="EditExerciseScreen"
            component={TrainingStepExercisesForm}
            options={{ headerShown: false }}
          />
        </>
      )}
    </HomeStack.Navigator>
  );
};

const StockExercisesNavigator = () => {
  return (
    <StockExercisesStack.Navigator>
      <StockExercisesStack.Screen
        name="StockExercisesScreen"
        component={StockExercisesScreen}
        options={{ headerShown: false }}
      />
    </StockExercisesStack.Navigator>
  );
};

const StudentsNavigator = () => {
  return (
    <StudentsStack.Navigator>
      <StudentsStack.Screen name="StudentsScreen" component={Students} options={{ headerShown: false }} />
      <StudentsStack.Screen name="StudentProfileScreen" component={StudentProfile} options={{ headerShown: false }} />
    </StudentsStack.Navigator>
  );
};

const TrainersNavigator = () => {
  return (
    <TrainersStack.Navigator>
      <TrainersStack.Screen name="TrainersScreen" component={Trainers} options={{ headerShown: false }} />
    </TrainersStack.Navigator>
  );
};

const ChatNavigator = () => {
  return (
    <ChatStack.Navigator>
      <ChatStack.Screen name="ChatListScreen" component={ChatList} options={{ headerShown: false }} />
      <ChatStack.Screen name="ChatScreen" component={Chat} options={{ headerShown: false }} />
    </ChatStack.Navigator>
  );
};

const PublicationsNavigator = () => {
  return (
    <PublicationsStack.Navigator>
      <PublicationsStack.Screen name="PublicationsScreen" component={Publications} options={{ headerShown: false }} />
      <PublicationsStack.Screen
        name="CreatePublicationScreen"
        component={PublicationForm}
        options={{ headerShown: false }}
      />
      <PublicationsStack.Screen
        name="EditPublicationScreen"
        component={PublicationForm}
        options={{ headerShown: false }}
      />
      <PublicationsStack.Screen
        name="PublicationDetailScreen"
        component={PublicationDetail}
        options={{ headerShown: false }}
      />
    </PublicationsStack.Navigator>
  );
};
