import axiosInstance from './axios';

export const getMarks = async () => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: '/marks',
    });
    return data.marks;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const storeMark = async (mark) => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: '/marks',
      data: mark,
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getStudentTrainingStepMarks = async (studentId, trainingStepId) => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/marks/students/${studentId}/training-steps/${trainingStepId}`,
    });
    return data.marks;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getMarksByRoutine = async (studentId, routineId) => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/marks/students/${studentId}/marks/${routineId}`,
    });
    return data.marks;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getStudentMarks = async (studentId) => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/marks/students/${studentId}/marks`,
    });
    return data.marks;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getExerciseMarks = async (id, routineId) => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/marks/exercises/${id}`,
      params: { routineId },
    });
    return data.marks;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getMarkTypes = async () => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/marks/mark-types`,
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getMeasureTypes = async () => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/marks/measure-types`,
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
