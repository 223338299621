import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  containerWeb: {
    width: '50%',
    maxHeight: '80%',
    alignSelf: 'center',
  },
  containerMobile: {
    width: '80%',
    maxHeight: '80%',
    alignSelf: 'center',
  },
  scrollContainer: {
    paddingHorizontal: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  text: {
    fontFamily: 'gilroy-regular',
    width: '90%',
    paddingLeft: 8,
  },
  replyToContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#f6f6f6',
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: '#dfdfdf',
  },
  replyToText: {
    color: '#625e5e',
  },
  commentSection: {
    marginTop: 10,
    flexDirection: 'row',
    backgroundColor: '#fff',
    paddingRight: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  commentIcon: {
    marginTop: 10,
    color: '#f00',
  },
  commentInput: {
    flex: 3,
    fontFamily: 'gilroy-regular',
    color: '#3f3a40',
    fontSize: 18,
    height: 45,
    backgroundColor: '#FFF',
  },
});
