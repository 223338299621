import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  titleInfoContainer: {
    width: '80%',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  title: {
    fontFamily: 'gilroy-regular',
    fontSize: 40,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  registerDescription: {
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    color: 'grey',
    justifyContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: 30,
  },
  registerContainer: {
    width: '50%',
    alignItems: 'center',
  },
  registerContainerMobile: {
    width: '80%',
    alignItems: 'center',
  },
  registerContainerSpinner: {
    height: 200,
  },
});
