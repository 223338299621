import React, { useContext, useState, useEffect } from 'react';
import { ScrollView, Text, TouchableOpacity, View, Image, SafeAreaView, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import { globalStyles } from 'styles/global';
import { styles } from 'src/styles/screens/private/chatList';
import Spinner from 'src/components/Spinner';
import SearchableDropDown from 'src/components/SearchableDropDown';
import DefaultUserImage from 'assets/default-user-image.png';
import { BASE_URL } from '@env';

const ChatList = (props) => {
  const { navigation } = props;
  const [chats, setChats] = useState([]);
  const [isContactSelectModalVisible, setIsContactSelectModalVisible] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userData, setContactChat } = useContext(appContext);
  const isWeb = Platform.OS === 'web';
  const user = userData?.user;
  const contactsDropDown = contacts.map((contact) => ({ label: contact.name, value: contact.id }));

  useFocusEffect(
    React.useCallback(() => {
      const getChats = async () => {
        try {
          const { data } = await axiosInstance({
            method: 'get',
            url: 'user/get-chats',
          });
          setChats(data.chats);
        } catch (error) {
          console.log(error);
        }
      };

      const interval = setInterval(async () => {
        await getChats();
      }, 1500);

      return () => {
        clearInterval(interval);
      };
    }, []),
  );
  const getContacts = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance({
        method: 'get',
        url: 'user/get-contacts',
      });
      setContacts(data.contacts);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  const ChatItem = ({ chat }) => (
    <TouchableOpacity
      onPress={() => {
        const findContact = contacts.find(({ id }) => id === chat.contact.id);
        setContactChat(findContact);
        navigation.navigate('ChatScreen', { contactId: chat.contact.id });
      }}
    >
      <View style={styles.chatItemContainer}>
        <View style={styles.profileImageContainer}>
          <Image
            source={chat.contact?.image ? { uri: BASE_URL + chat.contact?.image } : DefaultUserImage}
            style={styles.contactProfileImage}
          />
        </View>
        <View style={styles.contactInfoContainer}>
          <Text style={styles.contactName}>{chat.contact.name}</Text>
          <Text
            style={[
              styles.lastMessage,
              chat.lastMessage.readed === 0 && chat.lastMessage.from_user_id !== user?.id && styles.unreadedMessage,
            ]}
          >
            {chat.lastMessage.message}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      {isLoading ? (
        <View style={globalStyles.loadingContainer}>
          <Spinner />
        </View>
      ) : (
        <ScrollView contentContainerStyle={styles.scrollViewChatList} keyboardShouldPersistTaps="handled">
          {chats.length === 0 && <Text style={styles.noMessagesText}>Sin mensajes creados</Text>}
          {chats.length > 0 && chats.map((chat, index) => <ChatItem key={index} chat={chat} />)}
        </ScrollView>
      )}
      {contacts.length > 0 && (
        <>
          <SearchableDropDown
            items={contactsDropDown}
            onConfirm={(contactId) => {
              const findContact = contacts.find(({ id }) => id === contactId);
              setContactChat(findContact);
              setIsContactSelectModalVisible(false);
              navigation.navigate('ChatScreen', { contactId });
            }}
            onDismiss={() => setIsContactSelectModalVisible(false)}
            visible={isContactSelectModalVisible}
          />
          <TouchableOpacity style={styles.newMessageButton} onPress={() => setIsContactSelectModalVisible(true)}>
            <Icon name="message" size={30} color="#c33e1d" />
          </TouchableOpacity>
        </>
      )}
    </SafeAreaView>
  );
};

export default ChatList;
