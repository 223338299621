import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '90%',
    alignItems: 'center',
    marginBottom: 15,
  },
  containerWeb: {
    width: '35%',
  },
  loginContainer: {
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  logoText: {
    fontFamily: 'gilroy-regular',
    fontSize: 48,
    color: 'grey',
  },
  bottomTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 35,
    fontFamily: 'gilroy-regular',
    fontSize: 20,
    color: 'grey',
    marginVertical: 20,
    marginHorizontal: 10,
  },
  bottomText: {
    fontSize: 14,
    marginTop: 10,
  },
  polyticsLinkText: {
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
    color: '#3f3a40',
  },
});
