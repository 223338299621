import * as React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { styles } from 'styles/components/breadcrumb';

const Breadcrumb = (props) => {
  const { routes } = props;

  return (
    <View style={styles.container}>
      {routes.map(({ label, onPress }, index) => {
        const isLastItem = index < routes.length - 1;
        return (
          <View key={`breadcrumb-${index}`} style={styles.content}>
            <TouchableOpacity onPress={onPress}>
              <Text style={styles.label}>{label}</Text>
            </TouchableOpacity>
            {isLastItem && <Text style={styles.separator}>{'>'}</Text>}
          </View>
        );
      })}
    </View>
  );
};

export default Breadcrumb;
