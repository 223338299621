import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
  containerForSafeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 20,
    width: '100%',
    paddingHorizontal: 30,
  },

  wrapper: { width: '100%' },
  webWrapper: { width: '70%', padding: 10 },
  title: {
    fontFamily: 'gilroy-bold',
    fontSize: 24,
    textAlign: 'center',
    color: '#c33e1d',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    paddingRight: 45,
  },
  asReadButton: { fontFamily: 'gilroy-semibold', fontSize: 16, color: '#c33e1d', paddingLeft: 8, marginBottom: 10 },
  time: { fontSize: 12, textTransform: 'capitalize' },
  mr0: { marginRight: 0 },
  normalText: { fontFamily: 'gilroy-regular' },
  boldText: { fontFamily: 'gilroy-bold' },
  subtitleContainer: { flexDirection: 'row', justifyContent: 'space-between', width: '100%' },
  message: { flex: 1 },
  notNotifications: { textAlign: 'center', fontSize: 16 },
});
