import React, { useContext } from 'react';
import { Platform } from 'react-native';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import BottomTabNavigator from './BottomTabNavigation';
import { navigationRef } from './RootNavigation';
import appContext from 'contexts/appContext';
import InviteUser from 'screens/auth/InviteUser';
import InviteTrainer from 'screens/auth/InviteTrainer';
import Verify from 'screens/auth/Verify';
import Payment from 'screens/private/Payment';
import Profile from 'src/screens/private/Profile';
import Notifications from 'src/screens/private/Notifications';
import UpdatePassword from 'src/screens/private/UpdatePassword';
import ExercisesHistorial from 'src/screens/private/ExercisesHistorial';
import AllStudentsMarks from 'src/screens/private/AllStudentsMarks';
import Subscriptions from 'screens/private/Subscriptions';
import CustomerPortal from 'screens/private/CustomerPortal';
import PaymentFinished from 'src/screens/private/PaymentFinished';
import { PREFIXES } from '@env';

const Stack = createStackNavigator();
const isWeb = Platform.OS === 'web';

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'white',
    primary: '#3f3a40',
    text: '#3f3a40',
  },
};

export default () => {
  const { userData } = useContext(appContext);

  const config = {
    screens: {
      Main: {
        screens: {
          Login: 'login',
          Registrarse: 'registrarse',
          CompraFinalizada: 'CompraFinalizada',
          ResetearContrasena: 'ResetearContrasena/:token',
        },
      },
      Invitacion: 'Invitacion/:token',
      InvitacionEntrenador: 'InvitacionEntrenador/:token',
      Verify: 'verify/:id/:hash?',
    },
  };

  const splittedPrefixes = PREFIXES.split(',');
  const linking = {
    prefixes: Array.isArray(splittedPrefixes) ? splittedPrefixes : PREFIXES,
    config,
  };

  return (
    <NavigationContainer ref={navigationRef} linking={linking} theme={MyTheme}>
      <Stack.Navigator headerMode="screen" screenOptions={{ headerShown: false }}>
        <>
          <Stack.Screen name="Main" component={BottomTabNavigator} />
          <Stack.Screen name="ProfileScreen" component={Profile} options={{ headerShown: false }} />
          <Stack.Screen name="Notifications" component={Notifications} options={{ headerShown: false }} />
          <Stack.Screen name="UpdatePasswordScreen" component={UpdatePassword} options={{ headerShown: false }} />
          <Stack.Screen
            name="ExercisesHistorialScreen"
            component={ExercisesHistorial}
            options={{ headerShown: false }}
          />
          <Stack.Screen name="AllStudentsMarks" component={AllStudentsMarks} options={{ headerShown: false }} />
          <Stack.Screen name="MySubscriptionScreen" component={CustomerPortal} options={{ headerShown: false }} />
          <Stack.Screen name="MySubscriptionsScreen" component={Subscriptions} options={{ headerShown: false }} />
          <Stack.Screen name="PaymentScreen" component={Payment} options={{ headerShown: false }} />
          <Stack.Screen name="FinishedPaymentScreen" component={PaymentFinished} options={{ headerShown: false }} />
          <Stack.Screen name="Invitacion" component={InviteUser} options={{ headerShown: false }} />
          <Stack.Screen name="InvitacionEntrenador" component={InviteTrainer} options={{ headerShown: false }} />
          <Stack.Screen name="Verify" component={Verify} options={{ headerShown: false }} />
        </>
      </Stack.Navigator>
    </NavigationContainer>
  );
};
