import * as React from 'react';
import { useRef, useState } from 'react';
import { Button, Dialog, Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import Modal from 'react-native-modal';
import { Text, View, TextInput, Platform, KeyboardAvoidingView } from 'react-native';
import { Formik } from 'formik';
import { getMarkTypes, getMeasureTypes } from 'services/markService';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/components/resultsRoutineForm';
import CustomMarkDropdown from './CustomMarkDropdown';

const ResultsRoutineForm = (props) => {
  const {
    isOtraActividadInputsVisible,
    setIsOtraActividadInputsVisible,
    isNumberAvailableType,
    setIsNumberAvailableType,
    isNumberAvailableMeasure,
    setIsNumberAvailableMeasure,
    isOtherMeasureLabelVisible,
    setIsOtherMeasureLabelVisible,
    isTimeDetailsInputsVisible,
    setIsTimeDetailsInputsVisible,
    emptyForm,

    visible,
    title = null,
    onDismiss,
    onConfirm,
  } = props;

  const [markTypesIds, setMarkTypesIds] = useState([]);
  const [measureTypesIds, setMeasureTypesIds] = useState([]);

  const loadMarksAndMeasureTypes = React.useCallback(async () => {
    const markTypes = await getMarkTypes();
    const measureTypes = await getMeasureTypes();
    if (markTypes) {
      setMarkTypesIds(markTypes);
    }
    if (measureTypes) {
      setMeasureTypesIds(measureTypes);
    }
  }, []);
  const formikForm = useRef(null);
  const initialValues = {
    markValue: '',
    markTypeId: '',
    otherMarkLabel: '',

    measureValue: '',
    measureTypeId: '',
    otherMeasureLabel: '',

    annotations: '',
    timeHoursValue: '',
    timeMinutesValue: '',
    timeSecondsValue: '',
  };
  React.useEffect(() => {
    loadMarksAndMeasureTypes();
  }, [loadMarksAndMeasureTypes]);

  return (
    <PaperProvider theme={DefaultTheme}>
      <Modal
        animationType="slide"
        transparent={true}
        isVisible={visible}
        stlye={{ backgroundColor: '#807c7c' }}
        onDismiss={onDismiss}
        onRequestClose={onDismiss}
        onBackdropPress={onDismiss}
      >
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.wrapper}>
          <View style={styles.container}>
            {title !== null && <Dialog.Title style={globalStyles.normalText}>{title}</Dialog.Title>}
            <Dialog.Content>
              {emptyForm && <Text style={styles.errorText}>Debes rellenar al menos un campo</Text>}
              <Formik innerRef={formikForm} initialValues={initialValues} enableReinitialize onSubmit={onConfirm}>
                {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                  <>
                    <View style={[styles.inputsCtn]}>
                      <CustomMarkDropdown
                        items={markTypesIds}
                        name={'markTypeId'}
                        selectedValue={values.markTypeId}
                        callback={(v) => {
                          setIsOtraActividadInputsVisible(() => (v === 1 ? true : false));
                          setIsNumberAvailableType(true);
                          setFieldValue('markTypeId', v);
                        }}
                      />
                      {isOtraActividadInputsVisible ? (
                        <OtraActividadDetails
                          name={'otherMarkLabel'}
                          placeholder={'¿Cúal?'}
                          onChangeText={handleChange}
                          value={values.name}
                        />
                      ) : null}
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle, styles.input]}
                        name="markValue"
                        placeholder="Número"
                        onChangeText={handleChange('markValue')}
                        onBlur={handleBlur('markValue')}
                        value={values.markValue}
                        autoCapitalize="words"
                        keyboardType={'decimal-pad'}
                        editable={isNumberAvailableType}
                        disabled={!isNumberAvailableType}
                      />
                    </View>
                    <View style={[styles.inputsCtn]}>
                      <View>
                        <CustomMarkDropdown
                          items={measureTypesIds}
                          name={'measureTypeId'}
                          selectedValue={values.measureTypeId}
                          callback={(v) => {
                            if (!v) {
                              return;
                            }
                            setIsTimeDetailsInputsVisible(() => (v === 5 ? true : false));
                            setIsOtherMeasureLabelVisible(() => (v === 1 ? true : false));
                            setIsNumberAvailableMeasure(true);
                            setFieldValue('measureTypeId', v);
                          }}
                        />
                      </View>
                      {isOtherMeasureLabelVisible ? (
                        <OtraActividadDetails
                          name={'otherMeasureLabel'}
                          placeholder={'¿Cúal?'}
                          onChangeText={handleChange}
                          value={values.name}
                        />
                      ) : null}
                      {isTimeDetailsInputsVisible ? (
                        <>
                          <View style={[styles.timeInputsCtn]}>
                            <TextInput
                              name="timeHoursValue"
                              value={values.timeHoursValue}
                              onChangeText={handleChange('timeHoursValue')}
                              style={[styles.timeInputs, styles.capitalizeText, styles.timeInput]}
                              placeholder="hora(s)"
                              keyboardType="numeric"
                            />
                            <TextInput
                              name="timeMinutesValue"
                              value={values.timeMinutesValue}
                              onChangeText={handleChange('timeMinutesValue')}
                              style={[styles.timeInputs, styles.capitalizeText, styles.timeInput]}
                              placeholder="minutos"
                              keyboardType="numeric"
                            />
                            <TextInput
                              name="timeSecondsValue"
                              value={values.timeSecondsValue}
                              onChangeText={handleChange('timeSecondsValue')}
                              style={[styles.timeInputs, styles.capitalizeText, styles.timeInput]}
                              placeholder="segundos"
                              keyboardType="numeric"
                            />
                          </View>
                        </>
                      ) : (
                        <TextInput
                          style={[globalStyles.textInput, outlineStyle, styles.input]}
                          name="measureValue"
                          placeholder="Número"
                          onChangeText={handleChange('measureValue')}
                          onBlur={handleBlur('measureValue')}
                          autoCapitalize="words"
                          keyboardType={'decimal-pad'}
                          value={values.measureValue}
                          editable={isNumberAvailableMeasure}
                          disabled={!isNumberAvailableMeasure}
                        />
                      )}
                    </View>
                    <TextInput
                      editable
                      multiline
                      style={[globalStyles.textInput, outlineStyle, styles.inputMultiline]}
                      name="annotations"
                      placeholder="Anotaciones"
                      onChangeText={handleChange('annotations')}
                      onBlur={handleBlur('annotations')}
                      value={values.annotations}
                    />

                    {errors.markValue && touched.markValue && (
                      <Text style={globalStyles.inputErrorText}>{errors.markValue}</Text>
                    )}
                    {errors.measureValue && touched.measureValue && (
                      <Text style={globalStyles.inputErrorText}>{errors.measureValue}</Text>
                    )}
                    {errors.annotations && touched.annotations && (
                      <Text style={globalStyles.inputErrorText}>{errors.annotations}</Text>
                    )}
                    <Dialog.Actions style={globalStyles.dialogActionsPadding}>
                      <ActionButtons value="Cancelar" onPress={onDismiss} />
                      <ActionButtons
                        isOtherMeasureLabelVisible={isOtherMeasureLabelVisible}
                        otherMeasureLabel={formikForm.current?.values?.otherMeasureLabel}
                        measureValue={formikForm.current?.values?.measureValue}
                        value="Añadir"
                        onPress={handleSubmit}
                      />
                    </Dialog.Actions>
                  </>
                )}
              </Formik>
            </Dialog.Content>
          </View>
        </KeyboardAvoidingView>
      </Modal>
    </PaperProvider>
  );
};
export default ResultsRoutineForm;

function OtraActividadDetails({ name, placeholder, onChangeText, value }) {
  return (
    <View style={styles.otraActividadInputCtn}>
      <TextInput
        name={name}
        style={[styles.timeInputs, styles.otraActividadInput]}
        placeholder={placeholder}
        onChangeText={onChangeText(name)}
        value={value}
      />
    </View>
  );
}

function ActionButtons({ isOtherMeasureLabelVisible, otherMeasureLabel, measureValue, onPress, value }) {
  const otherLabelIsEmpty = () => {
    if (isOtherMeasureLabelVisible && (otherMeasureLabel === '' || measureValue === '')) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Button
      mode="text"
      disabled={otherLabelIsEmpty()}
      color={globalStyles.buttonColor.color}
      labelStyle={[globalStyles.normalText]}
      onPress={onPress}
    >
      {value}
    </Button>
  );
}
