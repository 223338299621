import React from 'react';
import { View, Text } from 'react-native';
import { Card, List } from 'react-native-paper';
import useMediumScreen from 'hooks/useMediumScreen';
import Iframe from 'components/Iframe';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/components/cardExercise';
import CustomButton from './CustomButton';

export default (props) => {
  const {
    showProgressButton = false,
    exercise,
    comments,
    observations,
    showTags = true,
    isPreview = false,
    setIsResultsRoutineModalVisible,
    setIsHistorialModalVisible,
    index,
  } = props;
  const isMediumScreen = useMediumScreen();

  return (
    <>
      <Card
        key={`exercise-${exercise.id}`}
        style={[
          isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile,
          isPreview && styles.isPreviewStyle,
        ]}
      >
        <List.Accordion
          theme={{ colors: { primary: '#3f3a40' } }}
          title={
            <>
              <Text style={[styles.exerciseTitle]}>{exercise.descripcion}</Text>
              {!!comments && (
                <Text style={globalStyles.normalText}>
                  {'\n'}
                  {comments}
                </Text>
              )}
            </>
          }
          titleNumberOfLines={100}
        >
          {!isMediumScreen && exercise.video && isPreview === false && <Iframe url={exercise.video} />}
          {!isMediumScreen && !!exercise.video && isPreview && <Iframe url={exercise.video} />}
          {isMediumScreen && !!exercise.video && <Iframe url={exercise.video} />}
          <View style={isMediumScreen && styles.infoContainer}>
            {showTags && (
              <>
                {!!exercise.articulaciones?.length && (
                  <Text style={styles.textContainer}>
                    Articulaciones: {exercise.articulaciones.map((item) => item.descripcion).join(', ')}.
                  </Text>
                )}
                {!!exercise.categorias?.length && (
                  <Text style={styles.textContainer}>
                    Acciones: {exercise.categorias.map((item) => item.descripcion).join(', ')}.
                  </Text>
                )}
                {!!exercise.elementos?.length && (
                  <Text style={styles.textContainer}>
                    Materiales: {exercise.elementos.map((item) => item.descripcion).join(', ')}.
                  </Text>
                )}
                {!!exercise.etiquetas?.length && (
                  <Text style={styles.textContainer}>
                    Etiquetas: {exercise.etiquetas.map((item) => item.descripcion).join(', ')}.
                  </Text>
                )}
                {!!exercise.metodos?.length && (
                  <Text style={styles.textContainer}>
                    Complejos musculares: {exercise.metodos.map((item) => item.descripcion).join(', ')}.
                  </Text>
                )}
                {!!exercise.musculos?.length && (
                  <Text style={styles.textContainer}>
                    Músculos: {exercise.musculos.map((item) => item.descripcion).join(', ')}.
                  </Text>
                )}
                {!!exercise.objetivos?.length && (
                  <Text style={styles.textContainer}>
                    Objetivos: {exercise.objetivos.map((item) => item.descripcion).join(', ')}.
                  </Text>
                )}
                {!!exercise.poblaciones?.length && (
                  <Text style={styles.textContainer}>
                    Poblaciones: {exercise.poblaciones.map((item) => item.descripcion).join(', ')}.
                  </Text>
                )}
              </>
            )}
            {!!observations && <Text style={styles.textContainer}>Observaciones: {observations}</Text>}

            {showProgressButton ? (
              <View style={styles.buttonsContainer}>
                <CustomButton
                  title={'Añadir Resultados'}
                  buttonColor="#bbebeb"
                  textColor="#3f3a40"
                  width={163}
                  alignSelf="center"
                  onPress={() => {
                    setIsResultsRoutineModalVisible(index);
                  }}
                />
                <CustomButton
                  title={'Historial de Resultados'}
                  buttonColor="#bbebeb"
                  textColor="#3f3a40"
                  width={163}
                  alignSelf="center"
                  onPress={() => setIsHistorialModalVisible(index)}
                />
              </View>
            ) : null}
          </View>
        </List.Accordion>
      </Card>
    </>
  );
};
