import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { DataTable } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import Breadcrumb from 'components/Breadcrumb';
import Spinner from 'components/Spinner';
import CustomTable from 'components/CustomTable';
import ConfirmationModal from 'components/ConfirmationModal';
import CustomButton from 'components/CustomButton';
import { reSortItemsByOrder, getRequestToReOrder } from 'helpers/utils';
import { getDescriptionName } from 'utils/parseDescriptionName';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/trainingDays';
import appContext from 'src/contexts/appContext';

export default ({ navigation, route }) => {
  const {
    routineId,
    routineDescription,
    trainingDayId,
    trainingDayDescription,
    trainingStepId,
    trainingStepDescription,
    refreshList,
    routineIsGeneric,
  } = route.params;

  const [trainingStepExercises, setTrainingStepExercises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSaveOrderBtn, setShowSaveOrderBtn] = useState(false);
  const [trainingStepExerciseIdSelected, setTrainingStepExerciseIdSelected] = useState(null);
  const { userData } = useContext(appContext);

  const isMediumScreen = useMediumScreen();

  const breadcrumbRoutes = [
    {
      label: routineDescription,
      onPress: () => {
        navigation.navigate('RoutinesListScreen', {});
      },
    },
    {
      label: trainingDayDescription,
      onPress: () => {
        navigation.navigate('TrainingDaysListScreen', {
          routineId,
          routineDescription,
          refreshList: true,
          routineIsGeneric,
        });
      },
    },
    {
      label: trainingStepDescription,
      onPress: () => {
        navigation.navigate('TrainingStepsScreen', {
          routineId,
          routineDescription,
          trainingDayId,
          trainingDayDescription,
          refreshList: true,
          routineIsGeneric,
        });
      },
    },
    {
      label: 'Ejercicios',
      onPress: () => {},
    },
  ];

  useEffect(() => {
    getTrainingStepExercises();
  }, [getTrainingStepExercises, trainingStepId]);

  useEffect(() => {
    !!refreshList && getTrainingStepExercises();
  }, [getTrainingStepExercises, route]);

  const getTrainingStepExercises = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: `training-steps/${trainingStepId}/exercises`,
      });
      setTrainingStepExercises(data.trainingStepExercises);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [trainingStepId]);

  const canDoActions = () => {
    if (!routineIsGeneric) {
      //routineIsGeneric solamente se setea cuando la rutina es genérica
      // entonces si no se setea significa que la rutina tiene dueño
      return true;
    }
    return parseInt(routineIsGeneric, 10) === userData?.user?.id;
  };

  const getIconColor = () => {
    return canDoActions() ? '#f2a172' : '#b9aeae';
  };

  const getColumnTitles = () => {
    const titles = ['Descripción', 'Acciones'];

    if (canDoActions()) {
      titles.push('Orden');
    }

    return titles;
  };

  const showConfirmationModal = (trainingStepExerciseId) => {
    setTrainingStepExerciseIdSelected(trainingStepExerciseId);
    setIsModalVisible(true);
  };

  const deleteTrainingStepExerciseById = async (trainingStepExerciseId) => {
    try {
      setIsModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: `training-steps/${trainingStepId}/exercises/${trainingStepExerciseId}`,
      });
      await getTrainingStepExercises();
    } catch (error) {
      console.log(error);
    } finally {
      setTrainingStepExerciseIdSelected(null);
      setIsLoading(false);
    }
  };

  const reSortExercises = (trainingStepExerciseId) => {
    const index = trainingStepExercises.findIndex(
      (trainingStepExercise) => trainingStepExercise.id === trainingStepExerciseId,
    );
    const newTrainingStepExercises = reSortItemsByOrder(trainingStepExercises, index);
    setShowSaveOrderBtn(true);
    setTrainingStepExercises(newTrainingStepExercises);
  };

  const saveTrainingStepExercisesOrder = async () => {
    const request = getRequestToReOrder(trainingStepExercises);
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'post',
        url: `training-steps/${trainingStepId}/exercises/re-order`,
        data: { trainingStepExercises: request },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setShowSaveOrderBtn(false);
    }
  };

  const renderTrainingStepExerciseRow = (trainingStepExercise) => (
    <DataTable.Row key={`training-step-excercise-${trainingStepExercise.id}`}>
      <DataTable.Cell
        style={[
          globalStyles.tableCell,
          { justifyContent: 'flex-start', paddingLeft: isMediumScreen ? 200 : 0, minWidth: 110 },
        ]}
      >
        <TouchableOpacity style={styles.iconAndDescriptionContainer}>
          <Icon name={'fitness-center'} size={22} style={{ alignSelf: 'flex-start' }} color="#f2a172" />
          <Text style={globalStyles.normalText}>{getDescriptionName(trainingStepExercise.exercise.descripcion)}</Text>
        </TouchableOpacity>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('EditExerciseScreen', {
              routineId,
              routineDescription,
              trainingDayId,
              trainingDayDescription,
              trainingStepId,
              trainingStepDescription,
              trainingStepExerciseId: trainingStepExercise.id,
              exerciseId: trainingStepExercise.exercise_id,
              comments: trainingStepExercise.comments,
              observations: trainingStepExercise.observations,
            });
          }}
          disabled={!canDoActions()}
        >
          <Icon name="edit" size={22} color={getIconColor()} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => showConfirmationModal(trainingStepExercise.id)} disabled={!canDoActions()}>
          <Icon name="delete" size={22} color={getIconColor()} style={globalStyles.tableIcon} />
        </TouchableOpacity>
      </DataTable.Cell>
      {canDoActions() ? (
        <DataTable.Cell style={globalStyles.tableCell}>
          {trainingStepExercise.order !== 1 ? (
            <TouchableOpacity onPress={() => reSortExercises(trainingStepExercise.id)} style={{ alignItems: 'center' }}>
              <Icon name="arrow-upward" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
          ) : null}
        </DataTable.Cell>
      ) : null}
    </DataTable.Row>
  );

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Ejercicios
            </Text>
            <TouchableOpacity disabled={isLoading} onPress={getTrainingStepExercises}>
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          <Breadcrumb routes={breadcrumbRoutes} />
          {isLoading ? (
            <View style={globalStyles.loadingContainer}>
              <Spinner />
            </View>
          ) : (
            <View style={globalStyles.tableContainer}>
              {canDoActions() ? (
                <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                  <Text style={[globalStyles.tableTitle, globalStyles.marginTop0, globalStyles.marginRight10]}>
                    Agregar un nuevo ejercicio
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('CreateExerciseScreen', {
                        routineId,
                        routineDescription,
                        trainingDayId,
                        trainingDayDescription,
                        trainingStepId,
                        trainingStepDescription,
                      });
                    }}
                  >
                    <Icon name="add-circle" size={22} color="#f2a172" />
                  </TouchableOpacity>
                </View>
              ) : null}

              {showSaveOrderBtn && (
                <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                  <CustomButton
                    title={'Guardar orden'}
                    buttonColor="#bbebeb"
                    textColor="#3f3a40"
                    marginTop={25}
                    onPress={() => saveTrainingStepExercisesOrder()}
                  />
                </View>
              )}
              <CustomTable
                titles={getColumnTitles()}
                rows={trainingStepExercises}
                renderRow={renderTrainingStepExerciseRow}
                isLoading={isLoading}
              />
            </View>
          )}
        </View>
      </ScrollView>
      <ConfirmationModal
        visible={isModalVisible}
        title="Atención"
        description="¿Estás seguro de eliminar el ejercicio?"
        onDismiss={() => setIsModalVisible(false)}
        onConfirm={() => deleteTrainingStepExerciseById(trainingStepExerciseIdSelected)}
      />
    </>
  );
};
