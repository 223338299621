import React, { useState } from 'react';
import { Platform, View, Text, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import { Picker } from '@react-native-picker/picker';
import Modal from 'react-native-modal';
import { Button, Dialog } from 'react-native-paper';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/components/customMarkDropdown';

export default ({ items, name, selectedValue, callback }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const isIOS = Platform.OS === 'ios';
  const renderPicker = () => (
    <Picker
      name={name}
      selectedValue={selectedValue}
      onValueChange={(itemValue) => {
        if (!itemValue) {
          return;
        }
        let v = parseInt(itemValue);
        setCurrentValue(() => {
          const item = items.find((el) => el.id === v);
          return item?.description;
        });
        callback(v);
      }}
      style={styles.picker}
    >
      <Picker.Item label={'Selecciona...'} value={null} />
      {items.map((item, index) => (
        <Picker.Item key={index + 1} label={item.description} value={item.id} />
      ))}
    </Picker>
  );

  return (
    <>
      <View>
        {isIOS ? (
          <TouchableOpacity
            style={styles.pickerIOS}
            onPress={() => {
              setModalOpen(true);
            }}
          >
            <Text style={globalStyles.normalText}>{currentValue || 'Selecciona...'}</Text>
            <Icon name="expand-more" color="#3f3a40" />
          </TouchableOpacity>
        ) : (
          renderPicker()
        )}
      </View>

      {isIOS && (
        <Modal
          isVisible={isModalOpen}
          onBackdropPress={() => {
            setModalOpen(false);
          }}
        >
          <View style={styles.modalContainer}>
            {renderPicker()}
            <Dialog.Actions style={styles.iosContainer}>
              <Button
                mode="text"
                color={globalStyles.buttonColor.color}
                labelStyle={[globalStyles.normalText]}
                onPress={() => {
                  setModalOpen(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                onPress={() => {
                  setModalOpen(false);
                }}
                mode="text"
                color={globalStyles.buttonColor.color}
                labelStyle={[globalStyles.normalText]}
              >
                Aceptar
              </Button>
            </Dialog.Actions>
          </View>
        </Modal>
      )}
    </>
  );
};
