import { Fragment, useState } from 'react';
import moment from 'moment/moment';
import { List } from 'react-native-paper';
import { Pressable, View, Text } from 'react-native';
import useMediumScreen from 'hooks/useMediumScreen';
import { globalStyles } from 'src/styles/global';
import { styles } from 'src/styles/components/marksItems';
import Spinner from 'components/Spinner';
import MarkItem from './MarkItem';

const MarksItems = ({ isLoading, filteredExercises }) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const [openFilterAccordion, setOpenFilterAccordion] = useState(false);
  const [dateFiltertoggle, setDateFiltertoggle] = useState(true);
  const [measureOrdertoggle, setMeasureOrdertoggle] = useState(true);
  const isMediumScreen = useMediumScreen();

  const reorder = (numCase, index) => {
    let exercise = filteredExercises[index];
    switch (numCase) {
      case 1:
        setDateFiltertoggle(!dateFiltertoggle);
        exercise.sort((a, b) =>
          dateFiltertoggle
            ? moment(b.created_at).format('YYYYMMDD') - moment(a.created_at).format('YYYYMMDD')
            : moment(a.created_at).format('YYYYMMDD') - moment(b.created_at).format('YYYYMMDD'),
        );
        break;
      case 2:
        setMeasureOrdertoggle(!measureOrdertoggle);
        exercise.sort((a, b) => {
          let valueA = a.measure_value;
          if (valueA === null) {
            valueA = a.time_hours_value;
          }
          let valueB = b.measure_value;
          if (valueB === null) {
            valueB = b.time_hours_value;
          }
          return measureOrdertoggle ? valueB - valueA : valueA - valueB;
        });
        break;
      default:
        break;
    }
  };
  return (
    <Fragment>
      {isLoading ? (
        <View style={globalStyles.loadingContainer}>
          <Spinner />
        </View>
      ) : (
        filteredExercises.map((exerciseList, index) => {
          return (
            <View key={exerciseList[0].exercise.descripcion} style={{ alignItems: 'center', width: '95%' }}>
              <View style={isMediumScreen ? styles.webWrapper : styles.wrapper}>
                <List.Section>
                  <List.Accordion
                    onPress={() => setOpenAccordion(!openAccordion)}
                    theme={{ colors: { primary: '#3f3a40' } }}
                    title={<Text style={styles.generalExerciseTitle}>{exerciseList[0]?.exercise.descripcion}</Text>}
                  >
                    <View style={styles.listSection}>
                      <View style={styles.accordionFilterContainer}>
                        <List.Accordion
                          onPress={() => setOpenFilterAccordion(!openFilterAccordion)}
                          theme={{ colors: { primary: '#3f3a40' } }}
                          title={'ORDENAR POR'}
                          titleStyle={styles.accordionTitle}
                          style={styles.filterAccordion}
                        >
                          <Pressable
                            onPress={() => reorder(1, index)}
                            style={({ pressed }) => [
                              {
                                backgroundColor: pressed ? '#c8c4c4' : '#f8f4f4',
                              },
                            ]}
                          >
                            <Text style={[styles.timeFilterText, styles.filterText]}>Fecha</Text>
                          </Pressable>
                          <Pressable
                            onPress={() => reorder(2, index)}
                            style={({ pressed }) => [
                              {
                                backgroundColor: pressed ? '#c8c4c4' : '#f8f4f4',
                              },
                            ]}
                          >
                            <Text style={styles.filterText}>Intensidad</Text>
                          </Pressable>
                        </List.Accordion>
                      </View>
                      <MarkItem marks={exerciseList} />
                    </View>
                  </List.Accordion>
                </List.Section>
              </View>
            </View>
          );
        })
      )}
      {filteredExercises.length === 0 && !isLoading && (
        <Text style={[globalStyles.marginTop10, styles.filterText]}>No se encontraron resultados.</Text>
      )}
    </Fragment>
  );
};

export default MarksItems;
