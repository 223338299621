import * as React from 'react';
import { View, Text, VirtualizedList, TouchableOpacity } from 'react-native';
import { Card, List, Dialog, Portal, Provider as PaperProvider, Paragraph, DefaultTheme } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import CardExercise from 'components/CardExercise';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/components/routinePreviewModal';

const RoutinePreviewModal = (props) => {
  const { visible, routine, onDismiss } = props;
  const isMediumScreen = useMediumScreen();
  const [selectedTrainingDay, setSelectedTrainingDay] = React.useState(null);
  const [selectedTrainingStep, setSelectedTrainingStep] = React.useState(null);

  const getTrainingStepExercises = async (trainingStep) => {
    try {
      const { data } = await axiosInstance({
        method: 'get',
        url: `training-steps/${trainingStep.id}/exercises?isPreview=true`,
      });
      trainingStep.exercises = data.trainingStepExercises;
      setSelectedTrainingStep(trainingStep);
    } catch (error) {
      console.log(error);
    }
  };

  const TrainingStepExercisesList = () => (
    <>
      <Paragraph style={[globalStyles.normalText, styles.trainingStepObservations]}>
        {selectedTrainingStep.observations}
      </Paragraph>

      {selectedTrainingStep.exercises.length > 0 ? (
        <VirtualizedList
          data={selectedTrainingStep.exercises}
          renderItem={({ item }) => (
            <CardExercise
              exercise={item.exercise}
              comments={item.comments}
              observations={item.observations}
              showTags={true}
              isPreview={true}
            />
          )}
          keyExtractor={(item, index) => `item-${item.value}-${index}`}
          getItemCount={() => selectedTrainingStep.exercises.length}
          getItem={(data, index) => data[index]}
        />
      ) : (
        <Text style={styles.noExercisesTitle}>Este entrenamiento no tiene ejercicios</Text>
      )}
    </>
  );

  const TrainingDaysList = () => (
    <>
      {routine.trainingDays.length > 0 ? (
        <>
          <VirtualizedList
            data={routine.trainingDays}
            renderItem={({ item }) => (
              <Card
                key={`training-day-${item.id}`}
                style={[
                  isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile,
                  { width: '100%' },
                ]}
              >
                <List.Accordion
                  theme={{ colors: { primary: '#3f3a40' } }}
                  title={item.day}
                  titleStyle={globalStyles.normalText}
                  onPress={() => {
                    // Esta lógica sirve para que al volver a seleccionar el accordion ya abierto
                    // el mismo se cierre
                    if (item.id === selectedTrainingDay?.id) {
                      setSelectedTrainingDay(null);
                    } else {
                      setSelectedTrainingDay(item);
                    }
                  }}
                  expanded={item.id === selectedTrainingDay?.id}
                >
                  {item.training_steps?.length > 0 ? (
                    item.training_steps.map((trainingStep) => (
                      <View key={trainingStep.id} style={styles.trainingStepContainer}>
                        <List.Item
                          key={`training-step-${trainingStep.id}`}
                          right={(props) => <List.Icon {...props} color="#3f3a40" icon="chevron-right" />}
                          style={styles.trainingStep}
                          titleStyle={globalStyles.normalText}
                          title={trainingStep.descripcion}
                          onPress={() => getTrainingStepExercises(trainingStep)}
                        />
                      </View>
                    ))
                  ) : (
                    <List.Item
                      style={styles.trainingStep}
                      title="No se encontraron resultados disponibles"
                      titleStyle={globalStyles.normalText}
                    />
                  )}
                </List.Accordion>
              </Card>
            )}
            keyExtractor={(item, index) => `item-${item.value}-${index}`}
            getItemCount={() => routine.trainingDays.length}
            getItem={(data, index) => data[index]}
          />
        </>
      ) : (
        <>
          <Text style={styles.noExercisesTitle}>Esta rutina no tiene días de entrenamiento</Text>
        </>
      )}
    </>
  );

  return (
    <PaperProvider theme={DefaultTheme}>
      <Portal theme={DefaultTheme}>
        <Dialog
          style={isMediumScreen ? styles.containerWeb : styles.containerMobile}
          visible={visible}
          onDismiss={onDismiss}
        >
          <View style={styles.header}>
            {selectedTrainingStep ? (
              <TouchableOpacity onPress={() => setSelectedTrainingStep(null)}>
                <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
              </TouchableOpacity>
            ) : null}
            <Text style={globalStyles.title}>Visualización del alumno</Text>
          </View>
          <Dialog.ScrollArea style={styles.scrollContainer}>
            {selectedTrainingStep ? <TrainingStepExercisesList /> : <TrainingDaysList />}
          </Dialog.ScrollArea>
        </Dialog>
      </Portal>
    </PaperProvider>
  );
};

export default RoutinePreviewModal;
