import axiosInstance from './axios';

export const getFolders = async () => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'user/folders',
    });
    return data.folders;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const storeFolder = async (folder) => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: 'user/folders',
      data: folder,
    });
    return data.folder;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateFolder = async (folder) => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `user/folders/${folder.id}`,
      data: folder,
    });
    return data.folder;
  } catch (error) {
    console.log(error);
    return null;
  }
};
