import React, { useCallback, useEffect, useState } from 'react';
import { Platform, SafeAreaView, View, Text } from 'react-native';
import { WebView } from 'react-native-webview';
import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from 'services/axios';
import Spinner from 'components/Spinner';
import CustomButton from 'components/CustomButton';
import { styles } from 'styles/screens/private/payment';
import { globalStyles } from 'src/styles/global';
import { STRIPE_PUBLIC_KEY } from '@env';

const Payment = (props) => {
  const { navigation, route } = props;
  const { priceId } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [hasError, setHasError] = useState(false);

  const isWeb = Platform.OS === 'web';

  const getSessionIdCallback = useCallback(async () => {
    try {
      setSessionId(null);
      setHasError(false);
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'post',
        url: '/payments/create-checkout-session',
        data: { priceId },
      });
      if (isWeb) {
        const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
        await stripe.redirectToCheckout({ sessionId: data.sessionId });
      } else {
        setSessionId(data.sessionId);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [isWeb, priceId]);

  useEffect(() => {
    getSessionIdCallback();
  }, [getSessionIdCallback, route]);

  const iframe = `
  <html>
    <body>
      <!-- Load Stripe.js on your website. -->
      <script src="https://js.stripe.com/v3"></script>
      <div id="error-message"></div>
      <script>
        (function () {
          var stripe = Stripe('${STRIPE_PUBLIC_KEY}');
          window.onload = function () {
            stripe.redirectToCheckout({ sessionId: '${sessionId}' }).then(function (result) {
              console.log(result);
              if (result.error) {
                var displayError = document.getElementById('error-message');
                displayError.textContent = result.error.message;
              }
            });
          };
        })();
      </script>
    </body>
  </html>
`;

  const onLoadStart = (syntheticEvent) => {
    const { nativeEvent } = syntheticEvent;
    const { url } = nativeEvent;
    if (url.includes('CompraFinalizada')) {
      const queryString = url.split('?')[1];
      const vars = queryString.split('&');
      const checkoutSessionId = vars[0].split('checkoutSessionId=')[1];
      const success = vars[1].split('success=')[1];
      if (isWeb) {
        navigation.navigate('Main', { screen: 'CompraFinalizada', params: { checkoutSessionId, success } });
      } else {
        navigation.navigate('FinishedPaymentScreen', { checkoutSessionId, success });
      }
    }
  };

  const renderLoading = () => <Spinner message="Esto puede demorar un poco..." />;

  return (
    <SafeAreaView style={[styles.container, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      {(isLoading || (isWeb && !hasError)) && renderLoading()}
      {!isLoading && !hasError && !!sessionId && (
        <WebView
          onLoadStart={onLoadStart}
          originWhitelist={['*']}
          source={{ html: iframe, baseUrl: 'https://mettheapp.com/' }}
          scalesPageToFit={false}
          renderLoading={renderLoading}
          startInLoadingState
          style={styles.webView}
        />
      )}
      {!isLoading && hasError && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>Ocurrió un error inesperado, por favor vuelva a intentarlo más tarde</Text>
          <CustomButton
            title="Volver"
            buttonColor="gray"
            marginTop={30}
            alignSelf="center"
            onPress={() => {
              if (isWeb) {
                navigation.navigate('Main', { screen: 'Suscripciones' });
              } else {
                navigation.navigate('MySubscriptionsScreen');
              }
            }}
          />
        </View>
      )}
    </SafeAreaView>
  );
};

export default Payment;
