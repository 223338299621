import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 20,
  },
  content: {
    flexDirection: 'row',
  },
  label: {
    fontFamily: 'gilroy-regular',
    fontSize: 14,
  },
  separator: {
    fontFamily: 'gilroy-regular',
    fontSize: 14,
    paddingHorizontal: 5,
  },
});
