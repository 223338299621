import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  registerText: {
    fontFamily: 'gilroy-regular',
    fontSize: 20,
    textAlign: 'left',
  },
  registerButton: {
    width: '90%',
    marginBottom: 60,
  },
  errorContainer: {
    width: '90%',
  },
  textInput: {
    fontFamily: 'gilroy-semibold',
    fontSize: 18,
    textAlign: 'left',
    borderColor: 'grey',
    backgroundColor: 'white',
    borderWidth: 1,
    borderRadius: 20,
    height: 45,
    width: '90%',
    margin: 5,
    paddingHorizontal: 16,
  },
  socialRegisterSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    marginVertical: 25,
  },
  socialTitle: {
    lineHeight: 17,
  },
  loginContainerSpinner: {
    height: 200,
  },
  socialLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 4,
  },
  googleImageStyle: {
    height: 30,
    width: 29,
  },
  appleButton: {
    width: '100%',
    height: 44,
  },
});
