import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Linking, Platform, SafeAreaView, View, Text } from 'react-native';
import { WebView } from 'react-native-webview';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import Spinner from 'components/Spinner';
import CustomButton from 'components/CustomButton';
import { styles } from 'styles/screens/private/payment';
import { globalStyles } from 'src/styles/global';

const CustomerPortal = (props) => {
  const { navigation, route } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [uri, setUri] = useState(null);
  const [hasError, setHasError] = useState(false);
  const { userData } = useContext(appContext);
  const isWeb = Platform.OS === 'web';
  const planId = userData?.user?.plan?.id;

  const getUrlCallback = useCallback(async () => {
    try {
      setHasError(false);
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'post',
        url: '/payments/create-customer-portal-session',
        data: { planId },
      });
      if (isWeb) {
        Linking.openURL(data.url);
      } else {
        setUri(data.url);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [isWeb, planId]);

  useEffect(() => {
    getUrlCallback();
  }, [getUrlCallback, route]);

  const onLoadStart = (syntheticEvent) => {
    const { nativeEvent } = syntheticEvent;
    const { url } = nativeEvent;
    if (url.includes('Perfil')) {
      if (isWeb) {
        navigation.navigate('Main', { screen: 'Perfil' });
      } else {
        navigation.navigate('ProfileScreen');
      }
    }
  };

  const renderLoading = () => <Spinner message="Esto puede demorar un poco..." />;

  return (
    <SafeAreaView style={[styles.container, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      {(isLoading || (isWeb && !hasError)) && renderLoading()}
      {!isLoading && !hasError && !!uri && (
        <WebView
          onLoadStart={onLoadStart}
          originWhitelist={['*']}
          source={{ uri }}
          scalesPageToFit={false}
          renderLoading={renderLoading}
          startInLoadingState
          style={styles.webView}
        />
      )}
      {!isLoading && hasError && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>Ocurrió un error inesperado, por favor vuelva a intentarlo más tarde</Text>
          <CustomButton
            title="Volver"
            buttonColor="gray"
            marginTop={30}
            alignSelf="center"
            onPress={() => {
              if (isWeb) {
                navigation.navigate('Main', { screen: 'Perfil' });
              } else {
                navigation.navigate('ProfileScreen');
              }
            }}
          />
        </View>
      )}
    </SafeAreaView>
  );
};

export default CustomerPortal;
