import React, { useState, useContext, useRef, useCallback } from 'react';
import { Platform, Text, TextInput, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Formik } from 'formik';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as AppleAuthentication from 'expo-apple-authentication';
import axiosInstance from 'services/axios';
import { USER_DATA_KEY } from 'constants/storageKey';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import CustomButton from 'components/CustomButton';
import SocialContainer from 'components/SocialContainer';
import CustomErrors from 'components/CustomErrors';
import Spinner from 'components/Spinner';
import appContext from 'contexts/appContext';
import formLoginSchema from './schemas/formLoginSchema';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/forms/formLogin';
import { SSO_LOGIN } from 'constants/enums/ssoLogin';
import { handleIOSName } from 'helpers/ios';
import { isStudent } from 'src/helpers/roles';

export default ({ navigation, isSubmitting, setIsSubmitting }) => {
  const [formError, setFormError] = useState('');
  const { setUserData, expoPushToken } = useContext(appContext);
  const initialValues = { email: '', password: '', device_id: '' };
  const formikForm = useRef(null);
  const startSubmitting = () => setIsSubmitting(true);
  const stopSubmitting = () => setIsSubmitting(false);

  const stopSubmittingCallback = useCallback(stopSubmitting, [setIsSubmitting]);

  const onSubmit = async (values) => {
    try {
      startSubmitting();
      const { data } = await axiosInstance({
        method: 'post',
        url: 'token',
        data: {
          ...values,
          device_id: Constants.installationId || Constants.getWebViewUserAgentAsync(),
          expoPushToken,
        },
      });
      await AsyncStorage.setItem(USER_DATA_KEY, data.token);
      setUserData(data);
      if (isStudent(data)) {
        navigation.navigate('Main', { screen: 'Programaciones' });
      } else {
        navigation.navigate('Main', { screen: 'Dashboard' });
      }
    } catch (error) {
      console.log('error', JSON.stringify(error));
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setFormError(error.response.data.errors[0]);
      } else {
        setFormError(GENERIC_ERROR_MESSAGE);
      }
    } finally {
      stopSubmitting();
    }
  };

  const onSubmitForm = (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    onSubmit(data);
  };

  useFocusEffect(
    useCallback(() => {
      return () => {
        formikForm.current?.resetForm();
        setFormError('');
        stopSubmittingCallback();
      };
    }, [stopSubmittingCallback]),
  );

  return (
    <>
      <Text style={globalStyles.title}>Registro</Text>
      <View style={styles.registerButton}>
        <CustomButton
          title="Comienza gratis"
          buttonColor="#bbebeb"
          textColor="#3f3a40"
          width="100%"
          height={50}
          onPress={() => navigation.navigate('Main', { screen: 'Registrarse' })}
        />
      </View>
      <Text style={globalStyles.title}>Inicia sesión</Text>
      <Formik
        innerRef={formikForm}
        validationSchema={formLoginSchema}
        initialValues={initialValues}
        onSubmit={onSubmitForm}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
          <>
            {!!formError && (
              <View style={globalStyles.errorContainer}>
                <CustomErrors msg={formError} />
              </View>
            )}
            {!isSubmitting ? (
              <>
                <TextInput
                  style={[globalStyles.textInput, outlineStyle]}
                  name="email"
                  placeholder="Email"
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  keyboardType="email-address"
                />
                {errors.email && touched.email && <Text style={globalStyles.inputErrorText}>{errors.email}</Text>}
                <TextInput
                  style={[globalStyles.textInput, outlineStyle]}
                  name="password"
                  placeholder="Contraseña"
                  onChangeText={handleChange('password')}
                  onBlur={handleBlur('password')}
                  value={values.password}
                  secureTextEntry
                />
                {errors.password && touched.password && (
                  <Text style={globalStyles.inputErrorText}>{errors.password}</Text>
                )}
              </>
            ) : (
              <View style={styles.loginContainerSpinner}>
                <Spinner />
              </View>
            )}
            <View style={styles.socialRegisterSection}>
              <SocialContainer isSubmitting={isSubmitting} onSubmit={onSubmit} />
              <CustomButton
                title="Iniciar sesión"
                buttonColor="#bbebeb"
                textColor="#3f3a40"
                width={120}
                marginTop={12}
                onPress={handleSubmit}
                disabled={!isValid || isSubmitting}
              />
            </View>
            {Platform.OS === 'ios' && (
              <View style={styles.socialRegisterSection}>
                <AppleAuthentication.AppleAuthenticationButton
                  buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                  buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                  cornerRadius={5}
                  style={styles.appleButton}
                  onPress={async () => {
                    try {
                      const credentials = await AppleAuthentication.signInAsync({
                        requestedScopes: [
                          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                          AppleAuthentication.AppleAuthenticationScope.EMAIL,
                        ],
                      });
                      onSubmit({
                        email: credentials.email,
                        name: handleIOSName(credentials.fullName),
                        platform_id: credentials.user,
                        platform_type: SSO_LOGIN.apple,
                        ios_token: credentials.identityToken,
                      });
                    } catch (e) {
                      if (e.code === 'ERR_CANCELED') {
                        // handle that the user canceled the sign-in flow
                      } else {
                        // handle other errors
                      }
                    }
                  }}
                />
              </View>
            )}
          </>
        )}
      </Formik>
    </>
  );
};
