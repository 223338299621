import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  textInputStyle: {
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    lineHeight: 20,
    borderBottomWidth: 1,
    borderColor: '#8a8a8a',
    marginVertical: 10,
    paddingVertical: 0,
    paddingBottom: 10,
  },
});
