import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  spinner: {
    height: 200,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'gilroy-regular',
  },
});
