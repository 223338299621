import { StyleSheet } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 64,
    paddingHorizontal: 20,
    backgroundColor: 'white',
    borderBottomColor: '#d8d8d8',
    borderBottomWidth: 1,
    marginTop: getStatusBarHeight(),
  },
  sideContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '40%',
  },
  logoContainer: {
    width: '10%',
    alignItems: 'center',
  },
  logo: {
    width: 15,
    height: 15,
  },
  rightContainer: {
    justifyContent: 'flex-end',
  },
  notificationIcon: { marginRight: 10 },
  badgeNotificationContainer: { position: 'absolute', top: 3, right: 1 },
  badge: { backgroundColor: '#c33e1d' },
});
