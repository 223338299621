import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '80%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  containerMobile: {
    width: '80%',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 10,
  },
  rowContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnContainer: {
    alignItems: 'center',
  },
  columnContent: {
    width: 300,
  },
  titleTextStyle: {
    fontFamily: 'gilroy-regular',
    fontSize: 20,
    marginTop: 10,
  },
  cardContent: {
    alignItems: 'center',
  },
  descriptionContainer: {
    height: 40,
    marginTop: 10,
    justifyContent: 'center',
  },
  descriptionTextStyle: {
    fontFamily: 'gilroy-regular',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  subTitleTextStyle: {
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    marginTop: 10,
  },
  cardButton: {
    width: '100%',
    marginTop: 10,
  },
  littleTitleTextStyle: {
    fontFamily: 'gilroy-bold',
    fontSize: 14,
  },
  littleDescriptionTextStyle: {
    fontFamily: 'gilroy-regular',
    fontSize: 13,
    marginVertical: 10,
  },
  groupedPlansContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
});
