import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Linking, Platform, ScrollView, Text, View, SafeAreaView } from 'react-native';
import axiosInstance from 'services/axios';
import appContext from 'contexts/appContext';
import CustomButton from 'components/CustomButton';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/paymentFinished';

const PaymentFinished = (props) => {
  const { navigation, route } = props;
  const { userData, setUserData } = useContext(appContext);
  const [url, setUrl] = useState(null);
  const checkoutSessionId = route.params?.checkoutSessionId;
  const success = parseInt(route.params?.success, 10);
  const isWeb = Platform.OS === 'web';

  const finishPaymentCallback = useCallback(async () => {
    try {
      const { data } = await axiosInstance({
        method: 'post',
        url: '/payments/finish',
        data: { checkoutSessionId, success },
      });
      data.url && setUrl(data.url);
      if (data.plan && data.subscription) {
        const { token, user } = userData;
        setUserData({ token, user: { ...user, plan: data.plan, subscription: data.subscription } });
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSessionId, success]);

  useEffect(() => {
    setUrl(null);
    !!userData?.user?.id && !!checkoutSessionId && finishPaymentCallback();
  }, [finishPaymentCallback, checkoutSessionId, route, userData?.user?.id]);

  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={[globalStyles.scrollViewContainer, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}
      >
        <View style={styles.container}>
          <Text style={styles.text}>La operación fue {success ? 'realizada con éxito' : 'cancelada'}</Text>
          {url && (
            <CustomButton
              title="Ver comprobante"
              buttonColor="#bbebeb"
              textColor="#3f3a40"
              marginTop={30}
              alignSelf="center"
              onPress={() => {
                if (isWeb) {
                  window.open(url, '_blank');
                } else {
                  Linking.openURL(url);
                }
              }}
            />
          )}
          <CustomButton
            title="Volver"
            buttonColor="gray"
            marginTop={30}
            alignSelf="center"
            onPress={() => {
              if (isWeb) {
                navigation.navigate('Main', { screen: 'Dashboard' });
              } else {
                navigation.navigate('MySubscriptionsScreen');
              }
            }}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default PaymentFinished;
