import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  message: {
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    textAlign: 'center',
  },
});
