import * as React from 'react';
import { Button, Dialog, Portal, Provider as PaperProvider, DefaultTheme, List } from 'react-native-paper';
import moment from 'moment/moment';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/components/historialModal';
import { ScrollView, Text, View } from 'react-native';
import MarkItem from './MarkItem';
const HistorialModal = (props) => {
  const { resultados, visible, title, onDismiss, dismissable = true } = props;
  return (
    <PaperProvider theme={DefaultTheme}>
      <Portal theme={DefaultTheme}>
        <Dialog style={[styles.container]} visible={visible} onDismiss={onDismiss} dismissable={dismissable}>
          <Dialog.Title style={[globalStyles.normalText, { marginLeft: 20 }]}>{title}</Dialog.Title>
          <Dialog.Content style={{ paddingHorizontal: 12 }}>
            <ScrollView style={styles.marksContainer}>
              {resultados.length === 0 ? (
                <Text style={[styles.fontFamily, { paddingLeft: 8 }]}>No hay resultados guardados.</Text>
              ) : (
                <MarkItem
                  marks={resultados}
                  titleStyles={{ fontFamily: 'gilroy-semibold', fontSize: 16 }}
                  dateStyles={{ fontFamily: 'gilroy-regular', fontSize: 12 }}
                />
              )}
            </ScrollView>
            <Dialog.Actions>
              <Button
                mode="text"
                color={globalStyles.buttonColor.color}
                labelStyle={[globalStyles.normalText]}
                onPress={onDismiss}
              >
                Aceptar
              </Button>
            </Dialog.Actions>
          </Dialog.Content>
        </Dialog>
      </Portal>
    </PaperProvider>
  );
};

export default HistorialModal;
