import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { Card } from 'react-native-elements';
import { Row, Col } from 'react-native-responsive-grid-system';
import appContext from 'contexts/appContext';
import useMediumScreen from 'hooks/useMediumScreen';
import Spinner from 'components/Spinner';
import CustomImgButton from 'components/CustomImgButton';
import CustomButton from 'components/CustomButton';
import Image from 'assets/cardImage.jpg';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/components/pricingContainer';
import { BASE_URL } from '@env';

const PricingContainer = (props) => {
  const { filterByRole = false, isLoading, navigation, onCancelPress, onSubscribePress, plans } = props;
  const { userData } = useContext(appContext);
  const isMediumScreen = useMediumScreen();

  const showPlan = (plan) => plan.role_id === userData?.user?.perfil_id || !filterByRole;

  const isMyPlan = (plan) => userData?.user?.plan?.stripe_product_id === plan.stripe_product_id;

  const getButtonText = (plan) => {
    if (!filterByRole) {
      return 'Ver';
    } else if (isMyPlan(plan)) {
      return 'Cancelar';
    } else {
      return 'Suscribirse';
    }
  };

  const onButtonPress = (plan) => {
    if (!userData) {
      navigation.navigate('Main', { screen: 'Login' });
    } else if (!filterByRole) {
      navigation.navigate('MySubscriptionsScreen');
    } else if (isMyPlan(plan)) {
      onCancelPress(plan);
    } else {
      onSubscribePress(plan);
    }
  };

  if (isLoading) {
    return (
      <View style={globalStyles.loadingContainer}>
        <Spinner />
      </View>
    );
  }

  const groupArray = (arr, size) => {
    let newArray = [];
    for (let i = 0; i < arr.length; i += size) {
      newArray.push(arr.slice(i, i + size));
    }
    return newArray;
  };
  const groupedPlans = groupArray(plans, 3);

  const PlanItem = ({ plan }) => (
    <View key={'plan-' + plan.id} style={[!isMediumScreen && globalStyles.marginTop20]}>
      <CustomImgButton
        backgroundImage={plan.image ? { uri: BASE_URL + plan.image } : Image}
        isBigButton
        onPress={() => onButtonPress(plan)}
        renderText={() => (
          <>
            <Text style={styles.littleTitleTextStyle}>{plan.name}</Text>
            <Text style={styles.littleDescriptionTextStyle}>{plan.description}</Text>
            {plan.trial_days > 0 ? (
              <Text style={styles.littleTitleTextStyle}>Prueba de {plan.trial_days} días</Text>
            ) : (
              <Text style={styles.littleTitleTextStyle}>No tiene días de prueba</Text>
            )}
            <CustomButton
              title={getButtonText(plan)}
              buttonColor="#bbebeb"
              textColor="#3f3a40"
              marginTop={10}
              alignSelf="center"
              onPress={() => onButtonPress(plan)}
              disabled={isLoading}
            />
          </>
        )}
      />
    </View>
  );

  return filterByRole ? (
    <>
      {isMediumScreen ? (
        <>
          {groupedPlans.map((group) => (
            <View style={styles.groupedPlansContainer}>
              {group.map((plan) => showPlan(plan) && <PlanItem plan={plan} />)}
            </View>
          ))}
        </>
      ) : (
        <View style={styles.containerMobile}>{plans.map((plan) => showPlan(plan) && <PlanItem plan={plan} />)}</View>
      )}
    </>
  ) : (
    <Row rowStyles={styles.rowContainer}>
      {plans.map(
        (plan) =>
          showPlan(plan) && (
            <Col key={'plan-' + plan.id} xs={12} sm={12} md={6} lg={4} colStyles={styles.columnContainer}>
              <View style={styles.columnContent}>
                <Card>
                  <Card.Image source={plan.image ? { uri: BASE_URL + plan.image } : Image} />
                  <View style={styles.cardContent}>
                    <Text style={styles.titleTextStyle}>{plan.name}</Text>
                    <View style={styles.descriptionContainer}>
                      <Text style={styles.descriptionTextStyle} numberOfLines={2}>
                        {plan.description}
                      </Text>
                    </View>
                    {plan.trial_days > 0 ? (
                      <Text style={styles.subTitleTextStyle}>Prueba de {plan.trial_days} días</Text>
                    ) : (
                      <Text style={styles.subTitleTextStyle}>No tiene días de prueba</Text>
                    )}
                    <View style={styles.cardButton}>
                      <CustomButton
                        title={getButtonText(plan)}
                        buttonColor="#bbebeb"
                        textColor="#3f3a40"
                        width="100%"
                        marginTop={10}
                        alignSelf="center"
                        onPress={() => onButtonPress(plan)}
                        disabled={isLoading}
                      />
                    </View>
                  </View>
                </Card>
              </View>
            </Col>
          ),
      )}
    </Row>
  );
};

export default PricingContainer;
