import React from 'react';
import { View, Text } from 'react-native';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import { styles } from 'styles/screens/private/students';

const FormTrainer = (props) => {
  const { name, email, setName, setEmail, createTrainer, disabled } = props;

  return (
    <>
      <View style={styles.inputContainer}>
        <View>
          <CustomInput title="Nombre del entrenador" value={name} onChangeText={setName} />
        </View>
        <View>
          <CustomInput title="Email del entrenador" value={email} onChangeText={setEmail} />
        </View>
      </View>
      <View>
        <CustomButton
          title="Agregar a la lista"
          buttonColor="#bbebeb"
          textColor="#3f3a40"
          marginTop={30}
          alignSelf="center"
          onPress={createTrainer}
          disabled={disabled}
        />
        {disabled && (
          <View style={styles.disabledInvitationAlertContainer}>
            <Text style={styles.disabledInvitationAlertText}>
              No puedes invitar a nuevos entrenadores porque ya alcanzaste el límite de invitaciones
            </Text>
          </View>
        )}
      </View>
    </>
  );
};

export default FormTrainer;
