import React, { useContext, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  ScrollView,
  Text,
  View,
  TextInput,
  Image,
  Platform,
  Keyboard,
  KeyboardAvoidingView,
  Dimensions,
} from 'react-native';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import { styles } from 'src/styles/screens/private/chat';
import { TouchableOpacity } from 'react-native-gesture-handler';
import moment from 'moment/moment';
import { globalStyles } from 'src/styles/global';
import DefaultUserImage from 'assets/default-user-image.png';
import { BASE_URL } from '@env';

const Chat = (props) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const { userData, contactChat } = useContext(appContext);
  const user = userData?.user;
  const scrollViewRef = React.useRef(null);
  const navigation = useNavigation();
  const { height } = Dimensions.get('screen');
  const iphoneXAndAboveHeight = height >= 812;

  useFocusEffect(
    React.useCallback(() => {
      const getMessages = async (contactId) => {
        if (!contactId) {
          return;
        }
        try {
          const { data } = await axiosInstance({
            method: 'get',
            url: `chat/get-messages-by-contact/${contactId}`,
          });
          setMessages(data.messages);
        } catch (error) {
          console.log(error);
        }
      };

      const interval = setInterval(() => {
        if (!contactChat) {
          return;
        }
        getMessages(contactChat.id);
      }, 1500);

      return () => {
        setMessages([]);
        clearInterval(interval);
      };
    }, [contactChat]),
  );

  const sendMessage = async (body) => {
    try {
      await axiosInstance({
        method: 'post',
        url: 'chat/send-message',
        data: body,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSendMessage = async () => {
    if (message === '' || !userData?.user.id || !contactChat?.id) {
      return;
    }

    const body = {
      fromUser: userData.user.id,
      toUser: contactChat.id,
      message,
    };
    await sendMessage(body);
    setMessage('');
    if (Platform.OS !== 'web') {
      Keyboard.dismiss();
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.chatItemContainer}>
        <View style={styles.backButtonContainer}>
          <Icon
            name="arrow-back"
            color={Platform.OS === 'web' ? 'white' : 'black'}
            size={25}
            onPress={() => {
              navigation.navigate('ChatListScreen');
            }}
            style={globalStyles.headerTitleIcon}
          />
        </View>
        <View style={styles.profileImageContainer}>
          <Image
            source={contactChat?.image ? { uri: BASE_URL + contactChat?.image } : DefaultUserImage}
            style={styles.contactProfileImage}
          />
        </View>
        <View style={styles.contactNameContainer}>
          <Text style={styles.contactNameText}>{contactChat?.name}</Text>
        </View>
      </View>
      <ScrollView
        style={styles.scrollViewToMessages}
        ref={(ref) => {
          scrollViewRef.current = ref;
        }}
        onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
      >
        {messages.length > 0 && (
          <View>
            {messages.map((messageItem, index) => (
              <View key={index} style={styles.messageItemContainer}>
                <View
                  style={[
                    styles.messageBodyContainer,
                    messageItem.from_user_id === user?.id ? styles.alignOwnMessage : styles.alignContactMessage,
                  ]}
                >
                  <Text style={styles.messageText}>{messageItem.message}</Text>
                  <Text style={styles.messageDatetime}>
                    {moment(messageItem.created_at).format('DD-MM-YYYY HH:mm')}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        )}
      </ScrollView>

      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={iphoneXAndAboveHeight ? 100 : 85}
      >
        <View style={styles.textMessageContainer}>
          <View style={styles.messageInputContainer}>
            <TextInput
              placeholder="Escribe un mensaje"
              style={styles.messageInput}
              onChangeText={setMessage}
              value={message}
              onSubmitEditing={onSendMessage}
            />
          </View>
          <View style={styles.sendMessageContainer}>
            <TouchableOpacity style={styles.newMessageButton} onPress={onSendMessage}>
              <Icon name="send" size={25} color={message === '' ? '#424242' : '#c33e1d'} />
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};

export default Chat;
