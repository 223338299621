import React, { useState, useEffect, useCallback } from 'react';
import { ScrollView, View, Text, TouchableOpacity, KeyboardAvoidingView, TextInput } from 'react-native';
import { DataTable } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import useMediumScreen from 'hooks/useMediumScreen';
import axiosInstance from 'services/axios';
import CustomTable from 'components/CustomTable';
import Spinner from 'components/Spinner';
import UserTrainingStepsModal from 'components/UserTrainingStepsModal';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/screens/private/students';
import CustomButton from 'src/components/CustomButton';

const StudentProfile = (props) => {
  const { navigation, route } = props;
  const { userId, userName } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [isUserTrainingStepsModalVisible, setIsUserTrainingStepsModalVisible] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState(null);
  const [routines, setRoutines] = useState([]);
  const [studentRoutines, setStudentRoutines] = useState([]);
  const [searchRoutine, setSearchRoutine] = useState('');

  const isMediumScreen = useMediumScreen();

  const filteredRoutines = routines.filter((routine) =>
    routine.descripcion.toLowerCase().includes(searchRoutine.toLowerCase()),
  );

  useEffect(() => {
    getRoutines();
  }, [getRoutines, userId]);

  const getRoutines = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: 'routines/user/' + userId,
      });
      setRoutines(data.routines);
      setStudentRoutines(data.studentRoutines);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  const updateRoutines = async (routineIds) => {
    try {
      await axiosInstance({
        method: 'put',
        url: 'routines/user/' + userId,
        data: {
          routines: routineIds,
        },
      });
      await getRoutines();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteRoutine = (routine) => {
    setIsLoading(true);
    const routineIds = studentRoutines
      .map((routineObj) => routineObj.id)
      .filter((routineId) => routineId !== routine.id);
    updateRoutines(routineIds);
  };

  const addRoutine = (routine) => {
    setIsLoading(true);
    const arrayOfRoutines = [...studentRoutines, routine];
    const routineIds = arrayOfRoutines.map((routineObj) => routineObj.id);
    updateRoutines(routineIds);
  };

  const renderAddRoutineRow = (routine) => (
    <DataTable.Row key={`add-routine-${routine.id}`} style={globalStyles.tableRow}>
      <DataTable.Cell style={globalStyles.tableCell}>
        <Text style={globalStyles.normalText}>{routine.descripcion}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <TouchableOpacity onPress={() => addRoutine(routine)}>
          <Icon name="add-circle" size={22} color="#f2a172" />
        </TouchableOpacity>
      </DataTable.Cell>
    </DataTable.Row>
  );

  const renderDeleteRoutineRow = (routine) => (
    <DataTable.Row key={`delete-routine-${routine.id}`} style={globalStyles.tableRow}>
      <DataTable.Cell style={globalStyles.tableCell}>
        <Text style={globalStyles.normalText}>{routine.descripcion}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <TouchableOpacity onPress={() => deleteRoutine(routine)}>
          <Icon name="delete" size={22} color="#f2a172" />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setSelectedRoutine(routine);
            setIsUserTrainingStepsModalVisible(true);
          }}
        >
          <Icon name="visibility" size={22} color={'#f2a172'} style={globalStyles.tableIcon} />
        </TouchableOpacity>
      </DataTable.Cell>
    </DataTable.Row>
  );

  return (
    <>
      <KeyboardAvoidingView behavior={'height'} style={{ flex: 1 }}>
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
          <View style={globalStyles.viewContainer}>
            <View style={globalStyles.headerContainer}>
              <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
                <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
              </TouchableOpacity>
              <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
                Programaciones de {userName}
              </Text>
              <TouchableOpacity disabled={isLoading} onPress={getRoutines}>
                <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
              </TouchableOpacity>
            </View>
            {isLoading ? (
              <View style={styles.studentContainerSpinner}>
                <Spinner />
              </View>
            ) : (
              <>
                <View style={globalStyles.tableContainer}>
                  <Text style={globalStyles.tableTitle}>Programaciones relacionadas</Text>
                  <CustomTable
                    titles={['Descripción', 'Acciones']}
                    rows={studentRoutines}
                    renderRow={renderDeleteRoutineRow}
                    isLoading={isLoading}
                  />
                </View>
                <View>
                  <CustomButton
                    title={'Ver Historial de Ejercicios'}
                    buttonColor="#bbebeb"
                    textColor="#3f3a40"
                    width={180}
                    alignSelf="center"
                    onPress={() => navigation.navigate('AllStudentsMarks', { studentId: userId })}
                  />
                </View>
                <View style={globalStyles.tableContainer}>
                  <Text style={globalStyles.tableTitle}>Programaciones disponibles</Text>
                  <View
                    style={[
                      styles.searchInputContainer,
                      isMediumScreen ? styles.searchWebContainer : styles.searchMobileContainer,
                    ]}
                  >
                    <TextInput
                      onChangeText={setSearchRoutine}
                      placeholder="Buscar rutina..."
                      style={[styles.searchInput, outlineStyle]}
                      value={searchRoutine}
                    />
                  </View>
                  <CustomTable
                    titles={['Descripción', 'Acciones']}
                    rows={filteredRoutines}
                    renderRow={renderAddRoutineRow}
                    isLoading={isLoading}
                  />
                </View>
              </>
            )}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      {selectedRoutine && isUserTrainingStepsModalVisible ? (
        <UserTrainingStepsModal
          visible={isUserTrainingStepsModalVisible}
          onDismiss={() => setIsUserTrainingStepsModalVisible(false)}
          routine={selectedRoutine}
          studentId={userId}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default StudentProfile;
