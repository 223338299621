import * as React from 'react';
import { Button, Paragraph, Dialog, Portal, Provider as PaperProvider, DefaultTheme } from 'react-native-paper';

import { globalStyles } from 'styles/global';
import { styles } from 'styles/components/confirmationModal';

const ConfirmationModal = (props) => {
  const { visible, title, description, onDismiss, onConfirm, dismissable = true } = props;

  return (
    <PaperProvider theme={DefaultTheme}>
      <Portal theme={DefaultTheme}>
        <Dialog style={styles.container} visible={visible} onDismiss={onDismiss} dismissable={dismissable}>
          <Dialog.Title style={globalStyles.normalText}>{title}</Dialog.Title>
          <Dialog.Content>
            <Paragraph style={globalStyles.normalText}>{description}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            {onDismiss ? (
              <Button
                mode="text"
                color={globalStyles.buttonColor.color}
                onPress={onDismiss}
                labelStyle={[globalStyles.normalText]}
              >
                Cancelar
              </Button>
            ) : null}
            <Button
              mode="text"
              color={globalStyles.buttonColor.color}
              onPress={onConfirm}
              labelStyle={[globalStyles.normalText]}
            >
              Aceptar
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </PaperProvider>
  );
};

export default ConfirmationModal;
