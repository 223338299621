import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, View, FlatList, TextInput } from 'react-native';
import { Icon } from 'react-native-elements';
import Collapsible from 'react-native-collapsible';
import { getArticulations } from 'services/articulationService';
import { getElements } from 'services/elementService';
import { getMethods } from 'services/methodService';
import { getObjetives } from 'services/objetiveService';
import { getPoblations } from 'services/poblationService';
import { getTags } from 'services/tagServices';
import useMediumScreen from 'hooks/useMediumScreen';
import CustomDropDown from 'components/CustomDropDown';
import CustomButton from 'components/CustomButton';
import { globalStyles, outlineStyle } from 'styles/global';

export default ({ exercises, setExercises, setIsLoading, filtersSelected, setFiltersSelected, search, setSearch }) => {
  const [isCollapsed, setIsCollapsed] = useState(!isMediumScreen);
  const [articulations, setArticulations] = useState([]);
  const [elements, setElements] = useState([]);
  const [methods, setMethods] = useState([]);
  const [objetives, setObjetives] = useState([]);
  const [poblations, setPoblations] = useState([]);
  const [tags, setTags] = useState([]);
  const isMediumScreen = useMediumScreen();
  const [currentSearchValue, setCurrentSearchValue] = useState('');

  const showTextSearch = useMemo(() => typeof search !== 'undefined' && typeof setSearch !== 'undefined', []);

  const initialLoad = async () => {
    setArticulations(await getArticulations());
    setElements(await getElements());
    setMethods(await getMethods());
    setObjetives(await getObjetives());
    setPoblations(await getPoblations());
    setTags(await getTags());
  };

  const initialLoadCallback = useCallback(initialLoad, []);

  useEffect(() => {
    initialLoadCallback();
  }, [initialLoadCallback]);

  const filters = [
    {
      id: 'articulaciones',
      title: 'Articulaciones',
      items: articulations.map((articulation) => ({
        label: articulation.descripcion,
        value: articulation.id,
      })),
    },
    {
      id: 'elementos',
      title: 'Materiales',
      items: elements.map((element) => ({
        label: element.descripcion,
        value: element.id,
      })),
    },
    {
      id: 'etiquetas',
      title: 'Etiquetas',
      items: tags.map((tag) => ({
        label: tag.descripcion,
        value: tag.id,
      })),
    },
    {
      id: 'metodos',
      title: 'Complejos musculares',
      items: methods.map((method) => ({
        label: method.descripcion,
        value: method.id,
      })),
    },
    {
      id: 'objetivos',
      title: 'Objetivos',
      items: objetives.map((objective) => ({
        label: objective.descripcion,
        value: objective.id,
      })),
    },
    {
      id: 'poblaciones',
      title: 'Poblaciones',
      items: poblations.map((poblation) => ({
        label: poblation.descripcion,
        value: poblation.id,
      })),
    },
  ];

  const renderCell = ({ index, style, ...props }) => {
    const cellStyle = [
      style,
      Platform.OS === 'web' && {
        alignItems: 'center',
      },
    ];
    return <View index={index} style={cellStyle} {...props} />;
  };

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onFilterChange = (filterId, value) => {
    setIsLoading(true);
    const newFilters = { ...filtersSelected };
    if (value) {
      newFilters[filterId] = value;
    } else {
      delete newFilters[filterId];
    }
    setFiltersSelected(newFilters);
    if (Object.values(newFilters).every((filter) => !filter)) {
      setExercises(exercises);
    } else {
      const filteredExercises = [];
      const hasExercise = (exercise, key) => exercise[key]?.some(({ id }) => id === parseInt(newFilters[key], 10));
      exercises.forEach((exercise) => {
        if (Object.keys(newFilters).every((key) => hasExercise(exercise, key))) {
          filteredExercises.push(exercise);
        }
      });
      setExercises(filteredExercises);
    }
    setIsLoading(false);
  };

  const restartFilters = () => {
    setFiltersSelected({});
    setExercises(exercises);
  };

  return (
    <>
      <CustomButton
        title={isCollapsed ? 'Filtrar' : 'Ocultar filtros'}
        buttonColor="#bbebeb"
        textColor="#3f3a40"
        marginTop={25}
        alignSelf="center"
        onPress={toggleCollapsed}
      />
      {showTextSearch && (
        <View style={globalStyles.searchInputContainer}>
          <TextInput
            name="searchExercises"
            placeholder="Buscar ejercicios"
            onChangeText={(value) => setCurrentSearchValue(value)}
            onSubmitEditing={() => setSearch(currentSearchValue)}
            style={[globalStyles.searchTextInput, outlineStyle]}
            value={currentSearchValue}
          />
          {!search ? (
            <Icon name="search" size={18} color="#3e3a40" onPress={() => setSearch(currentSearchValue)} />
          ) : (
            <Icon
              name="close"
              size={18}
              color="#3e3a40"
              onPress={() => {
                setCurrentSearchValue('');
                setSearch('');
              }}
            />
          )}
        </View>
      )}
      <Collapsible collapsed={isCollapsed}>
        <CustomButton
          title="Limpiar filtros"
          buttonColor="#3e3a40"
          marginTop={15}
          alignSelf="center"
          onPress={restartFilters}
        />
        <FlatList
          removeClippedSubviews={false}
          data={filters}
          numColumns={isMediumScreen ? 3 : 1}
          key={isMediumScreen ? 3 : 1}
          renderItem={({ item }) => (
            <CustomDropDown
              key={item.id}
              title={item.title}
              items={item.items}
              pickerValue={filtersSelected[item.id]}
              setPickerValue={(value) => onFilterChange(item.id, value)}
            />
          )}
          keyExtractor={(item) => item.id}
          contentContainerStyle={{
            alignItems: Platform.OS === 'web' ? 'center' : undefined,
          }}
        />
      </Collapsible>
    </>
  );
};
