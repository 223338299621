import React, { useState, useRef, useCallback } from 'react';
import { ScrollView, View, Text, TextInput, TouchableOpacity, SafeAreaView, Platform } from 'react-native';
import { Icon } from 'react-native-elements';
import { useFocusEffect } from '@react-navigation/native';
import { Formik } from 'formik';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import CustomButton from 'components/CustomButton';
import Spinner from 'components/Spinner';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'src/styles/screens/private/updatePassword';
import formUpdatePasswordSchema from 'forms/schemas/formUpdatePasswordSchema';

const UpdatePassword = ({ navigation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [message, setMessage] = useState('');
  const formikForm = useRef(null);
  const isMediumScreen = useMediumScreen();
  const isWeb = Platform.OS === 'web';

  useFocusEffect(
    useCallback(() => {
      return () => {
        formikForm.current.resetForm();
        setMessage('');
        setFormError(false);
        setIsLoading(false);
      };
    }, []),
  );

  const onSubmit = async (values) => {
    try {
      setFormError(false);
      setMessage('');
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'put',
        url: '/user/update-password',
        data: { ...values },
      });
      setFormError(false);
      setMessage(data.message);
      formikForm?.current?.resetForm();
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setMessage(error.response.data.errors[0]);
      } else {
        setMessage(GENERIC_ERROR_MESSAGE);
      }
      setFormError(true);
    } finally {
      setTimeout(() => setMessage(''), 5000);
      setIsLoading(false);
    }
  };

  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={[globalStyles.scrollViewContainer, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}
      >
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Actualizar contraseña
            </Text>
            <View style={globalStyles.headerEmptyIcon} />
          </View>
          <View style={[globalStyles.formContainer, !isMediumScreen && globalStyles.formContainerMobile]}>
            <Formik
              innerRef={formikForm}
              validationSchema={formUpdatePasswordSchema}
              initialValues={{
                current_password: '',
                password: '',
                password_confirmation: '',
              }}
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
                <>
                  {!!message && (
                    <Text style={formError ? globalStyles.inputErrorText : globalStyles.successFormText}>
                      {message}
                    </Text>
                  )}
                  {!isLoading ? (
                    <>
                      <TextInput
                        style={[globalStyles.textInput, !!message && globalStyles.marginTop20, outlineStyle]}
                        name="current_password"
                        placeholder="Contraseña actual"
                        onChangeText={handleChange('current_password')}
                        onBlur={handleBlur('current_password')}
                        value={values.current_password}
                        secureTextEntry
                      />
                      {errors.current_password && touched.current_password && (
                        <Text style={globalStyles.inputErrorText}>{errors.current_password}</Text>
                      )}
                      <TextInput
                        style={[globalStyles.textInput, globalStyles.marginTop20, outlineStyle]}
                        name="password"
                        placeholder="Contraseña nueva"
                        onChangeText={handleChange('password')}
                        onBlur={handleBlur('password')}
                        value={values.password}
                        secureTextEntry
                      />
                      {errors.password && touched.password && (
                        <Text style={globalStyles.inputErrorText}>{errors.password}</Text>
                      )}
                      <TextInput
                        style={[globalStyles.textInput, globalStyles.marginTop20, outlineStyle]}
                        name="password_confirmation"
                        placeholder="Confirmar contraseña nueva"
                        onChangeText={handleChange('password_confirmation')}
                        onBlur={handleBlur('password_confirmation')}
                        value={values.password_confirmation}
                        secureTextEntry
                      />
                      {errors.password_confirmation && touched.password_confirmation && (
                        <Text style={globalStyles.inputErrorText}>{errors.password_confirmation}</Text>
                      )}
                      <CustomButton
                        title="Actualizar"
                        buttonColor="#bbebeb"
                        textColor="#3f3a40"
                        marginTop={30}
                        alignSelf="center"
                        onPress={handleSubmit}
                        disabled={!isValid || isLoading}
                      />
                    </>
                  ) : (
                    <View style={globalStyles.loadingContainer}>
                      <Spinner />
                    </View>
                  )}
                </>
              )}
            </Formik>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default UpdatePassword;
