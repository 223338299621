import React, { useState } from 'react';
import { VirtualizedList, View, TextInput } from 'react-native';
import {
  Provider as PaperProvider,
  Subheading,
  Button,
  Portal,
  Dialog,
  RadioButton,
  TouchableRipple,
  DefaultTheme,
} from 'react-native-paper';
import useMediumScreen from 'hooks/useMediumScreen';
import { globalStyles, outlineStyle } from 'src/styles/global';
import { allWordsIncluded } from 'utils/search';
import { styles } from 'styles/components/searchableDropDown';
import CustomButton from './CustomButton';

export default ({ items, onConfirm, onDismiss, visible }) => {
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState('');

  const isMediumScreen = useMediumScreen();

  const filteredItems = items.filter((item) =>
    allWordsIncluded(search.toLowerCase().trim().split(' '), item.label.toLowerCase()),
  );
  const handleSearch = (text) => {
    setSearch(text);
    setValue(null);
  };

  const onItemPress = (item) => (item.value === value ? setValue(null) : setValue(item.value));

  return (
    <PaperProvider theme={DefaultTheme}>
      <Portal theme={DefaultTheme}>
        <Dialog
          onDismiss={onDismiss}
          visible={visible}
          style={isMediumScreen ? styles.containerWeb : styles.containerMobile}
        >
          <View style={styles.searchInputContainer}>
            <TextInput
              onChangeText={handleSearch}
              placeholder="Buscar..."
              style={[styles.searchInput, outlineStyle]}
              value={search}
            />
          </View>
          <Dialog.ScrollArea style={styles.scrollContainer}>
            {filteredItems.length > 0 ? (
              <VirtualizedList
                data={filteredItems}
                renderItem={({ item }) => (
                  <TouchableRipple onPress={() => onItemPress(item)}>
                    <View style={styles.row}>
                      <View pointerEvents="none">
                        <RadioButton value={item.value} status={item.value === value ? 'checked' : 'unchecked'} />
                      </View>
                      <Subheading style={styles.text}>{item.label}</Subheading>
                    </View>
                  </TouchableRipple>
                )}
                keyExtractor={(item, index) => `item-${item.value}-${index}`}
                getItemCount={() => filteredItems.length}
                getItem={(data, index) => data[index]}
              />
            ) : (
              <View style={styles.row}>
                <Subheading style={styles.text}>No se encontraron resultados</Subheading>
              </View>
            )}
          </Dialog.ScrollArea>
          <Dialog.Actions>
            <Button
              mode="text"
              color={globalStyles.buttonColor.color}
              labelStyle={[globalStyles.normalText]}
              onPress={onDismiss}
            >
              Cancelar
            </Button>
            <Button
              mode="text"
              color={globalStyles.buttonColor.color}
              labelStyle={[globalStyles.normalText]}
              onPress={() => onConfirm(value)}
            >
              Aceptar
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </PaperProvider>
  );
};
