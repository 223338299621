import { Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';

const isMobile = Platform.OS !== 'web';

const requestPermissions = async () => {
  try {
    if (isMobile) {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      return status === 'granted';
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const pickImage = async (withAspect = false) => {
  const params = {
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: false,
    quality: 1,
    base64: true,
    exif: true,
  };

  if (withAspect) {
    params.aspect = [4, 3];
  }
  try {
    const hasPermissions = await requestPermissions();
    if (hasPermissions) {
      const result = await ImagePicker.launchImageLibraryAsync(params);
      if (!result.cancelled) {
        let uri = result.uri;
        if (isMobile) {
          let extension = uri.substr(uri.lastIndexOf('.') + 1);
          extension = extension === 'jpg' ? 'jpeg' : extension;
          uri = `data:image/${extension};base64,${result.base64}`;
        }
        return uri;
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('Debes aceptar los permisos para poder realizar esta acción');
    }
  } catch (error) {
    console.log(error);
  }
};
