import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as RootNavigation from 'navigations/RootNavigation';
import { USER_DATA_KEY } from 'constants/storageKey';
import { BASE_URL } from '@env';
const axiosInstance = axios.create({
  baseURL: BASE_URL + 'api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await AsyncStorage.getItem(USER_DATA_KEY);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const authFailed = [401, 403].includes(error?.response?.status);
    if (authFailed) {
      await AsyncStorage.removeItem(USER_DATA_KEY);
      RootNavigation.navigate('Main', { screen: 'Login' });
    } else {
      return Promise.reject(error);
    }
  },
);

export default axiosInstance;
