import React, { useContext, useState } from 'react';
import { ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { Modal, Portal, Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import { isTrainer, isGym } from 'helpers/roles';
import { hasTrainerSubscription, hasGymSubscription } from 'helpers/plans';
import appContext from 'contexts/appContext';
import useMediumScreen from 'hooks/useMediumScreen';
import PersonalTrainer from 'assets/personalTrainer.jpg';
import Gimnasio from 'assets/gimnasio.jpg';
import { styles } from 'styles/components/pricingBanner';

const PricingBanner = ({ navigation }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const { userData } = useContext(appContext);

  const isMediumScreen = useMediumScreen();
  const hideModal = () => setIsModalVisible(false);

  const isTrainerUser = isTrainer(userData);
  const isGymUser = isGym(userData);

  const hasActiveTrainerSubscription = hasTrainerSubscription(userData);
  const hasActiveGymSubscription = hasGymSubscription(userData);

  const showTrainerBanner = isTrainerUser && !hasActiveTrainerSubscription;
  const showBaseGymBanner = isGymUser && !hasActiveGymSubscription;
  const showBanner = showTrainerBanner || showBaseGymBanner;

  const getBackgroundImage = () => {
    if (isTrainerUser) {
      return PersonalTrainer;
    } else if (isGymUser) {
      return Gimnasio;
    } else {
      return null;
    }
  };

  const getMessage = () => {
    if (showTrainerBanner) {
      return 'Conviértete en personal trainer';
    } else if (showBaseGymBanner) {
      return 'Conviértete en un gimnasio';
    } else {
      return null;
    }
  };

  return (
    showBanner && (
      <PaperProvider theme={DefaultTheme}>
        <Portal theme={DefaultTheme}>
          <Modal
            visible={isModalVisible}
            onDismiss={hideModal}
            contentContainerStyle={styles.modalContainer}
            theme={{ colors: { backdrop: 'rgba(0, 0, 0, 0.8)' } }}
          >
            <View style={isMediumScreen ? styles.modalContent : styles.modalContentMobile}>
              <TouchableOpacity onPress={hideModal} style={styles.closeContainer}>
                <Icon name="close" size={20} color="#ffffff" />
              </TouchableOpacity>
              <View style={isMediumScreen ? styles.container : styles.containerMobile}>
                <ImageBackground source={getBackgroundImage()} style={styles.image}>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('MySubscriptionsScreen');
                      hideModal();
                    }}
                    style={styles.titleContainer}
                  >
                    <Text style={styles.titleStyle}>{getMessage()}</Text>
                  </TouchableOpacity>
                </ImageBackground>
              </View>
            </View>
          </Modal>
        </Portal>
      </PaperProvider>
    )
  );
};

export default PricingBanner;
