import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  modalContainer: {
    height: '100%',
  },
  modalContent: {
    position: 'relative',
    width: 500,
    alignSelf: 'center',
  },
  modalContentMobile: {
    position: 'relative',
    width: 300,
    alignSelf: 'center',
  },
  closeContainer: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 10,
  },
  container: {
    width: 500,
    height: 300,
  },
  containerMobile: {
    width: 300,
    height: 200,
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 15,
  },
  titleStyle: {
    fontFamily: 'gilroy-bold',
    fontSize: 20,
    color: 'white',
    textAlign: 'center',
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    borderRadius: 10,
    overflow: 'hidden',
  },
});
