import * as React from 'react';
import { Text, View } from 'react-native';
import moment from 'moment/moment';
import { styles } from 'styles/components/markItem';

const MarkItem = (props) => {
  const { marks, titleStyles, dateStyles, title } = props;
  const formatMark = (item) => {
    if (item.mark_value !== null && item.mark_type && item.measure_type_id !== 5) {
      return `${item.mark_value} ${item.mark_type_id === 1 ? item.other_mark_label : item.mark_type.description}`;
    } else if (item.measure_type_id === 5) {
      return formatTime(item.time_hours_value, item.time_minutes_value, item.time_seconds_value);
    }
  };

  const formatTime = (hour, min, sec) => {
    const formattedTime = [];

    if (hour !== null) {
      formattedTime.push(`${hour}hs`);
    }
    if (min !== null) {
      formattedTime.push(`${min}min`);
    }
    if (sec !== null) {
      formattedTime.push(`${sec}seg.`);
    }

    return `En ${formattedTime.join(' ')}`;
  };

  const formatDate = (item) => {
    return moment(item.created_at).format('DD/MM/YYYY');
  };

  const thereIsOtherMeasure = (item) => {
    if (item.measure_type_id === 5) {
      return formatTime(item.time_hours_value, item.time_minutes_value, item.time_seconds_value);
    } else {
      if (item.measure_type) {
        return `${item.measure_value} ${
          item.measure_type.id === 1 ? item.other_measure_label : item.measure_type?.description
        }`;
      }
    }
  };

  const formatAnnotations = (item) => {
    return item.annotations !== null && <Text style={styles.description}>{item.annotations}</Text>;
  };
  const subtitle = (item) => {
    if (item.mark_value && item.mark_type && item.measure_type !== null) {
      return `${item.mark_value} ${item.mark_type_id === 1 ? item.other_mark_label : item.mark_type.description}`;
    }
  };
  return marks.map((item) => (
    <View key={item.id}>
      {title && (
        <Text numberOfLines={1} style={styles.exerciseMarkTitle}>
          {item.exercise.descripcion}
        </Text>
      )}
      <View style={[styles.itemContainer, title && { paddingTop: 2 }]}>
        <View style={styles.header}>
          <Text style={[styles.markTitle, titleStyles]}>
            {item.measure_type_id && item.measure_value !== null ? thereIsOtherMeasure(item) : formatMark(item)}
          </Text>
          <View>
            <Text style={[styles.time, dateStyles]}>{formatDate(item)}</Text>
          </View>
        </View>

        <View style={styles.infoContainer}>
          <Text style={styles.secondary}>{subtitle(item)}</Text>
          {formatAnnotations(item)}
        </View>
      </View>
    </View>
  ));
};

export default MarkItem;
