import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  trainingStepContainer: {
    borderBottomWidth: 1,
    borderColor: '#d6d6d6',
  },
  trainingStepTouchable: {
    width: '100%',
    minHeight: 56,
  },
  trainingStepTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'space-between',
  },
  trainingStepTitleText: {
    paddingTop: 5,
    textAlign: 'left',
    fontFamily: 'gilroy-regular',
  },
  routineCompletedText: {
    paddingTop: 5,
    textAlign: 'center',
    fontFamily: 'gilroy-regular',
  },
  trainingStepStatusContainer: {
    marginTop: 5,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  trainingStepStatusSwitch: {
    marginLeft: 20,
    marginBottom: 10,
  },
  trainingStepStatusText: {
    fontFamily: 'gilroy-regular',
  },
  daysContainer: {
    width: '100%',
    alignItems: 'center',
  },
  dayItemTouchable: {
    display: 'flex',
    flexDirection: 'row',
    height: 56,
    backgroundColor: '#f6f6f6',
    marginBottom: 10,
  },
  dayItemTitleContainer: {
    justifyContent: 'center',
    flex: 4,
    padding: 15,
  },
  dayItemTitleText: {
    fontFamily: 'gilroy-regular',
    fontSize: 16,
  },
  dayItemTitleIcon: {
    flex: 1,
    marginTop: 17,
    marginRight: 16,
  },
  noTrainingStepsText: {
    textAlign: 'center',
    fontFamily: 'gilroy-regular',
  },
  trainingDaySwitchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
});
