import React, { useState, useEffect, useRef } from 'react';
import { Platform } from 'react-native';
import * as Font from 'expo-font';
import * as Device from 'expo-device';
import { useKeepAwake } from 'expo-keep-awake';
import * as Notifications from 'expo-notifications';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { USER_DATA_KEY } from 'constants/storageKey';
import RootStack from 'navigations/RootStack';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import Spinner from 'components/Spinner';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default function App() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [iosInit, setIosInit] = useState(true);
  const [userData, setUserData] = useState(null);
  const [contactChat, setContactChat] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [hasNotifications, setHasNotifications] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);

  const [expoPushToken, setExpoPushToken] = useState('');
  const notificationListener = useRef();
  const responseListener = useRef();
  //const navigation = useNavigation();

  useKeepAwake();

  useEffect(() => {
    initializeUser();
    initializeFonts();
    if (Platform.OS === 'web') {
      return;
    }
    // Register for push notification
    const token = registerForPushNotificationsAsync();
    setExpoPushToken(token);

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      //notificationCommonHandler(notification);
    });

    // This listener is fired whenever a user taps on or interacts with a notification
    // (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
      //notificationCommonHandler(response.notification);
      //alert('response received: ' + JSON.stringify(response.notification.request.content));
      //notificationNavigationHandler(response.notification.request.content);
    });
    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  const registerForPushNotificationsAsync = async () => {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        console.log('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      console.log('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    setExpoPushToken(token);

    return token;
  };

  const initializeUser = async () => {
    try {
      if (Platform.OS === 'ios') {
        try {
          const { data } = await axiosInstance({ method: 'get', url: '/check' });
          if (data) {
            setIosInit(data.init);
          }
        } catch (error) {
          console.log(error);
        }
      }
      const token = await AsyncStorage.getItem(USER_DATA_KEY);
      if (token) {
        const { data } = await axiosInstance({ method: 'get', url: '/user' });
        setUserData({ user: data.user, token });
        if (Platform.OS !== 'web') {
          const res = await axiosInstance({ method: 'get', url: '/notifications' });
          setNotifications(res.data.notifications);
        }
        if (expoPushToken && !data.user.expo_push_token) {
          try {
            axiosInstance({
              method: 'post',
              url: '/user/set-expo-push-token',
              data: {
                expoPushToken,
              },
            });
          } catch (error) {
            console.log('Error al guardar expo-push-token');
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsPreloaderVisible(false);
    }
  };

  const clearUserData = async () => {
    try {
      await AsyncStorage.removeItem(USER_DATA_KEY);
      setUserData(null);
    } catch (error) {
      console.log(error);
    }
  };

  const initializeFonts = async () => {
    try {
      await Font.loadAsync({
        'gilroy-regular': require('./src/assets/fonts/Gilroy-Regular.otf'),
        'gilroy-bold': require('./src/assets/fonts/Gilroy-Bold.otf'),
        'gilroy-semibold': require('./src/assets/fonts/Gilroy-Semibold.otf'),
      });
      setDataLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SafeAreaProvider>
      <appContext.Provider
        value={{
          iosInit,
          setIosInit,
          userData,
          setUserData,
          clearUserData,
          isSubmitting,
          setIsSubmitting,
          expoPushToken,
          setExpoPushToken,
          contactChat,
          setContactChat,
          notifications,
          setNotifications,
          hasNotifications,
          setHasNotifications,
        }}
      >
        {!dataLoaded || isPreloaderVisible ? <Spinner /> : <RootStack />}
      </appContext.Provider>
    </SafeAreaProvider>
  );
}
