import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  containerForSafeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
  publicationFormContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginBottom: 20,
  },
  publicationImagePreview: {
    marginTop: 15,
    width: 350,
    height: 150,
  },
  publicationImageBtn: {
    width: 300,
    borderRadius: 15,
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    justifyContent: 'center',
    padding: 4,
    marginTop: 45,
  },
  contentTextarea: {
    borderWidth: 1,
    height: 150,
    fontSize: 14,
    padding: 10,
  },
  addImageBtnText: {
    fontFamily: 'gilroy-regular',
  },
  recommendedImageFormat: {
    color: '#ff0000',
    fontSize: 14,
    padding: 15,
    textAlign: 'center',
  },
});
