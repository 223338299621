import React, { useState, useCallback, useRef, useContext } from 'react';
import { ScrollView, View, Text, TextInput, TouchableOpacity, Platform } from 'react-native';
import { Icon } from 'react-native-elements';
import { useFocusEffect } from '@react-navigation/native';
import { Formik } from 'formik';
import axiosInstance from 'services/axios';
import { getFolders, storeFolder } from 'services/folderService';
import useMediumScreen from 'hooks/useMediumScreen';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import CustomErrors from 'components/CustomErrors';
import CustomButton from 'components/CustomButton';
import Spinner from 'components/Spinner';
import FolderFormModal from 'src/components/FolderFormModal';
import SearchableDropDown from 'components/SearchableDropDown';
import formRoutineSchema from './schemas/formRoutineSchema';
import { globalStyles, outlineStyle } from 'styles/global';
import appContext from 'contexts/appContext';

export default ({ navigation, route }) => {
  const { userData } = useContext(appContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isFolderSelectModalVisible, setIsFolderSelectModalVisible] = useState(false);
  const [isFolderFormModalVisible, setIsFolderFormModalVisible] = useState(false);
  const [formError, setFormError] = useState('');
  const [myFolders, setMyFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const isMediumScreen = useMediumScreen();
  const formikForm = useRef(null);
  const [routine, setRoutine] = useState(null);
  const initialValues = { description: routine?.descripcion ?? '', folderId: 0 };
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);
  const foldersDropDown = myFolders.map((folder) => ({ label: folder.description, value: folder.id }));

  useFocusEffect(
    useCallback(() => {
      loadInitialData();
      return () => {
        setRoutine(null);
        setSelectedFolder(null);
      };
    }, [route?.params?.id]),
  );

  const loadInitialData = async () => {
    await getMyFolders();
    if (route?.params?.id) {
      await getRoutine(route?.params?.id);
    }
  };

  const getRoutine = async (routineId) => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: '/routines/' + routineId,
      });
      setRoutine(data.routine);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMyFolders = async () => {
    if (!userData.user) return;
    startLoading();
    const folders = await getFolders();
    setMyFolders(folders);
    stopLoading();
  };

  React.useEffect(() => {
    if (!routine || myFolders.length === 0) return;
    if (routine.folder_id) {
      const foundFolder = myFolders.find((folder) => folder.id === routine?.folder_id);
      if (foundFolder) {
        setSelectedFolder(foundFolder);
      }
    }
  }, [routine]);

  const onFolderConfirm = (value) => {
    const folder = myFolders.find(({ id }) => id === value);
    if (folder) setSelectedFolder(folder);
    setIsFolderSelectModalVisible(false);
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await axiosInstance({
        method: routine ? 'put' : 'post',
        url: 'routines' + (routine ? '/' + routine.id : ''),
        data: {
          descripcion: values.description,
          folderId: selectedFolder?.id ?? null,
        },
      });
      setIsLoading(false);
      navigation.navigate('RoutinesListScreen', {
        refreshList: true,
      });
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setFormError(error.response.data.errors[0]);
      } else {
        setFormError(GENERIC_ERROR_MESSAGE);
      }
      setIsLoading(false);
    }
  };

  const onSubmitFolderForm = async (folder) => {
    if (!userData.user) return;

    const newFolder = {
      ...folder,
      trainerId: userData.user.id,
    };

    startLoading();
    const result = await storeFolder(newFolder);
    if (result) {
      setSelectedFolder(result);
      getMyFolders();
    }
    setIsFolderFormModalVisible(false);
    stopLoading();
  };

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              {routine ? 'Editar' : 'Crear'} programación
            </Text>
            <View style={globalStyles.headerEmptyIcon} />
          </View>
          <View style={[globalStyles.formContainer, !isMediumScreen && globalStyles.formContainerMobile]}>
            <Formik
              innerRef={formikForm}
              validationSchema={formRoutineSchema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
                <>
                  {!!formError && (
                    <View style={globalStyles.errorContainer}>
                      <CustomErrors msg={formError} />
                    </View>
                  )}
                  {!isLoading ? (
                    <>
                      {myFolders.length > 0 && (
                        <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                          <Text style={[globalStyles.tableTitle, globalStyles.marginTop0, globalStyles.marginRight10]}>
                            Seleccionar una de mis carpetas
                          </Text>
                          <TouchableOpacity onPress={() => setIsFolderSelectModalVisible(true)}>
                            <Icon name="folder" size={22} color="#f2a172" />
                          </TouchableOpacity>
                        </View>
                      )}
                      <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                        <Text style={[globalStyles.tableTitle, globalStyles.marginTop0, globalStyles.marginRight10]}>
                          Crear una carpeta nueva
                        </Text>
                        <TouchableOpacity onPress={() => setIsFolderFormModalVisible(true)}>
                          <Icon name="add-circle" size={22} color="#f2a172" />
                        </TouchableOpacity>
                      </View>
                      {selectedFolder ? (
                        <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                          <Text style={{ fontSize: 17, textAlign: 'left', marginTop: 10, marginBottom: 10 }}>
                            Carpeta seleccionada:
                            <Text
                              style={{
                                paddingLeft: 5,
                                color: '#c33e1d',
                              }}
                            >
                              {selectedFolder.description}
                            </Text>
                          </Text>
                          <Icon
                            name="close"
                            style={{ marginLemarginTop: 10 }}
                            size={22}
                            color="#ff0000"
                            onPress={() => {
                              setSelectedFolder(null);
                            }}
                          />
                        </View>
                      ) : (
                        <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                          <Text style={{ fontSize: 17, textAlign: 'left', marginTop: 10, marginBottom: 10 }}>
                            Esta rutina no pertenece a ninguna carpeta
                          </Text>
                        </View>
                      )}
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle]}
                        name="description"
                        placeholder="Descripción"
                        onChangeText={handleChange('description')}
                        onBlur={handleBlur('description')}
                        value={values.description}
                      />
                      {errors.description && touched.description && (
                        <Text style={globalStyles.inputErrorText}>{errors.description}</Text>
                      )}
                      <CustomButton
                        title={routine ? 'Actualizar' : 'Crear'}
                        buttonColor="#bbebeb"
                        textColor="#3f3a40"
                        marginTop={30}
                        alignSelf="center"
                        onPress={handleSubmit}
                        disabled={!isValid || isLoading}
                      />
                    </>
                  ) : (
                    <View style={globalStyles.loadingContainer}>
                      <Spinner />
                    </View>
                  )}
                </>
              )}
            </Formik>
          </View>
        </View>
      </ScrollView>
      {myFolders.length > 0 && (
        <SearchableDropDown
          items={foldersDropDown}
          onConfirm={onFolderConfirm}
          onDismiss={() => setIsFolderSelectModalVisible(false)}
          visible={isFolderSelectModalVisible}
        />
      )}
      <FolderFormModal
        visible={isFolderFormModalVisible}
        onDismiss={() => setIsFolderFormModalVisible(false)}
        onSubmit={onSubmitFolderForm}
        title="Crear carpeta"
      />
    </>
  );
};
