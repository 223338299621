import React, { useEffect, useState, useContext, useCallback } from 'react';
import { ScrollView, View, Text, TouchableOpacity, KeyboardAvoidingView, Platform } from 'react-native';
import { Card, Title, Paragraph } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import useMediumScreen from 'hooks/useMediumScreen';
import axiosInstance from 'services/axios';
import appContext from 'contexts/appContext';
import Spinner from 'components/Spinner';
import ImageCrop from 'components/ImageCrop';
import { globalStyles } from 'styles/global';

export default ({ navigation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [routines, setRoutines] = useState([]);
  const { userData } = useContext(appContext);
  const isMediumScreen = useMediumScreen();
  const isWeb = Platform.OS === 'web';

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  useEffect(() => {
    getRoutines();
  }, [getRoutines]);

  const getRoutines = useCallback(async () => {
    try {
      startLoading();
      const { data } = await axiosInstance({
        method: 'get',
        url: 'routines/user/' + userData?.user?.id,
      });
      setRoutines(data.routines);
    } catch (error) {
      console.log(error);
    } finally {
      stopLoading();
    }
  }, [userData]);

  return (
    <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
      <View style={globalStyles.viewContainer}>
        <View style={globalStyles.headerContainer}>
          {isWeb && (
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          )}
          <Text
            style={[
              isWeb ? globalStyles.headerTitle : globalStyles.headerTitleForMobileNavigation,
              !isMediumScreen && globalStyles.headerTitleMobile,
            ]}
          >
            Mis entrenamientos
          </Text>
          <TouchableOpacity disabled={isLoading} onPress={getRoutines}>
            <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
          </TouchableOpacity>
        </View>
        {isLoading ? (
          <View style={globalStyles.loadingContainer}>
            <Spinner />
          </View>
        ) : (
          <View style={globalStyles.tableContainer}>
            {routines.map((routine) => (
              <View
                key={`routine-${routine.id}`}
                style={[isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile]}
              >
                <TouchableOpacity>
                  <Card
                    onPress={() => {
                      navigation.navigate('StudentTrainingDaysListScreen', {
                        routineId: routine.id,
                        routineDescription: routine.descripcion,
                        refreshList: true,
                      });
                    }}
                  >
                    <Card.Content style={globalStyles.flexRow}>
                      <View style={globalStyles.marginRight10}>
                        <ImageCrop disabled hasSmallSize image={routine.user?.image} />
                      </View>
                      <View
                        style={[
                          globalStyles.flexColumn,
                          globalStyles.justifyContentCenter,
                          globalStyles.w100,
                          globalStyles.flex1,
                        ]}
                      >
                        <Title style={globalStyles.normalText}>{routine.descripcion}</Title>
                        <Paragraph style={globalStyles.normalText}>Entrenador: {routine.user?.name}</Paragraph>
                      </View>
                    </Card.Content>
                  </Card>
                </TouchableOpacity>
              </View>
            ))}
          </View>
        )}
      </View>
    </ScrollView>
  );
};
