import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  backButtonContainer: {
    justifyContent: 'center',
    marginRight: 8,
    marginLeft: 6,
  },
  chatItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: 4,
  },
  profileImageContainer: {
    width: 50,
  },
  commentIcon: {
    marginTop: 15,
    color: '#f00',
  },
  commentInput: {
    flex: 3,
    fontFamily: 'gilroy-regular',
    color: '#3f3a40',
    fontSize: 18,
    height: 45,
    backgroundColor: '#FFF',
  },
  messageContainer: {
    padding: 6,
    borderWidth: 1,
    borderRadius: 10,
    maxWidth: 200,
  },
  toRight: {
    alignItems: 'center',
  },
  messageText: {
    fontFamily: 'gilroy-regular',
  },
  textMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 50,
    width: '100%',
    paddingBottom: 2,
  },
  textInputMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    paddingRight: 5,
  },
  scrollviewContainer: {
    flex: 1,
    backgroundColor: '#e2f0fb',
  },
  alignOwnMessage: {
    backgroundColor: '#f2a172',
    alignSelf: 'flex-end',
    marginRight: 10,
  },
  alignContactMessage: {
    alignSelf: 'flex-start',
    marginLeft: 10,
    backgroundColor: '#fff',
  },
  messageBodyContainer: {
    width: 200,
    borderRadius: 10,
    padding: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
  contactProfileImage: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  contactNameContainer: {
    flex: 6,
    padding: 5,
    paddingLeft: 10,
    justifyContent: 'center',
  },
  contactNameText: {
    fontFamily: 'gilroy-bold',
    fontSize: 18,
  },
  scrollViewToMessages: {
    backgroundColor: '#f5f5f5',
    marginTop: 5,
  },
  messageItemContainer: {
    justifyContent: 'center',
    marginBottom: 10,
  },
  messageDatetime: {
    fontFamily: 'gilroy-regular',
    color: '#49565e',
    fontSize: 11,
    alignSelf: 'flex-end',
  },
  newMessageButton: {
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.2)',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 100,
  },
  messageInputContainer: {
    height: 50,
    width: '85%',
    justifyContent: 'center',
  },
  messageInput: {
    fontFamily: 'gilroy-regular',
    marginLeft: 5,
    paddingLeft: 15,
    paddingRigh: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 40,
    borderColor: 'lightgrey',
    borderRadius: 25,
    backgroundColor: '#fff',
  },
  sendMessageContainer: {
    height: 50,
    width: '15%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
