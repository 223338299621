import React, { useState, useRef, useContext, useCallback } from 'react';
import { View, Text, TextInput, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Formik } from 'formik';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as AppleAuthentication from 'expo-apple-authentication';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import axiosInstance from 'services/axios';
import { USER_DATA_KEY } from 'constants/storageKey';
import appContext from 'contexts/appContext';
import Spinner from 'components/Spinner';
import SocialContainer from 'components/SocialContainer';
import CustomButton from 'components/CustomButton';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/forms/formRegister';
import registerSchema from './schemas/formRegisterSchema';
import { handleIOSName } from 'helpers/ios';
import { SSO_LOGIN } from 'src/constants/enums/ssoLogin';

export default ({ navigation, isSubmitting, setIsSubmitting, onReturnPress, roleId }) => {
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState(false);
  const { setUserData, expoPushToken } = useContext(appContext);
  const formikForm = useRef(null);

  const onSubmit = async (values) => {
    try {
      setFormError(false);
      setMessage('');
      setIsSubmitting(true);
      const { data } = await axiosInstance({
        method: 'post',
        url: 'register',
        data: {
          ...values,
          roleId,
          device_id: Constants.installationId || Constants.getWebViewUserAgentAsync(),
          expoPushToken,
        },
      });
      if (data.token) {
        await AsyncStorage.setItem(USER_DATA_KEY, data.token);
        setUserData(data);
        navigation.navigate('Main', { screen: 'Dashboard' });
      } else {
        setFormError(false);
        setMessage(
          '¡Genial! Te hemos enviado un correo de verificación para finalizar tu registro, comprueba tu bandeja de entrada.',
        );
        formikForm?.current?.resetForm();
        setTimeout(() => setMessage(''), 5000);
      }
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setMessage(error.response.data.errors[0]);
      } else {
        setMessage(GENERIC_ERROR_MESSAGE);
      }
      setFormError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitForm = (values) => {
    const data = {
      name: values.name,
      email: values.email,
      emailConfirmation: values.email_verified_at,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
    };
    onSubmit(data);
  };

  useFocusEffect(
    useCallback(() => {
      return () => {
        formikForm.current?.resetForm();
        setMessage('');
        setFormError(false);
        setIsSubmitting(false);
      };
    }, [setIsSubmitting]),
  );

  return (
    <>
      <SocialContainer isSubmitting={isSubmitting} onSubmit={onSubmit} />
      {Platform.OS === 'ios' && (
        <AppleAuthentication.AppleAuthenticationButton
          buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_UP}
          buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
          cornerRadius={5}
          style={styles.appleButton}
          onPress={async () => {
            try {
              const credentials = await AppleAuthentication.signInAsync({
                requestedScopes: [
                  AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                  AppleAuthentication.AppleAuthenticationScope.EMAIL,
                ],
              });
              onSubmit({
                email: credentials.email,
                name: handleIOSName(credentials.fullName),
                platform_id: credentials.user,
                platform_type: SSO_LOGIN.apple,
                ios_token: credentials.identityToken,
              });
            } catch (e) {
              if (e.code === 'ERR_CANCELED') {
                // handle that the user canceled the sign-in flow
              } else {
                // handle other errors
              }
            }
          }}
        />
      )}
      <View style={[styles.formContainer, globalStyles.marginTop10]}>
        <Formik
          innerRef={formikForm}
          validationSchema={registerSchema}
          initialValues={{
            name: '',
            email: '',
            email_verified_at: '',
            password: '',
            passwordConfirmation: '',
            device_id: '',
          }}
          onSubmit={onSubmitForm}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
            <>
              <View>
                <View style={styles.firstContainer}>
                  {formError ? (
                    <Text style={globalStyles.inputErrorText}>{message}</Text>
                  ) : (
                    <Text style={globalStyles.successFormText}>{message}</Text>
                  )}
                  {!isSubmitting ? (
                    <>
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle]}
                        name="name"
                        placeholder="Nombre y apellido"
                        onChangeText={handleChange('name')}
                        onBlur={handleBlur('name')}
                        value={values.name}
                      />
                      {errors.name && touched.name && <Text style={globalStyles.inputErrorText}>{errors.name}</Text>}
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle]}
                        name="email"
                        placeholder="Email"
                        onChangeText={handleChange('email')}
                        onBlur={handleBlur('email')}
                        value={values.email}
                        keyboardType="email-address"
                      />
                      {errors.email && touched.email && <Text style={globalStyles.inputErrorText}>{errors.email}</Text>}
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle]}
                        name="email_verified_at"
                        placeholder="Confirmar email"
                        onChangeText={handleChange('email_verified_at')}
                        onBlur={handleBlur('email_verified_at')}
                        value={values.email_verified_at}
                        keyboardType="email-address"
                      />
                      {errors.email_verified_at && touched.email_verified_at && (
                        <Text style={globalStyles.inputErrorText}>{errors.email_verified_at}</Text>
                      )}
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle]}
                        name="password"
                        placeholder="Contraseña"
                        onChangeText={handleChange('password')}
                        onBlur={handleBlur('password')}
                        value={values.password}
                        secureTextEntry
                      />
                      {errors.password && touched.password && (
                        <Text style={globalStyles.inputErrorText}>{errors.password}</Text>
                      )}
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle]}
                        name="passwordConfirmation"
                        placeholder="Confirmar contraseña"
                        onChangeText={handleChange('passwordConfirmation')}
                        onBlur={handleBlur('passwordConfirmation')}
                        value={values.passwordConfirmation}
                        secureTextEntry
                      />
                      {errors.passwordConfirmation && touched.passwordConfirmation && (
                        <Text style={globalStyles.inputErrorText}>{errors.passwordConfirmation}</Text>
                      )}
                      <View style={[styles.submitButton, globalStyles.contentCenteredColumn]}>
                        <CustomButton
                          title="Registrarse"
                          buttonColor="#bbebeb"
                          textColor="#3f3a40"
                          marginTop={20}
                          alignSelf="center"
                          onPress={handleSubmit}
                          disabled={!isValid || isSubmitting}
                        />
                        <CustomButton
                          title="Volver"
                          buttonColor="gray"
                          marginTop={20}
                          marginBottom={30}
                          alignSelf="center"
                          onPress={onReturnPress}
                          disabled={isSubmitting}
                        />
                      </View>
                    </>
                  ) : (
                    <View style={styles.registerContainerSpinner}>
                      <Spinner />
                    </View>
                  )}
                </View>
              </View>
            </>
          )}
        </Formik>
      </View>
    </>
  );
};
