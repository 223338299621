import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ScrollView, View, Text, TextInput, TouchableOpacity, KeyboardAvoidingView, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Icon } from 'react-native-elements';
import { Formik } from 'formik';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import Breadcrumb from 'components/Breadcrumb';
import SearchableDropDown from 'components/SearchableDropDown';
import StockExercisesFilters from 'components/StockExercisesFilters';
import CustomErrors from 'components/CustomErrors';
import CustomButton from 'components/CustomButton';
import Spinner from 'components/Spinner';
import { getExercises } from 'services/exerciseService';
import { globalStyles, outlineStyle } from 'styles/global';
import formTrainingStepExerciseSchema from './schemas/formTrainingStepExerciseSchema';

export default ({ navigation, route }) => {
  const {
    routineId,
    routineDescription,
    trainingDayId,
    trainingDayDescription,
    trainingStepId,
    trainingStepDescription,
    trainingStepExerciseId,
    exerciseId,
    comments,
    observations,
  } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [filtersSelected, setFiltersSelected] = useState({});
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [isExercisesModalVisible, setIsExercisesModalVisible] = useState(false);
  const [formError, setFormError] = useState('');
  const [exerciseSelected, setExerciseSelected] = useState({ touched: false, value: exerciseId });
  const isMediumScreen = useMediumScreen();
  const formikForm = useRef(null);
  const initialValues = { comments: comments ?? '', observations: observations ?? '' };
  const exercisesDropDown = filteredExercises.map((exercise) => ({ label: exercise.descripcion, value: exercise.id }));
  const currentExercise = filteredExercises.find((exercise) => exercise.id === exerciseSelected.value);

  const breadcrumbRoutes = [
    {
      label: routineDescription,
      onPress: () => {
        navigation.navigate('RoutinesListScreen', {});
      },
    },
    {
      label: trainingDayDescription,
      onPress: () => {
        navigation.navigate('TrainingDaysListScreen', { routineId, routineDescription, refreshList: true });
      },
    },
    {
      label: trainingStepDescription,
      onPress: () => {
        navigation.navigate('TrainingStepsScreen', {
          routineId,
          routineDescription,
          trainingDayId,
          trainingDayDescription,
          refreshList: true,
        });
      },
    },
    {
      label: 'Ejercicios',
      onPress: () => navigation.goBack,
    },
  ];

  useEffect(() => {
    getTrainingStepExercisesData();
  }, [getTrainingStepExercisesData]);

  useEffect(() => {
    if (trainingStepExerciseId) {
      setExerciseSelected({ touched: false, value: exerciseId });
    }
  }, [route, trainingStepExerciseId, exerciseId]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        formikForm.current.resetForm();
        setFormError('');
        setIsLoading(false);
        setExerciseSelected({ touched: false, value: null });
        setFiltersSelected({});
      };
    }, []),
  );

  const getTrainingStepExercisesData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getExercises();
      setExercises(data.exercises);
      setFilteredExercises(data.exercises);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [trainingStepId]);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await axiosInstance({
        method: trainingStepExerciseId ? 'put' : 'post',
        url: trainingStepExerciseId
          ? `training-steps/${trainingStepId}/exercises/${trainingStepExerciseId}`
          : `training-steps/${trainingStepId}/exercises`,
        data: {
          exercise_id: currentExercise.id,
          comments: values.comments,
          observations: values.observations,
        },
      });
      setIsLoading(false);
      setExerciseSelected({ touched: false, value: null });
      navigation.navigate('ExercisesScreen', {
        routineId,
        routineDescription,
        trainingDayId,
        trainingDayDescription,
        trainingStepId,
        trainingStepDescription,
        refreshList: true,
      });
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setFormError(error.response.data.errors[0]);
      } else {
        setFormError(GENERIC_ERROR_MESSAGE);
      }
      setIsLoading(false);
    }
  };

  const onExerciseConfirm = (value) => {
    setExerciseSelected({ touched: true, value });
    setIsExercisesModalVisible(false);
  };

  const exerciseStyle = { color: currentExercise ? '#3f3a40' : 'gray' };

  return (
    <KeyboardAvoidingView behavior={'height'} style={{ flex: 1 }}>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              {trainingStepExerciseId ? 'Editar' : 'Crear'} ejercicio
            </Text>
            <View style={globalStyles.headerEmptyIcon} />
          </View>
          <Breadcrumb routes={breadcrumbRoutes} />
          <View style={[globalStyles.formContainer, !isMediumScreen && globalStyles.formContainerMobile]}>
            <Formik
              innerRef={formikForm}
              validationSchema={formTrainingStepExerciseSchema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
                <>
                  {!!formError && (
                    <View style={globalStyles.errorContainer}>
                      <CustomErrors msg={formError} />
                    </View>
                  )}
                  {!isLoading ? (
                    <>
                      <View style={{ width: '100%' }}>
                        <StockExercisesFilters
                          exercises={exercises}
                          setExercises={setFilteredExercises}
                          setIsLoading={setIsLoading}
                          filtersSelected={filtersSelected}
                          setFiltersSelected={setFiltersSelected}
                        />
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          setIsExercisesModalVisible(true);
                          setExerciseSelected({ touched: true, value: exerciseSelected.value });
                        }}
                        style={[globalStyles.textInput, globalStyles.justifyContentCenter, outlineStyle]}
                      >
                        <Text style={[globalStyles.normalText, exerciseStyle]}>
                          {currentExercise?.descripcion || 'Seleccionar un ejercicio'}
                        </Text>
                      </TouchableOpacity>
                      {!isExercisesModalVisible && exerciseSelected.touched && !exerciseSelected.value && (
                        <Text style={globalStyles.inputErrorText}>El campo ejercicio es obligatorio</Text>
                      )}
                      <TextInput
                        style={[globalStyles.textInput, globalStyles.marginTop20, outlineStyle]}
                        name="comments"
                        placeholder="Volumen, intensidad, descanso, RIR..."
                        onChangeText={handleChange('comments')}
                        onBlur={handleBlur('comments')}
                        value={values.comments}
                      />
                      {errors.comments && touched.comments && (
                        <Text style={globalStyles.inputErrorText}>{errors.comments}</Text>
                      )}
                      <TextInput
                        style={[globalStyles.textInput, globalStyles.textArea, outlineStyle]}
                        name="observations"
                        placeholder="Observaciones"
                        onChangeText={handleChange('observations')}
                        onBlur={handleBlur('observations')}
                        value={values.observations}
                        multiline
                        textAlignVertical="top"
                      />
                      {errors.observations && touched.observations && (
                        <Text style={globalStyles.inputErrorText}>{errors.observations}</Text>
                      )}
                      <CustomButton
                        title={trainingStepExerciseId ? 'Actualizar' : 'Crear'}
                        buttonColor="#bbebeb"
                        textColor="#3f3a40"
                        marginTop={30}
                        alignSelf="center"
                        onPress={handleSubmit}
                        disabled={!isValid || isLoading || !exerciseSelected.value}
                      />
                    </>
                  ) : (
                    <View style={globalStyles.loadingContainer}>
                      <Spinner />
                    </View>
                  )}
                </>
              )}
            </Formik>
          </View>
        </View>
      </ScrollView>
      <SearchableDropDown
        items={exercisesDropDown}
        onConfirm={onExerciseConfirm}
        onDismiss={() => setIsExercisesModalVisible(false)}
        visible={isExercisesModalVisible}
      />
    </KeyboardAvoidingView>
  );
};
