import React, { useState, useEffect, useCallback } from 'react';
import { Text, View } from 'react-native';
import { Card } from 'react-native-elements';
import useMediumScreen from 'hooks/useMediumScreen';
import { getExercises } from 'services/exerciseService';
import StockExercisesFilters from 'components/StockExercisesFilters';
import CardExercise from 'components/CardExercise';
import Spinner from 'components/Spinner';
import { allWordsIncluded } from 'utils/search';
import { globalStyles } from 'styles/global';

export default ({ isLoadingMore = false, page }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [filtersSelected, setFiltersSelected] = useState({});
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [search, setSearch] = useState('');
  const isMediumScreen = useMediumScreen();

  const getExercisesCallback = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getExercises();
      setExercises(data.exercises);
      setFiltersSelected({});
      setFilteredExercises(data.exercises);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getExercisesCallback();
  }, [getExercisesCallback]);

  const items = filteredExercises.filter((exercise) =>
    allWordsIncluded(search.toLowerCase().trim().split(' '), exercise.descripcion.toLowerCase()),
  );

  const moreItems = Math.min(page * 3, items.length);
  const itemsPaginated = items.slice(0, moreItems);
  const noMoreItems = moreItems === items.length;

  return (
    <>
      <StockExercisesFilters
        exercises={exercises}
        setExercises={setFilteredExercises}
        setIsLoading={setIsLoading}
        filtersSelected={filtersSelected}
        setFiltersSelected={setFiltersSelected}
        search={search}
        setSearch={setSearch}
      />
      <Card containerStyle={globalStyles.marginTop40}>
        <Card.Title style={[globalStyles.normalText, { fontSize: 18 }]}>Ejercicios</Card.Title>
        <Card.Divider />
        {isLoading ? (
          <View style={globalStyles.loadingContainer}>
            <Spinner />
          </View>
        ) : (
          <View style={[globalStyles.tableContainer, !isMediumScreen && globalStyles.mobileTableContainer]}>
            {itemsPaginated.map((exercise) => (
              <CardExercise exercise={exercise} key={'card-exercise-' + exercise.id} />
            ))}
            {isLoadingMore && !noMoreItems && <Text style={globalStyles.normalText}>Cargando más ejercicios...</Text>}
            {itemsPaginated.length === 0 && <Text style={globalStyles.normalText}>No se encontraron ejercicios.</Text>}
          </View>
        )}
      </Card>
    </>
  );
};
