import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { DataTable } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import appContext from 'contexts/appContext';
import Breadcrumb from 'components/Breadcrumb';
import Spinner from 'components/Spinner';
import CustomTable from 'components/CustomTable';
import ConfirmationModal from 'components/ConfirmationModal';
import CustomButton from 'components/CustomButton';
import { getDescriptionName } from 'utils/parseDescriptionName';
import { reSortItemsByOrder, getRequestToReOrder } from 'helpers/utils';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/trainingSteps';

export default ({ navigation, route }) => {
  const { routineId, routineDescription, trainingDayId, trainingDayDescription, refreshList, routineIsGeneric } =
    route.params;
  const [trainingSteps, setTrainingSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trainingStepIdSelected, setTrainingStepIdSelected] = useState(null);
  const [showSaveOrderBtn, setShowSaveOrderBtn] = useState(false);
  const { userData } = useContext(appContext);

  const isMediumScreen = useMediumScreen();

  const breadcrumbRoutes = [
    {
      label: routineDescription,
      onPress: () => {
        navigation.navigate('RoutinesListScreen', {});
      },
    },
    {
      label: trainingDayDescription,
      onPress: () => {
        navigation.navigate('TrainingDaysListScreen', {
          routineId,
          routineDescription,
          refreshList: true,
          routineIsGeneric,
        });
      },
    },
    {
      label: 'Partes de entrenamiento',
      onPress: () => {},
    },
  ];

  useEffect(() => {
    getTrainingSteps();
  }, [getTrainingSteps, trainingDayId]);

  useEffect(() => {
    !!refreshList && getTrainingSteps();
  }, [getTrainingSteps, route]);

  const canDoActions = () => {
    if (!routineIsGeneric) {
      //routineIsGeneric solamente se setea cuando la rutina es genérica
      // entonces si no se setea significa que la rutina tiene dueño
      return true;
    }
    return parseInt(routineIsGeneric, 10) === userData?.user?.id;
  };

  const getIconColor = () => {
    return canDoActions() ? '#f2a172' : '#b9aeae';
  };

  const getColumnTitles = () => {
    const titles = ['Descripción', 'Acciones'];

    if (canDoActions()) {
      titles.push('Orden');
    }

    return titles;
  };

  const getTrainingSteps = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: `training-days/${trainingDayId}/training-steps`,
      });
      setTrainingSteps(data.trainingSteps);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [trainingDayId]);

  const copyTrainingStep = async (trainingStepId) => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: `training-days/${trainingDayId}/training-steps/${trainingStepId}/edit`,
      });
      setTrainingSteps([...trainingSteps, data.trainingStep]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showConfirmationModal = (trainingStepId) => {
    setTrainingStepIdSelected(trainingStepId);
    setIsModalVisible(true);
  };

  const deleteRoutineById = async (trainingStepId) => {
    try {
      setIsModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: `training-days/${trainingDayId}/training-steps/${trainingStepId}`,
      });
      const newRoutines = trainingSteps.filter((trainingStep) => trainingStep.id !== trainingStepId);
      setTrainingSteps(newRoutines);
    } catch (error) {
      console.log(error);
    } finally {
      setTrainingStepIdSelected(null);
      setIsLoading(false);
    }
  };

  const reSortTrainingSteps = (trainingStepId) => {
    const index = trainingSteps.findIndex((trainingStep) => trainingStep.id === trainingStepId);
    const newTrainingSteps = reSortItemsByOrder(trainingSteps, index);
    setShowSaveOrderBtn(true);
    setTrainingSteps(newTrainingSteps);
  };

  const saveTrainingStepExercisesOrder = async () => {
    const request = getRequestToReOrder(trainingSteps);
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'post',
        url: `training-days/${trainingDayId}/training-steps/re-order`,
        data: { trainingSteps: request },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setShowSaveOrderBtn(false);
    }
  };

  const renderTrainingStepsRow = (trainingStep) => (
    <DataTable.Row key={`training-step-${trainingStep.id}`}>
      <DataTable.Cell
        style={[
          globalStyles.tableCell,
          { justifyContent: 'flex-start', paddingLeft: isMediumScreen ? 200 : 0, minWidth: 40 },
        ]}
      >
        <TouchableOpacity
          style={styles.iconAndDescriptionContainer}
          onPress={() => {
            navigation.navigate('ExercisesScreen', {
              routineId,
              routineDescription,
              trainingDayId,
              trainingDayDescription,
              trainingStepId: trainingStep.id,
              trainingStepDescription: trainingStep.descripcion,
              refreshList: true,
              routineIsGeneric,
            });
          }}
        >
          <Icon name={'fitness-center'} size={22} style={{ alignSelf: 'flex-start' }} color="#f2a172" />
          <Text style={globalStyles.tableText}>{getDescriptionName(trainingStep.descripcion, 14)}</Text>
        </TouchableOpacity>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('EditTrainingPartFormScreen', {
              routineId,
              routineDescription,
              trainingDayDescription,
              trainingStepId: trainingStep.id,
              trainingDayId: trainingDayId,
              trainingStepDescription: trainingStep.descripcion,
              trainingStepObservations: trainingStep.observations,
            });
          }}
          disabled={!canDoActions()}
        >
          <Icon name="edit" size={22} color={getIconColor()} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => copyTrainingStep(trainingStep.id)} disabled={!canDoActions()}>
          <Icon name="file-copy" size={22} color={getIconColor()} style={globalStyles.tableIcon} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => showConfirmationModal(trainingStep.id)} disabled={!canDoActions()}>
          <Icon name="delete" size={22} color={getIconColor()} style={globalStyles.tableIcon} />
        </TouchableOpacity>
        {isMediumScreen && (
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ExercisesScreen', {
                routineId,
                routineDescription,
                trainingDayId,
                trainingDayDescription,
                trainingStepId: trainingStep.id,
                trainingStepDescription: trainingStep.descripcion,
                refreshList: true,
              });
            }}
            disabled={!canDoActions()}
          >
            <Icon name="visibility" size={22} color={getIconColor()} style={globalStyles.tableIcon} />
          </TouchableOpacity>
        )}
      </DataTable.Cell>
      {canDoActions() ? (
        <DataTable.Cell style={globalStyles.tableCell}>
          {trainingStep.order !== 1 ? (
            <TouchableOpacity onPress={() => reSortTrainingSteps(trainingStep.id)} style={{ alignItems: 'center' }}>
              <Icon name="arrow-upward" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
          ) : null}
        </DataTable.Cell>
      ) : null}
    </DataTable.Row>
  );

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Partes de entrenamiento
            </Text>
            <TouchableOpacity disabled={isLoading} onPress={getTrainingSteps}>
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          <Breadcrumb routes={breadcrumbRoutes} />
          {isLoading ? (
            <View style={globalStyles.loadingContainer}>
              <Spinner />
            </View>
          ) : (
            <View style={globalStyles.tableContainer}>
              {canDoActions() ? (
                <View style={[globalStyles.contentCenteredRow, globalStyles.marginTop10]}>
                  <Text style={[globalStyles.tableTitle, globalStyles.marginTop0, globalStyles.marginRight10]}>
                    Crear una nueva parte de entrenamiento
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate('CreateTrainingPartFormScreen', {
                        routineId,
                        routineDescription,
                        trainingDayId,
                        trainingDayDescription,
                      });
                    }}
                  >
                    <Icon name="add-circle" size={22} color="#f2a172" />
                  </TouchableOpacity>
                </View>
              ) : null}

              {showSaveOrderBtn && (
                <View style={globalStyles.contentCenteredRow}>
                  <CustomButton
                    title={'Guardar orden'}
                    buttonColor="#bbebeb"
                    textColor="#3f3a40"
                    marginTop={25}
                    onPress={() => saveTrainingStepExercisesOrder()}
                  />
                </View>
              )}
              <CustomTable
                titles={getColumnTitles()}
                rows={trainingSteps}
                renderRow={renderTrainingStepsRow}
                isLoading={isLoading}
              />
            </View>
          )}
        </View>
      </ScrollView>
      <ConfirmationModal
        visible={isModalVisible}
        title="Atención"
        description="¿Estás seguro de eliminar la parte de entrenamiento?"
        onDismiss={() => setIsModalVisible(false)}
        onConfirm={() => deleteRoutineById(trainingStepIdSelected)}
      />
    </>
  );
};
