import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Avatar, Icon, ListItem } from 'react-native-elements';
import { Pressable } from 'react-native';
import { ScrollView, View, Text, TouchableOpacity, SafeAreaView, Platform } from 'react-native';
import moment from 'moment';
import appContext from 'src/contexts/appContext';
import useMediumScreen from 'hooks/useMediumScreen';
import { globalStyles } from 'styles/global';
import { styles } from 'src/styles/screens/private/notificationsList';
import { getNotifications, updateStatus, markAllAsReaded } from 'src/services/notificationService';
import DefaultUserImage from 'assets/default-user-image.png';
import 'moment/locale/es';
import Spinner from 'src/components/Spinner';
import { BASE_URL } from '@env';

const Notifications = ({ navigation }) => {
  const isWeb = Platform.OS === 'web';
  const isMediumScreen = useMediumScreen();
  const { userData, setContactChat, notifications, setNotifications, setHasNotifications } = useContext(appContext);
  const [isLoading, setIsLoading] = useState(false);
  const stopLoading = () => setIsLoading(false);
  const startLoading = () => setIsLoading(true);
  const getMyNotifications = useCallback(async () => {
    if (!userData.user) {
      return;
    }
    startLoading();
    const userNotifications = await getNotifications();
    if (userNotifications) {
      setNotifications(userNotifications);
    }
    stopLoading();
  }, [userData.user]);
  const markAsReaded = () => {
    markAllAsReaded();
    setHasNotifications(false);
    getMyNotifications();
  };

  useEffect(() => {
    getMyNotifications();
  }, [getMyNotifications]);

  moment.locale('es');
  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={[styles.headerContainer, isWeb && globalStyles.contentCenteredRow]}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[isWeb ? globalStyles.title : styles.title, !isMediumScreen && globalStyles.titleMobile]}>
              Notificaciones
            </Text>
          </View>
          <View style={isMediumScreen ? styles.webWrapper : styles.wrapper}>
            {notifications.length > 0 && (
              <Pressable onPress={markAsReaded}>
                <Text style={styles.asReadButton}>Marcar todas como leídas</Text>
              </Pressable>
            )}
            {isLoading ? (
              <View style={globalStyles.loadingContainer}>
                <Spinner />
              </View>
            ) : (
              notifications.map((el) => (
                <TouchableOpacity
                  key={el.id}
                  onPress={() => {
                    if (el.notification_type_id === 1) {
                      setContactChat(el.chat_person);
                      navigation.navigate('Mensajes', {
                        screen: 'ChatScreen',
                        params: { contactId: el.chat_person.id },
                      });
                    } else {
                      navigation.navigate('Noticias', {
                        screen: 'PublicationDetailScreen',
                        params: { publicationId: JSON.parse(el.payload).referenceId },
                      });
                    }
                    updateStatus(el.id);
                  }}
                >
                  <ListItem bottomDivider pad={8}>
                    <Avatar
                      rounded
                      size={50}
                      source={el.chat_person?.image ? { uri: BASE_URL + el.chat_person?.image } : DefaultUserImage}
                    />
                    <ListItem.Content>
                      <ListItem.Title style={[el.readed ? styles.normalText : styles.boldText]}>
                        {el.title}
                      </ListItem.Title>
                      <View style={styles.subtitleContainer}>
                        <ListItem.Subtitle
                          style={[styles.normalText, styles.message, { fontWeight: el.readed ? 'normal' : 'bold' }]}
                        >
                          {el.message}
                        </ListItem.Subtitle>
                        <ListItem.Subtitle style={[styles.time, styles.normalText]}>
                          {moment(el.created_at).fromNow().toLocaleLowerCase()}
                        </ListItem.Subtitle>
                      </View>
                    </ListItem.Content>
                  </ListItem>
                </TouchableOpacity>
              ))
            )}

            {notifications.length === 0 && !isLoading && (
              <Text style={[globalStyles.marginTop10, styles.normalText, styles.notNotifications]}>
                Aún no tienes notificaciones.
              </Text>
            )}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Notifications;
