import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  iconAndDescriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    minWidth: 100,
  },
});
