import * as React from 'react';
import { View, TouchableOpacity, Text, Image, Platform, Linking } from 'react-native';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/components/acceptedInvitation';
import { BASE_URL } from '@env';

const AcceptedInvitation = (props) => {
  const { message, navigation } = props;
  const appStoreImage = BASE_URL + 'files/app_store.png';
  const playStoreImage = BASE_URL + 'files/play_store.png';
  const appStoreUrl = 'https://apps.apple.com/kh/app/met/id1559791794?l=es';
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.doublepoint.trainerbuilder&hl=es_AR&gl=US';
  const instructionsUrl = 'https://mettheapp.com/step-by-step-alumno/';

  const openUrl = (url) => {
    if (Platform.OS === 'web') {
      window.open(url, '_blank');
    } else {
      Linking.openURL(url);
    }
  };

  return (
    <View>
      <Text style={[globalStyles.successFormText, styles.message]}>{message}</Text>
      <View style={styles.downloadContainer}>
        <Text style={styles.downloadTitle}>¡Descarga nuestra aplicación!</Text>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity onPress={() => openUrl(playStoreUrl)}>
            <Image source={{ uri: playStoreImage }} style={styles.storeImage} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openUrl(appStoreUrl)}>
            <Image source={{ uri: appStoreImage }} style={styles.storeImage} />
          </TouchableOpacity>
        </View>
      </View>
      <View>
        <Text style={styles.webAppContainer}>
          O entrena desde la{' '}
          <Text style={styles.webAppText} onPress={() => navigation.navigate('Main', { screen: 'Login' })}>
            web app
          </Text>
          .
        </Text>
      </View>
      <View style={styles.loginButtonContainer}>
        <Text style={styles.instructionsContainer}>
          <Text style={styles.loginButton} onPress={() => openUrl(instructionsUrl)}>
            Descubre{' '}
          </Text>
          lo fácil que es entrenar con la app
        </Text>
      </View>
    </View>
  );
};

export default AcceptedInvitation;
