import * as yup from 'yup';

export default () =>
  yup.object().shape({
    password: yup
      .string()
      .min(8, ({ min }) => `Debe tener al menos ${min} caracteres`)
      .required('El campo contraseña nueva es obligatorio'),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'La contraseña debe coincidir')
      .required('El campo confirmar contraseña nueva es obligatorio'),
  });
