import React, { useState, useContext, useEffect, useCallback } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { Switch } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import axiosInstance from 'services/axios';
import appContext from 'contexts/appContext';
import useMediumScreen from 'hooks/useMediumScreen';
import Breadcrumb from 'components/Breadcrumb';
import Spinner from 'components/Spinner';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/studentTrainingDays';

export default ({ navigation, route }) => {
  const { routineId, routineDescription } = route.params;
  const { userData } = useContext(appContext);
  const [trainingDays, setTrainingDays] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isMediumScreen = useMediumScreen();

  const breadcrumbRoutes = [
    {
      label: routineDescription,
      onPress: () => {
        navigation.navigate('RoutinesListScreen', {});
      },
    },
    {
      label: 'Días de entrenamiento',
      onPress: () => {},
    },
  ];

  useEffect(() => {
    getTrainingDays();
  }, [getTrainingDays, routineId]);

  const updateTrainingStepStatus = async (trainingStep) => {
    try {
      await axiosInstance({
        method: 'put',
        url: `/user/${userData?.user?.id}/training-steps/set-complete`,
        data: trainingStep,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateTrainingDayStatus = async (id, completed) => {
    try {
      await axiosInstance({
        method: 'put',
        url: `/training-days/${id}/set-complete`,
        data: {
          completed,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkTrainingDayIsComplete = (trainingDay) => {
    let incompleteTrainingStepsTotal = 0;
    const incomplete = trainingDay.training_steps.filter(({ completed }) => completed === false).length;
    incompleteTrainingStepsTotal += incomplete;
    //Si la cantidad de partes de entrenamiento incompletas son 0 significa que el día se marca como completa
    return incompleteTrainingStepsTotal === 0;
  };

  const findTrainingDay = (dayId) => {
    const result = trainingDays.find((day) => day.id === dayId);
    return result;
  };

  const getTrainingDays = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: 'routines/' + routineId + '/training-days',
      });
      data.trainingDays.forEach((day) => {
        day.isOpen = false;
      });
      setTrainingDays(data.trainingDays);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [routineId]);

  const checkCompletedTrainingDay = (dayId, completed) => {
    let day = findTrainingDay(dayId);
    day.completed = completed;
    if (completed === true) {
      day.training_steps.forEach((trainingStep) => (trainingStep.completed = true));
    }
    setTrainingDays([...trainingDays]);
  };

  const CompleteTrainingStepSwitch = ({ setDayIsCompleted, trainingDay, trainingStep }) => {
    const [isCompleted, setIsCompleted] = useState(trainingStep.completed);
    return (
      <View style={styles.trainingStepStatusContainer}>
        <Switch
          value={isCompleted}
          onValueChange={async () => {
            trainingStep.completed = !trainingStep.completed;
            setIsCompleted(!isCompleted);
            const dayResult = checkTrainingDayIsComplete(trainingDay);
            let day = findTrainingDay(trainingDay.id);
            if (day) {
              day.completed = dayResult;
            }
            setTrainingDays([...trainingDays]);
            updateTrainingStepStatus(trainingStep);
          }}
          style={styles.trainingStepStatusSwitch}
        />
      </View>
    );
  };

  const DayItem = ({ trainingDay }) => {
    const [dayIsCompleted, setDayIsCompleted] = useState(trainingDay.completed);

    return (
      <View style={isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile}>
        <TouchableOpacity
          onPress={() => {
            let day = findTrainingDay(trainingDay.id);
            if (day) {
              day.isOpen = !trainingDay.isOpen;
            }
            setTrainingDays([...trainingDays]);
          }}
          style={[styles.dayItemTouchable]}
        >
          <View style={styles.dayItemTitleContainer}>
            <Text style={styles.dayItemTitleText}>{trainingDay.day}</Text>
          </View>
          {trainingDay.training_steps.length > 0 ? (
            <View style={styles.trainingDaySwitchContainer}>
              <Switch
                value={dayIsCompleted}
                onValueChange={() => {
                  checkCompletedTrainingDay(trainingDay.id, !dayIsCompleted);
                  setDayIsCompleted(!dayIsCompleted);
                  updateTrainingDayStatus(trainingDay.id, !dayIsCompleted);
                }}
              />
            </View>
          ) : null}
        </TouchableOpacity>
        {trainingDay.isOpen && (
          <>
            {trainingDay.training_steps?.length > 0 ? (
              trainingDay.training_steps.map((trainingStep, index) => (
                <View key={index} style={styles.trainingStepContainer}>
                  <TouchableOpacity
                    style={styles.trainingStepTouchable}
                    onPress={() => {
                      navigation.navigate('StudentTrainingStepExercisesListScreen', {
                        routineId,
                        routineDescription,
                        trainingDayDescription: trainingDay.day,
                        trainingStepId: trainingStep.id,
                        trainingStepDescription: trainingStep.descripcion,
                        trainingStepObservations: trainingStep.observations,
                        refreshList: true,
                      });
                    }}
                  >
                    <View style={styles.trainingStepTitleContainer}>
                      <Text style={styles.trainingStepTitleText}>{trainingStep.descripcion}</Text>
                      <Icon name="chevron-right" color="black" size={30} />
                    </View>
                  </TouchableOpacity>
                  <CompleteTrainingStepSwitch
                    setDayIsCompleted={setDayIsCompleted}
                    trainingDay={trainingDay}
                    trainingStep={trainingStep}
                  />
                </View>
              ))
            ) : (
              <>
                <Text style={styles.noTrainingStepsText}>Este día no tiene partes de entrenamiento</Text>
              </>
            )}
          </>
        )}
      </View>
    );
  };

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Días de entrenamiento
            </Text>
            <TouchableOpacity disabled={isLoading} onPress={getTrainingDays}>
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          <Breadcrumb routes={breadcrumbRoutes} />
          {isLoading ? (
            <View style={globalStyles.loadingContainer}>
              <Spinner />
            </View>
          ) : (
            <View style={globalStyles.tableContainer}>
              {trainingDays.map((trainingDay, index) => (
                <View key={index} style={styles.daysContainer}>
                  <DayItem trainingDay={trainingDay} />
                </View>
              ))}
            </View>
          )}
        </View>
      </ScrollView>
    </>
  );
};
