import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loading: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: '#3f3a40',
    opacity: 0.8,
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  smallImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  cameraButtonContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  cameraButton: {
    width: 40,
    height: 40,
  },
});
