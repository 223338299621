import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    marginTop: 30,
  },
  containerForSafeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
  headerContainer: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 20,
  },
  error: {
    fontFamily: 'gilroy-regular',
    fontSize: 12,
    color: 'red',
    marginTop: 10,
    textAlign: 'center',
  },
  textContainer: {
    marginTop: 10,
  },
  text: {
    fontFamily: 'gilroy-regular',
    fontSize: 20,
    textAlign: 'center',
  },
  textMobile: {
    fontFamily: 'gilroy-regular',
    fontSize: 15,
  },
  bold: {
    fontFamily: 'gilroy-bold',
  },
  deleteAccountText: {
    marginTop: 20,
    color: '#f00',
    fontSize: 17,
    textAlign: 'center',
  },
  title: {
    fontFamily: 'gilroy-bold',
    fontSize: 24,
    textAlign: 'center',
    color: '#c33e1d',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    paddingRight: 45,
  },
});
