import React, { useState, useCallback, useContext } from 'react';
import { ScrollView, View, Text, TouchableOpacity, TextInput, KeyboardAvoidingView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { DataTable } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import { hasTrainerSubscription } from 'helpers/plans';
import Spinner from 'components/Spinner';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import ConfirmationModal from 'components/ConfirmationModal';
import RoutinePreviewModal from 'components/RoutinePreviewModal';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/students';
import { outlineStyle } from 'styles/global';

export default ({ navigation, route }) => {
  const [routines, setRoutines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteRoutineModalVisible, setIsDeleteRoutineModalVisible] = useState(false);
  const [isDeleteFolderModalVisible, setIsDeleteFolderModalVisible] = useState(false);
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [isRoutinePreviewModalVisible, setIsRoutinePreviewModalVisible] = useState(false);
  const [routineSelected, setRoutineSelected] = useState(null);
  const [routineGenericSelected, setRoutineGenericSelected] = useState(null);
  const [searchRoutine, setSearchRoutine] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState(null);

  const { userData } = useContext(appContext);

  const isMediumScreen = useMediumScreen();

  const hasActiveSubscription = hasTrainerSubscription(userData);

  useFocusEffect(
    useCallback(() => {
      if (route?.params?.folderId) {
        setSelectedFolderId(route.params.folderId);
        getRoutines(route?.params?.folderId);
      }
      return () => setSelectedFolderId(null);
    }, [route?.params?.folderId]),
  );

  const findRoutine = (routine, handleSearch) => {
    return routine.descripcion.toLowerCase().includes(handleSearch.toLowerCase());
  };

  const filteredMyRoutines = routines.filter((routine) => findRoutine(routine, searchRoutine));

  const renderFolderOrRoutineFolder = (item) => {
    if (item.hasOwnProperty('folder_id')) {
      return renderRoutineRow(item);
    }
  };

  const getRoutines = useCallback(async (folderId) => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: `/user/folders/${folderId}/routines`,
      });
      setRoutines(data.routines);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteFolderById = async (folderId) => {
    try {
      setIsDeleteRoutineModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: 'user/folders/' + folderId,
      });
      navigation.navigate('RoutinesListScreen', {
        refreshList: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleteFolderModalVisible(false);
      setIsLoading(false);
    }
  };

  const onRoutinePreview = async (routine) => {
    try {
      const { data } = await axiosInstance({
        method: 'get',
        url: `routines/${routine.id}/training-days?isPreview=true`,
      });
      routine.trainingDays = data.trainingDays;
      setRoutineSelected(routine);
      setIsRoutinePreviewModalVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteConfirmationModal = (routine) => {
    setRoutineSelected(routine);
    setIsDeleteRoutineModalVisible(true);
  };

  const showCopyConfirmationModal = (routine) => {
    if (hasActiveSubscription) {
      setRoutineGenericSelected(routine);
      setIsCopyModalVisible(true);
    }
  };

  const deleteRoutineById = async (routineId) => {
    try {
      setIsDeleteRoutineModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: 'routines/' + routineId,
      });
      getRoutines(selectedFolderId);
    } catch (error) {
      console.log(error);
    } finally {
      setRoutineSelected(null);
      setIsLoading(false);
    }
  };

  const copyRoutineGeneric = async (routine) => {
    try {
      setIsCopyModalVisible(false);
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'post',
        url: 'routines/create',
        data: { routineId: routine.id },
      });
      navigation.navigate('RoutinesListScreen', { refreshList: true });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setRoutineGenericSelected(null);
    }
  };

  const itemIsARoutine = (item) => item.hasOwnProperty('folder_id');
  const parseDescriptionName = (description) => `${description.substr(0, 16)}...`;
  const getDescriptionName = (item) => {
    const name = itemIsARoutine(item) ? item.descripcion : item.description;
    if (isMediumScreen) {
      return name;
    } else {
      if (name.length > 16) return parseDescriptionName(name);
      return name;
    }
  };

  const renderRoutineRow = (routine) => (
    <DataTable.Row key={`routine-${routine.id}`}>
      <DataTable.Cell
        style={[
          globalStyles.tableCell,
          { justifyContent: 'flex-start', paddingLeft: isMediumScreen ? 200 : 0, minWidth: 65 },
        ]}
      >
        <View style={styles.iconAndDescriptionContainer}>
          <Icon name="fitness-center" size={22} style={{ alignSelf: 'flex-start' }} color="#f2a172" />
          <View style={{ flex: 1 }}>
            <Text
              onPress={() => {
                if (hasActiveSubscription) {
                  navigation.navigate('TrainingDaysListScreen', {
                    routineId: routine.id,
                    routineDescription: routine.descripcion,
                    refreshList: true,
                    routineIsGeneric: userData.user.id !== routine.user_id,
                  });
                }
              }}
              style={[globalStyles.tableText, { paddingLeft: 10, width: '100%' }]}
            >
              {getDescriptionName(routine)}
            </Text>
          </View>
        </View>
      </DataTable.Cell>
      <DataTable.Cell style={[globalStyles.tableCell]}>
        {hasActiveSubscription ? (
          <>
            <TouchableOpacity onPress={() => showCopyConfirmationModal(routine)}>
              <Icon name="file-copy" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onRoutinePreview(routine)}>
              <Icon name="preview" size={22} color="#f2a172" style={globalStyles.tableIcon} />
            </TouchableOpacity>
            {routine.user_id ? (
              <>
                <Icon
                  name="edit"
                  size={22}
                  color="#f2a172"
                  onPress={() => {
                    navigation.navigate('EditRoutineFormScreen', {
                      id: routine.id,
                      description: routine.descripcion,
                      folderId: selectedFolderId,
                    });
                  }}
                />
                {isMediumScreen && (
                  <Icon
                    name="visibility"
                    size={22}
                    color="#f2a172"
                    style={globalStyles.tableIcon}
                    onPress={() => {
                      navigation.navigate('TrainingDaysListScreen', {
                        routineId: routine.id,
                        routineDescription: routine.descripcion,
                        refreshList: true,
                      });
                    }}
                  />
                )}
                <TouchableOpacity onPress={() => showDeleteConfirmationModal(routine)}>
                  <Icon name="delete" size={22} color="#f2a172" style={globalStyles.tableIcon} />
                </TouchableOpacity>
              </>
            ) : null}
          </>
        ) : (
          <Text style={globalStyles.normalText}>No disponible</Text>
        )}
      </DataTable.Cell>
    </DataTable.Row>
  );

  return (
    <KeyboardAvoidingView behavior={'height'} style={{ flex: 1 }}>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Programaciones
            </Text>
            <TouchableOpacity
              disabled={isLoading}
              onPress={() => {
                getRoutines(selectedFolderId);
              }}
            >
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          {isLoading ? (
            <View style={globalStyles.loadingContainer}>
              <Spinner />
            </View>
          ) : (
            <>
              <View style={styles.tableContainer}>
                <View
                  style={[
                    styles.searchInputContainer,
                    isMediumScreen ? styles.searchWebContainer : styles.searchMobileContainer,
                  ]}
                >
                  <TextInput
                    onChangeText={setSearchRoutine}
                    placeholder="Buscar  carpetas o programaciones..."
                    style={[styles.searchInput, outlineStyle]}
                    value={searchRoutine}
                  />
                </View>
                <CustomTable
                  titles={['Descripción', 'Acciones']}
                  rows={filteredMyRoutines}
                  renderRow={renderFolderOrRoutineFolder}
                />

                {routines.length === 0 && (
                  <>
                    <CustomButton
                      title={'Eliminar carpeta'}
                      buttonColor="#bbebeb"
                      textColor="#3f3a40"
                      marginTop={25}
                      alignSelf="center"
                      onPress={() => setIsDeleteFolderModalVisible(true)}
                    />
                  </>
                )}
              </View>
            </>
          )}
        </View>
      </ScrollView>
      {isRoutinePreviewModalVisible && routineSelected && (
        <RoutinePreviewModal
          visible={isRoutinePreviewModalVisible}
          routine={routineSelected}
          onDismiss={() => setIsRoutinePreviewModalVisible(false)}
        />
      )}
      <ConfirmationModal
        visible={isDeleteRoutineModalVisible}
        title="Atención"
        description="¿Estás seguro de eliminar la programación?"
        onDismiss={() => setIsDeleteRoutineModalVisible(false)}
        onConfirm={() => deleteRoutineById(routineSelected.id)}
      />
      <ConfirmationModal
        visible={isDeleteFolderModalVisible}
        title="Atención"
        description="¿Estás seguro de eliminar la carpeta?"
        onDismiss={() => setIsDeleteFolderModalVisible(false)}
        onConfirm={() => deleteFolderById(selectedFolderId)}
      />
      <ConfirmationModal
        visible={isCopyModalVisible}
        title={routineGenericSelected?.descripcion}
        description={'Estás a punto de generar una copia de esta programación, desea continuar?'}
        onDismiss={() => setIsCopyModalVisible(false)}
        onConfirm={() => copyRoutineGeneric(routineGenericSelected)}
      />
    </KeyboardAvoidingView>
  );
};
