import React from 'react';
import { Button } from 'react-native-elements';
import { styles } from 'styles/components/customButton';

export default ({
  title,
  buttonColor = '#3f3a40',
  width = 170,
  height = 40,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  alignSelf,
  onPress,
  disabled,
  textColor = 'white',
  fontSize = 14,
  marginVertical,
}) => {
  const customButtonStyle = {
    width,
    height,
    background: buttonColor,
    backgroundColor: buttonColor,
    marginLeft,
    marginBottom,
    marginTop,
    marginRight,
    alignSelf,
    marginVertical,
  };

  const customTextStyle = {
    color: textColor,
    fontSize,
  };

  return (
    <Button
      title={title}
      type="clear"
      buttonStyle={[styles.buttonStyle, customButtonStyle]}
      titleStyle={[styles.buttonTextStyle, customTextStyle]}
      onPress={onPress}
      disabled={disabled}
    />
  );
};
