import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  title: {
    fontFamily: 'gilroy-bold',
    fontSize: 14,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  buttonsContainerMobile: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  stockTitle: {
    marginTop: 10,
  },
});
