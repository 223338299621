import React, { useEffect, useCallback } from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import GoogleImage from 'assets/google.png';
import { styles } from 'styles/forms/formLogin';
import { globalStyles } from 'styles/global';
import useGoogleAuth from '../hooks/useGoogleAuth';

WebBrowser.maybeCompleteAuthSession();

const SocialContainer = (props) => {
  const { isSubmitting, onSubmit } = props;
  const { googleResponse, googlePromptAsync } = useGoogleAuth();
  const socialLogin = googleResponse;

  useEffect(() => {
    isSocialAuthCallback();
  }, [socialLogin, isSocialAuthCallback]);

  const isSocialAuth = async () => {
    if (!isSubmitting && socialLogin) {
      onSubmit(socialLogin);
    }
  };

  const isSocialAuthCallback = useCallback(isSocialAuth, [socialLogin, onSubmit, isSubmitting]);

  return (
    <View style={styles.socialRegisterContainer}>
      <Text style={[globalStyles.normalText, styles.socialTitle]}>Únete a través de</Text>
      <View style={styles.socialLogoContainer}>
        <TouchableOpacity disabled={isSubmitting} onPress={googlePromptAsync}>
          <Image source={GoogleImage} style={styles.googleImageStyle} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SocialContainer;
