import * as React from 'react';
import { Image, Platform, TouchableOpacity, View } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { Badge, Icon } from 'react-native-elements';
import useMediumScreen from 'hooks/useMediumScreen';
import CustomButton from 'components/CustomButton';
import LogoImage from 'assets/logo.png';
import { styles } from 'styles/components/header';
import { getNotifications } from 'src/services/notificationService';
import appContext from 'src/contexts/appContext';

const Header = (props) => {
  const { navigation, userData } = props;
  const route = useRoute();
  const currentScreen = route?.params?.screen;
  const { setNotifications, hasNotifications, setHasNotifications } = React.useContext(appContext);

  const isWeb = Platform.OS === 'web';
  const isMediumScreen = useMediumScreen();

  const checkNotifications = async () => {
    try {
      if (Platform.OS === 'web') {
        return;
      }
      const userNotifications = await getNotifications();
      setNotifications(userNotifications);
      if (userNotifications.some((el) => el.readed === 0)) {
        setHasNotifications(true);
      } else {
        setHasNotifications(false);
      }
    } catch (error) {
      console.log('error checkNotifications(): ', JSON.stringify(error));
    }
  };

  React.useEffect(() => {
    if (userData) {
      checkNotifications();
      const interval = setInterval(checkNotifications, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  const renderButton = (title, screen, style = {}) => (
    <CustomButton
      title={title}
      buttonColor="#3f3a40"
      width={140}
      height={35}
      fontSize={14}
      {...style}
      onPress={() => navigation.navigate('Main', { screen })}
    />
  );

  const renderProfileIcon = () => {
    return (
      <TouchableOpacity onPress={() => navigation.navigate('ProfileScreen')}>
        <Icon name="person" size={22} color="#3f3a40" />
      </TouchableOpacity>
    );
  };
  const renderNotificationIcon = () => {
    if (Platform.OS === 'web') {
      return;
    }
    return (
      <TouchableOpacity onPress={() => navigation.navigate('Notifications')} style={styles.notificationIcon}>
        <Icon name="notifications" size={22} color={'#3f3a40'} type="material" />
        {hasNotifications && (
          <Badge status="success" badgeStyle={styles.badge} containerStyle={styles.badgeNotificationContainer} />
        )}
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        {((isWeb && isMediumScreen) || !isWeb) && <Image source={LogoImage} style={styles.logo} />}
      </View>
      <View style={[styles.sideContainer, styles.rightContainer]}>
        {!userData ? (
          <>
            {currentScreen !== 'Registrarse' &&
              renderButton('Comienza gratis', 'Registrarse', { marginRight: currentScreen !== 'Login' ? 5 : 0 })}
            {((isWeb && currentScreen !== 'Login') || (!isWeb && !!currentScreen && currentScreen !== 'Login')) &&
              renderButton('Iniciar sesión', 'Login')}
          </>
        ) : (
          <>
            {renderNotificationIcon()}
            {renderProfileIcon()}
          </>
        )}
      </View>
    </View>
  );
};

export default Header;
