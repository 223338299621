import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import CustomInput from 'components/CustomInput';
import { styles } from 'styles/screens/private/students';
import { globalStyles } from 'styles/global';

const Trainer = (props) => {
  const { item, deleteTrainer, updateTrainer } = props;
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateName, setUpdateName] = useState(item.name);
  const [updateEmail, setUpdateEmail] = useState(item.email);

  const onUpdateTrainer = () => {
    const success = updateTrainer(updateName, updateEmail, item.email);
    if (success) {
      setIsUpdating(!isUpdating);
    }
  };

  return (
    <>
      {isUpdating ? (
        <View style={styles.flexIcons}>
          <View style={styles.flexInputs}>
            <View>
              <CustomInput title="Nombre del entrenador" value={updateName} onChangeText={setUpdateName} />
            </View>
            <View>
              <CustomInput title="Email del entrenador" value={updateEmail} onChangeText={setUpdateEmail} />
            </View>
          </View>
          <View>
            <TouchableOpacity onPress={onUpdateTrainer}>
              <Icon name="save" size={22} color="#f2a172" />
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <View style={styles.flexIcons}>
          <View>
            <Text style={globalStyles.normalText}>
              {item.email.length > 18 ? item.email.slice(0, 18) + '...' : item.email}
            </Text>
          </View>
          <View>
            <View style={styles.flexIcons}>
              <TouchableOpacity onPress={onUpdateTrainer}>
                <Icon name="create" size={22} color="#f2a172" />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => deleteTrainer(item.email)} style={styles.icon}>
                <Icon name="delete" size={22} color="#f2a172" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

export default Trainer;
