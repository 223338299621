import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  buttonStyle: {
    borderRadius: 10,
    marginLeft: 10,
    marginBottom: 5,
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTextStyle: {
    fontFamily: 'gilroy-regular',
    lineHeight: 14,
  },
});
