import React, { useState, useCallback, useRef } from 'react';
import { ScrollView, View, Text, TextInput, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import { useFocusEffect } from '@react-navigation/native';
import { Formik } from 'formik';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import Breadcrumb from 'components/Breadcrumb';
import CustomErrors from 'components/CustomErrors';
import CustomButton from 'components/CustomButton';
import Spinner from 'components/Spinner';
import { globalStyles, outlineStyle } from 'styles/global';
import formTrainingDaySchema from './schemas/formTrainingDaySchema';

export default ({ navigation, route }) => {
  const { routineId, routineDescription, trainingDayId, trainingDayDescription } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const isMediumScreen = useMediumScreen();
  const formikForm = useRef(null);
  const initialValues = { day: trainingDayDescription };

  const breadcrumbRoutes = [
    {
      label: routineDescription,
      onPress: () => {
        navigation.navigate('RoutinesListScreen', {});
      },
    },
    {
      label: 'Días de entrenamiento',
      onPress: navigation.goBack,
    },
  ];

  useFocusEffect(
    useCallback(() => {
      return () => {
        formikForm.current.resetForm();
        setFormError('');
        setIsLoading(false);
      };
    }, []),
  );

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await axiosInstance({
        method: 'put',
        url: `routines/${routineId}/training-days/${trainingDayId}`,
        data: {
          day: values.day,
        },
      });
      setIsLoading(false);
      navigation.navigate('TrainingDaysListScreen', {
        routineId,
        routineDescription,
        refreshList: true,
      });
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setFormError(error.response.data.errors[0]);
      } else {
        setFormError(GENERIC_ERROR_MESSAGE);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Editar día de entrenamiento
            </Text>
            <View style={globalStyles.headerEmptyIcon} />
          </View>
          <Breadcrumb routes={breadcrumbRoutes} />
          <View style={[globalStyles.formContainer, !isMediumScreen && globalStyles.formContainerMobile]}>
            <Formik
              innerRef={formikForm}
              validationSchema={formTrainingDaySchema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
                <>
                  {!!formError && (
                    <View style={globalStyles.errorContainer}>
                      <CustomErrors msg={formError} />
                    </View>
                  )}
                  {!isLoading ? (
                    <>
                      <TextInput
                        style={[globalStyles.textInput, outlineStyle]}
                        name="day"
                        placeholder="Descripción"
                        onChangeText={handleChange('day')}
                        onBlur={handleBlur('day')}
                        value={values.day}
                      />
                      {errors.day && touched.day && <Text style={globalStyles.inputErrorText}>{errors.day}</Text>}
                      <CustomButton
                        title="Actualizar"
                        buttonColor="#bbebeb"
                        textColor="#3f3a40"
                        marginTop={30}
                        alignSelf="center"
                        onPress={handleSubmit}
                        disabled={!isValid || isLoading}
                      />
                    </>
                  ) : (
                    <View style={globalStyles.loadingContainer}>
                      <Spinner />
                    </View>
                  )}
                </>
              )}
            </Formik>
          </View>
        </View>
      </ScrollView>
    </>
  );
};
