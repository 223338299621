import React from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { styles } from 'styles/components/customImageButton';

export default ({ backgroundImage, isBigButton = false, onPress, renderText }) => (
  <View style={styles.buttonImageContainer}>
    <TouchableOpacity onPress={onPress} style={[styles.buttonContainer, isBigButton && styles.bigButtonContainer]}>
      <Image source={backgroundImage} style={styles.image} />
      <View style={styles.titleContainer}>{renderText()}</View>
    </TouchableOpacity>
  </View>
);
