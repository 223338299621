import React, { useState, useEffect, useCallback } from 'react';
import { View, Text } from 'react-native';
import * as Linking from 'expo-linking';
import AcceptedInvitation from 'components/AcceptedInvitation';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import axiosInstance from 'services/axios';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/auth/Verify';

export default ({ navigation }) => {
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [executed, setExecuted] = useState(false);
  const [verifyUrl, setVerifyUrl] = useState('');

  const getUrl = async () => {
    const initialUrl = await Linking.getInitialURL();
    setVerifyUrl(initialUrl.substring(initialUrl.indexOf('verify')));
  };

  useEffect(() => {
    getUrl();
  }, []);

  useEffect(() => {
    if (verifyUrl && !executed) {
      setExecuted(true);
      verifyEmailCallback();
    }
  }, [verifyUrl, executed, verifyEmailCallback]);

  const verifyEmailCallback = useCallback(async () => {
    try {
      setHasError(false);
      setMessage('');
      const { data } = await axiosInstance({ method: 'get', url: `email/${verifyUrl}` });
      setMessage(data.message);
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setMessage(error.response.data.errors[0]);
      } else {
        setMessage(GENERIC_ERROR_MESSAGE);
      }
      setHasError(true);
    }
  }, [verifyUrl]);

  return (
    <View style={styles.container}>
      {hasError ? (
        <Text style={[globalStyles.inputErrorText, styles.message]}>{message}</Text>
      ) : (
        <AcceptedInvitation message={message} navigation={navigation} />
      )}
    </View>
  );
};
