import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  containerMedium: {
    margin: 15,
  },
  container: {
    marginTop: 15,
  },
  picker: {
    borderRadius: 20,
    backgroundColor: '#ffffff',
  },
  pickerAndroidContainer: {
    fontFamily: 'gilroy-regular',
    color: '#3f3a40',
    backgroundColor: '#ffffff',
    marginHorizontal: 25,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#3f3a40',
  },
  pickerWeb: {
    borderRadius: 20,
    backgroundColor: '#ffffff',
    height: 30,
    width: 200,
  },
  pickerIOS: {
    flex: 1,
    paddingHorizontal: 10,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#3f3a40',
    height: 30,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  pickerAndroid: {
    marginHorizontal: 10,
  },
  modalContainer: {
    backgroundColor: 'white',
  },
});
