import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  firstContainer: {
    alignItems: 'center',
  },
  submitButton: {
    marginTop: 20,
  },
  formContainer: {
    width: '100%',
  },
  registerContainerSpinner: {
    height: 200,
  },
  appleButton: {
    width: '100%',
    height: 44,
    marginTop: 20,
  },
});
