import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  containerForSafeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
  webContainer: {
    width: '60%',
    alignSelf: 'center',
  },
  refreshIcon: {
    position: 'absolute',
    right: 10,
  },
  publicationItem: {
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 12,
    // },
    // shadowOpacity: 0.58,
    // shadowRadius: 16.0,

    // // elevation: 24,
    borderWidth: 1,
    borderColor: '#d4d4d4',
    overflow: 'hidden',
  },
  publicationImage: {
    width: '100%',
    height: 180,
  },
  publicationBody: {
    flex: 1,
    padding: 21,
    paddingBottom: 0,
  },
  publicationBodyItem: {
    marginBottom: 10,
  },
  publicationTitleText: {
    fontSize: 22,
    color: '#2e2e2e',
    fontFamily: 'gilroy-bold',
  },
  publicationContentText: {
    fontSize: 16,
    fontFamily: 'gilroy-regular',
  },
  publicationsHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    marginBottom: 15,
    marginTop: 20,
  },
  newPublicationContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  newPublicationText: {
    fontFamily: 'gilroy-semibold',
    color: '#000',
  },
  publicationsTitle: {
    textAlign: 'center',
    flex: 1,
    display: 'flex',
  },
  publicationsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  onlyMyPublicationsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    justifyContent: 'center',
  },
  onlyMyPublicationsText: {
    display: 'flex',
    justifyContent: 'center',
  },
  onlyMyPublicationsSwitch: {
    marginLeft: 10,
  },
  publicationActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 10,
  },
});
