import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  safeAreaViewStyle: {
    flex: 1,
  },
  webContainer: {
    width: '80%',
    alignSelf: 'center',
  },
  scrollViewPublicationDetail: {
    marginTop: 20,
    flex: 1,
  },
  contentContainerStyleScrollView: { flexGrow: 1 },
  publicationFormContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  publicationImagePreview: {
    width: '100%',
    height: 250,
  },
  publicationBody: {
    marginTop: 30,
    flex: 1,
    padding: 30,
    marginBottom: 30,
  },
  publicationBodyItem: {
    marginBottom: 10,
  },
  publicationTitleText: {
    fontSize: 22,
    fontWeight: 'bold',
    fontFamily: 'gilroy-regular',
  },
  publicationContentText: {
    fontSize: 15,
    fontFamily: 'gilroy-regular',
  },
  imageContainer: {
    marginTop: 15,
    width: '100%',
    height: 250,
  },
  fullHeight: { height: '100%' },
  webImageContainer: { height: 400 },
  hyperLinkText: { fontWeight: '600', textDecorationLine: 'underline' },
});
