import React, { useState } from 'react';
import { ScrollView, Text, View, Image, SafeAreaView, TouchableOpacity } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { Icon } from 'react-native-elements';
import axiosInstance from 'services/axios';
import { globalStyles } from 'styles/global';
import { styles } from 'src/styles/screens/private/publicationDetail';
import Spinner from 'src/components/Spinner';
import useMediumScreen from 'src/hooks/useMediumScreen';
import { BASE_URL } from '@env';

const PublicationDetail = (props) => {
  const { navigation, route } = props;
  const isMediumScreen = useMediumScreen();
  const [publicationImage, setPublicationImage] = useState(null);
  const [publication, setPublication] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    getPublication();
  }, [route]);

  const getPublication = async () => {
    if (!route?.params?.publicationId) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: '/publications/' + route.params.publicationId,
      });

      setPublication(data.publication);
      if (data.publication.image.startsWith('storage')) {
        setPublicationImage(BASE_URL + data.publication.image);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SafeAreaView style={[styles.safeAreaViewStyle]}>
      {isLoading ? (
        <View style={globalStyles.loadingContainer}>
          <Spinner />
        </View>
      ) : (
        <ScrollView
          style={styles.scrollViewPublicationDetail}
          contentContainerStyle={styles.contentContainerStyleScrollView}
          keyboardShouldPersistTaps="handled"
        >
          <View style={globalStyles.viewContainer}>
            <View style={globalStyles.headerContainer}>
              <TouchableOpacity
                disabled={isLoading}
                onPress={() => {
                  navigation.navigate('Noticias', {
                    screen: 'PublicationsScreen',
                  });
                }}
              >
                <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
              </TouchableOpacity>
              <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
                {publication?.title}
              </Text>
              <View style={globalStyles.headerEmptyIcon} />
            </View>
          </View>
          <View style={[isMediumScreen && styles.webContainer]}>
            {publicationImage && (
              <View style={[styles.imageContainer, isMediumScreen && styles.webImageContainer]}>
                <Image
                  resizeMode={isMediumScreen ? 'cover' : 'cover'}
                  source={{ uri: publicationImage }}
                  style={[styles.publicationImagePreview, isMediumScreen && styles.fullHeight]}
                />
              </View>
            )}
            <View style={[styles.publicationBody]}>
              <View style={styles.publicationBodyItem}>
                <Hyperlink linkDefault={true} linkStyle={styles.hyperLinkText}>
                  <Text style={styles.publicationContentText}>{publication?.content}</Text>
                </Hyperlink>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
};

export default PublicationDetail;
