import * as yup from 'yup';

export default () =>
  yup.object().shape({
    name: yup.string().required('El campo con su nombre y apellido es obligatorio'),
    email: yup.string().email('Ingrese un email válido').required('El campo email es obligatorio'),
    email_verified_at: yup
      .string()
      .oneOf([yup.ref('email'), null], 'El email debe coincidir')
      .required('La confirmación del email es obligatoria'),
    password: yup
      .string()
      .min(8, ({ min }) => `Debe tener al menos ${min} caracteres`)
      .required('El campo contraseña es obligatorio'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'La contraseña debe coincidir')
      .required('La confirmación de la contraseña es obligatoria'),
  });
