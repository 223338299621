import { StyleSheet } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  errorContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '90%',
  },
  errorText: {
    fontFamily: 'gilroy-bold',
    fontSize: 14,
    textAlign: 'center',
  },
  webView: {
    marginTop: getStatusBarHeight(),
  },
});
