import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  containerWeb: {
    width: '50%',
    maxHeight: '80%',
    alignSelf: 'center',
  },
  containerMobile: {
    width: '80%',
    maxHeight: '80%',
    alignSelf: 'center',
  },
  scrollContainer: {
    paddingHorizontal: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  text: {
    fontFamily: 'gilroy-regular',
    width: '90%',
    paddingLeft: 8,
  },
  searchInputContainer: {
    width: '100%',
    height: 50,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  searchInput: {
    fontFamily: 'gilroy-regular',
    color: '#3f3a40',
    fontSize: 20,
    borderColor: '#8a8a8a',
  },
});
