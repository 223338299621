import { StyleSheet } from 'react-native';
export const styles = StyleSheet.create({
  containerForSafeArea: {
    flex: 1,
    backgroundColor: 'white',
  },
  headerContainer: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 20,
  },
  title: {
    fontFamily: 'gilroy-bold',
    fontSize: 24,
    textAlign: 'center',
    color: '#c33e1d',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    paddingRight: 45,
  },

  buttonsContainer: {
    justifyContent: 'space-around',
    flexDirection: 'row',
    marginVertical: 15,
  },
});
