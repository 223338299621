import React, { useEffect, useState, useCallback, useContext } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { Card, Paragraph } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import axiosInstance from 'services/axios';
import { getExerciseMarks, storeMark } from 'src/services/markService';
import appContext from 'src/contexts/appContext';
import useMediumScreen from 'hooks/useMediumScreen';
import Breadcrumb from 'components/Breadcrumb';
import CardExercise from 'components/CardExercise';
import ResultsRoutineForm from 'components/ResultsRoutineForm';
import Spinner from 'components/Spinner';
import HistorialModal from 'src/components/HistorialModal';
import { globalStyles } from 'styles/global';
import Hyperlink from 'react-native-hyperlink';

export default ({ navigation, route }) => {
  const {
    routineId,
    routineDescription,
    trainingDayDescription,
    trainingStepId,
    trainingStepDescription,
    trainingStepObservations,
  } = route.params;
  const [isLoading, setIsLoading] = useState(false);
  const [trainingStepExercises, setTrainingStepExercises] = useState([]);
  const [filteredExercises, setFilteredExercises] = useState([]);
  const { userData } = useContext(appContext);
  const isMediumScreen = useMediumScreen();

  const [isResultsRoutineModalVisible, setIsResultsRoutineModalVisible] = useState(false);
  const [isHistorialModalVisible, setIsHistorialModalVisible] = useState(false);
  const [isTimeDetailsInputsVisible, setIsTimeDetailsInputsVisible] = useState(false);
  const [isOtraActividadInputsVisible, setIsOtraActividadInputsVisible] = useState(false);
  const [isNumberAvailableType, setIsNumberAvailableType] = useState(false);
  const [isNumberAvailableMeasure, setIsNumberAvailableMeasure] = useState(false);
  const [isOtherMeasureLabelVisible, setIsOtherMeasureLabelVisible] = useState(false);
  const [emptyForm, setEmptyForm] = useState(false);
  const [resultados, setResultados] = useState([]);
  const [selectedExerciseFromRoutineStep, setSelectedExerciseFromRoutineStep] = useState(null);
  const [canUseMultipleCalls, setCanUseMultipleCalls] = useState(false);
  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  const breadcrumbRoutes = [
    {
      label: routineDescription,
      onPress: () => {
        navigation.navigate('StudentsRoutinesScreen', {});
      },
    },
    {
      label: trainingDayDescription,
      onPress: () => {
        navigation.navigate('StudentTrainingDaysListScreen', { routineId, routineDescription, refreshList: true });
      },
    },
    {
      label: trainingStepDescription,
      onPress: navigation.goBack,
    },
    {
      label: 'Ejercicios',
      onPress: () => {},
    },
  ];

  useEffect(() => {
    getTrainingStepExercises();
  }, [getTrainingStepExercises, trainingStepId]);

  const getTrainingStepExercises = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: `training-steps/${trainingStepId}/exercises`,
      });
      setTrainingStepExercises(data.trainingStepExercises);
      setFilteredExercises(data.trainingStepExercises.map(({ exercise }) => exercise));
      if (data.trainingStepExercises.length === 1) {
        setCanUseMultipleCalls(false);
        getMyMarks(data.trainingStepExercises[0].exercise_id);
      } else {
        setCanUseMultipleCalls(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [trainingStepId]);

  const getMyMarks = async (exerciseId) => {
    if (!userData.user) {
      return;
    }
    startLoading();
    const marks = await getExerciseMarks(exerciseId, routineId);
    setResultados(marks);
    stopLoading();
  };

  const saveResults = async (data) => {
    setIsTimeDetailsInputsVisible(false);
    if (!userData.user) {
      return;
    }
    const exerciseId = trainingStepExercises[selectedExerciseFromRoutineStep].exercise_id;
    const newExerciseResult = {
      ...data,
      exerciseId,
      routineId,
    };
    startLoading();
    const result = await storeMark(newExerciseResult);
    if (result.response === 200) {
      getMyMarks(exerciseId);
    } else {
      alert('No se añadió tu resultado. Por favor revisa los datos ingresados.');
    }
    stopLoading();
  };

  const openAddRoutineMarkModal = (index) => {
    setSelectedExerciseFromRoutineStep(index);
    setIsResultsRoutineModalVisible(!isResultsRoutineModalVisible);
  };
  const openHistorialModalAndGetSelected = (index) => {
    if (canUseMultipleCalls === true) {
      setSelectedExerciseFromRoutineStep(index);
      getMyMarks(filteredExercises[index].id);
    }
    setIsHistorialModalVisible(!isHistorialModalVisible);
  };
  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Ejercicios
            </Text>
            <TouchableOpacity disabled={isLoading} onPress={getTrainingStepExercises}>
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          <Breadcrumb routes={breadcrumbRoutes} />
          <View style={globalStyles.tableContainer}>
            {!!trainingStepObservations && (
              <Card
                key={`training-step-observations-${trainingStepId}`}
                style={[isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile]}
              >
                <Card.Content>
                  <Hyperlink linkDefault={true} linkStyle={{ fontWeight: '600', textDecorationLine: 'underline' }}>
                    <Paragraph style={globalStyles.normalText}>{trainingStepObservations}</Paragraph>
                  </Hyperlink>
                </Card.Content>
              </Card>
            )}
            {isLoading ? (
              <View style={globalStyles.loadingContainer}>
                <Spinner />
              </View>
            ) : (
              trainingStepExercises.map(
                (trainingStepExercise, index) =>
                  filteredExercises.some(({ id }) => id === trainingStepExercise.exercise.id) && (
                    <CardExercise
                      exercise={trainingStepExercise.exercise}
                      key={index}
                      index={index}
                      comments={trainingStepExercise.comments}
                      observations={trainingStepExercise.observations}
                      showTags={false}
                      showProgressButton={true}
                      setIsResultsRoutineModalVisible={(i) => openAddRoutineMarkModal(i)}
                      setIsHistorialModalVisible={(i) => openHistorialModalAndGetSelected(i)}
                    />
                  ),
              )
            )}
          </View>
        </View>
      </ScrollView>

      <ResultsRoutineForm
        visible={isResultsRoutineModalVisible}
        isTimeDetailsInputsVisible={isTimeDetailsInputsVisible}
        setIsTimeDetailsInputsVisible={setIsTimeDetailsInputsVisible}
        isOtraActividadInputsVisible={isOtraActividadInputsVisible}
        setIsOtraActividadInputsVisible={setIsOtraActividadInputsVisible}
        isNumberAvailableType={isNumberAvailableType}
        setIsNumberAvailableType={setIsNumberAvailableType}
        isNumberAvailableMeasure={isNumberAvailableMeasure}
        setIsNumberAvailableMeasure={setIsNumberAvailableMeasure}
        isOtherMeasureLabelVisible={isOtherMeasureLabelVisible}
        setIsOtherMeasureLabelVisible={setIsOtherMeasureLabelVisible}
        emptyForm={emptyForm}
        onDismiss={() => {
          setIsTimeDetailsInputsVisible(false);
          setIsOtraActividadInputsVisible(false);
          setIsResultsRoutineModalVisible(false);
        }}
        onConfirm={(values) => {
          if (
            (values.markTypeId === '' && values.measureTypeId === '') ||
            (isNaN(values.markTypeId) && isNaN(values.markTypeId)) ||
            (values.markValue.trim() === '' && values.measureValue.trim() === '' && values.measureTypeId !== 5)
          ) {
            setEmptyForm(true);
            return;
          }
          setEmptyForm(false);
          values.markValue = values.markValue.replace(',', '.');
          values.measureValue = values.measureValue.replace(',', '.');
          saveResults(values);

          setIsNumberAvailableMeasure(false);
          setIsNumberAvailableType(false);
          setIsResultsRoutineModalVisible(false);
          setIsOtraActividadInputsVisible(false);
          setIsOtherMeasureLabelVisible(false);
        }}
      />

      <HistorialModal
        title={'Historial de Ejercicios'}
        visible={isHistorialModalVisible}
        resultados={resultados}
        onDismiss={() => {
          setIsHistorialModalVisible(false);
        }}
      />
    </>
  );
};
