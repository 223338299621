import React, { useContext, useState, useEffect } from 'react';
import { ScrollView, View, FlatList, TouchableOpacity, Text, Platform } from 'react-native';
import { DataTable } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import CustomButton from 'components/CustomButton';
import Trainer from 'components/Trainer';
import CustomInput from 'components/CustomInput';
import FormTrainers from 'forms/FormTrainers';
import axiosInstance from 'services/axios';
import useMediumScreen from 'hooks/useMediumScreen';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import { hasSubscription } from 'helpers/plans';
import Spinner from 'components/Spinner';
import CustomTable from 'components/CustomTable';
import ConfirmationModal from 'components/ConfirmationModal';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/private/students';

export default ({ navigation }) => {
  const { isSubmitting, setIsSubmitting, userData, iosInit } = useContext(appContext);
  const [message, setMessage] = useState('');
  const [trainers, setTrainers] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [search, setSearch] = useState('');
  const [trainersInvited, setTrainersInvited] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trainerIdSelected, setTrainerIdSelected] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isMediumScreen = useMediumScreen();
  const isWeb = Platform.OS === 'web';

  const plan = userData?.user?.plan;
  const hasActiveSubscription = hasSubscription(userData);
  const showBaseGymError = !hasActiveSubscription;

  const getTrainersInvited = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: '/gym/trainers',
      });
      setTrainersInvited(data.trainers);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTrainersInvited();
  }, []);

  const disabledCreateTrainerBtn = () => {
    if (trainersInvited.length === plan?.guest_personal_trainers_cant) {
      return true;
    }
    if (isSubmitting) {
      return true;
    }
    return false;
  };

  const createTrainer = () => {
    if (!name.trim() || !email.trim()) {
      setMessage('Los campos son requeridos');
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setMessage('El formato de email no es válido');
      return;
    }

    if (trainers.some((trainer) => trainer.email === email)) {
      setMessage('El email: ' + email + ' ya existe en la lista de invitaciones, por favor ingrese otro');
      return;
    }

    if (showBaseGymError) {
      return;
    }

    const trainer = { email, name };
    setTrainers([...trainers, trainer]);
    setName('');
    setEmail('');
    setMessage('');
  };

  const updateTrainer = (updateName, updateEmail, currentEmail) => {
    if (trainers.some((trainer) => trainer.email === updateEmail) && updateEmail !== currentEmail) {
      setMessage('El email: ' + updateEmail + ' ya existe en la lista de invitaciones, por favor ingrese otro');
      return false;
    }

    const newTrainers = trainers.map((trainer) => {
      if (trainer.email === currentEmail) {
        trainer.name = updateName;
        trainer.email = updateEmail;
      }
      return trainer;
    });
    setTrainers(newTrainers);
    return true;
  };

  const deleteTrainer = (trainerEmail) => {
    const filteredTrainers = trainers.filter((trainer) => trainer.email !== trainerEmail);
    setTrainers(filteredTrainers);
  };

  const filterTrainer = trainers.filter(
    (trainer) =>
      trainer.name.toLowerCase().includes(search.toLowerCase()) ||
      trainer.email.toLowerCase().includes(search.toLowerCase()),
  );

  const sendInvitation = async () => {
    try {
      if (!hasActiveSubscription) {
        return;
      }
      setMessage('');
      setIsSubmitting(true);
      await axiosInstance({
        method: 'post',
        url: '/gym/invite-trainer',
        data: { trainers },
      });
      setMessage('Invitación enviada de manera exitosa!');
      setTrainers([]);
      setTimeout(function () {
        setMessage('');
      }, 3000);
      getTrainersInvited();
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setMessage(error.response.data.errors[0]);
      } else {
        setMessage(GENERIC_ERROR_MESSAGE);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteTrainerById = async (trainerId) => {
    try {
      setIsModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: '/gym/trainers/' + trainerId,
      });
      const newTrainers = trainersInvited.filter((trainer) => trainer.trainer_id !== trainerId);
      setTrainersInvited(newTrainers);
    } catch (error) {
      console.log(error);
    } finally {
      setTrainerIdSelected(null);
      setIsLoading(false);
    }
  };

  const showConfirmationModal = (trainerId) => {
    setTrainerIdSelected(trainerId);
    setIsModalVisible(true);
  };

  const renderRow = (trainer) => (
    <DataTable.Row key={`trainer-${trainer.trainer.id}`}>
      <DataTable.Cell style={globalStyles.tableCell}>
        <Text style={globalStyles.normalText}>{trainer.trainer.name}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <Text style={globalStyles.normalText}>{trainer.trainer.email}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        <Text style={globalStyles.normalText}>{trainer.active ? 'Activo' : 'Pendiente'}</Text>
      </DataTable.Cell>
      <DataTable.Cell style={globalStyles.tableCell}>
        {hasActiveSubscription ? (
          <TouchableOpacity onPress={() => showConfirmationModal(trainer.trainer_id)}>
            <Icon name="delete" size={22} color="#f2a172" />
          </TouchableOpacity>
        ) : (
          <Text style={globalStyles.normalText}>No disponible</Text>
        )}
      </DataTable.Cell>
    </DataTable.Row>
  );

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
        <View style={styles.studentsSection}>
          <View style={globalStyles.headerContainer}>
            {isWeb && (
              <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
                <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
              </TouchableOpacity>
            )}
            <Text
              style={[
                isWeb ? globalStyles.headerTitle : globalStyles.headerTitleForMobileNavigation,
                !isMediumScreen && globalStyles.headerTitleMobile,
              ]}
            >
              Entrenadores
            </Text>
            <TouchableOpacity disabled={isLoading} onPress={getTrainersInvited}>
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          <View style={globalStyles.tableContainer}>
            {hasActiveSubscription && !showBaseGymError && (
              <Text style={globalStyles.tableTitle}>Enviar invitación a un entrenador</Text>
            )}
            <View style={styles.studentsSection}>
              {!hasActiveSubscription && iosInit && (
                <View style={[globalStyles.noSubscriptionContainer, globalStyles.marginTop10]}>
                  <Text style={globalStyles.tableTitle}>
                    Para invitar entrenadores es requerida una suscripción de gimnasio
                  </Text>
                  <CustomButton
                    title="Ver suscripciones"
                    buttonColor="#bbebeb"
                    textColor="#3f3a40"
                    marginTop={30}
                    alignSelf="center"
                    onPress={() => {
                      navigation.navigate('MySubscriptionsScreen');
                    }}
                  />
                </View>
              )}
              {isSubmitting ? (
                <View style={styles.studentContainerSpinner}>
                  <Spinner />
                </View>
              ) : (
                <View
                  style={[
                    styles.studentsContainer,
                    hasActiveSubscription && !showBaseGymError && globalStyles.marginTop20,
                  ]}
                >
                  {hasActiveSubscription && (
                    <View
                      style={isMediumScreen ? styles.studentsContainerSection : styles.studentsContainerSectionMobile}
                    >
                      {!!message && <Text>{message}</Text>}
                      {!showBaseGymError && (
                        <FormTrainers
                          trainers={trainers}
                          setTrainers={setTrainers}
                          createTrainer={createTrainer}
                          setName={setName}
                          setEmail={setEmail}
                          email={email}
                          name={name}
                          disabled={disabledCreateTrainerBtn()}
                        />
                      )}
                      <View
                        style={[
                          styles.sendInvitationContainer,
                          hasActiveSubscription && showBaseGymError && globalStyles.marginTop10,
                        ]}
                      >
                        <CustomInput
                          title={!trainers.length ? 'No hay entrenadores' : 'Buscar entrenadores...'}
                          onChangeText={(text) => setSearch(text)}
                        />
                        <FlatList
                          data={filterTrainer}
                          keyExtractor={(trainer) => trainer.email}
                          renderItem={({ item }) => (
                            <Trainer item={item} deleteTrainer={deleteTrainer} updateTrainer={updateTrainer} />
                          )}
                        />
                        <CustomButton
                          title="Enviar invitación"
                          buttonColor="#bbebeb"
                          textColor="#3f3a40"
                          marginTop={30}
                          alignSelf="center"
                          onPress={sendInvitation}
                          disabled={isSubmitting || !trainers.length}
                        />
                      </View>
                    </View>
                  )}
                  <View style={globalStyles.tableContainer}>
                    <CustomTable
                      titles={['Nombre', 'Email', 'Estado', 'Acciones']}
                      rows={trainersInvited}
                      renderRow={renderRow}
                      isLoading={isLoading}
                    />
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
      <ConfirmationModal
        visible={isModalVisible}
        title="Atención"
        description="¿Estás seguro de eliminar al entrenador?"
        onDismiss={() => setIsModalVisible(false)}
        onConfirm={() => deleteTrainerById(trainerIdSelected)}
      />
    </>
  );
};
