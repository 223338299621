import React, { useCallback, useEffect, useState, useContext } from 'react';
import { ScrollView, Text, TouchableOpacity, View, SafeAreaView, Platform } from 'react-native';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import { getPersonalizedPlans } from 'services/planService';
import useMediumScreen from 'hooks/useMediumScreen';
import axiosInstance from 'services/axios';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import PricingContainer from 'components/PricingContainer';
import ConfirmationModal from 'components/ConfirmationModal';
import { globalStyles } from 'styles/global';
import { styles } from 'src/styles/screens/private/subscriptions';

const Subscriptions = (props) => {
  const { navigation } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [planSelected, setPlanSelected] = useState([]);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isSubscribeModalVisible, setIsSubscribeModalVisible] = useState(false);
  const [message, setMessage] = useState(null);
  const { userData, setUserData } = useContext(appContext);
  const getPlansCallback = useCallback(async () => {
    setIsLoading(true);
    const data = await getPersonalizedPlans();
    setPlans(data.plans);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getPlansCallback();
  }, [getPlansCallback]);

  const isMediumScreen = useMediumScreen();
  const isWeb = Platform.OS === 'web';

  const onCancelPress = (plan) => {
    setPlanSelected(plan);
    setIsCancelModalVisible(true);
  };

  const cancelSubscription = async () => {
    try {
      setIsCancelModalVisible(false);
      setIsLoading(true);
      const { data } = await axiosInstance({
        method: 'post',
        url: '/payments/cancel',
        data: { planId: planSelected?.id },
      });
      setMessage(data.message);
      const { token, user } = userData;
      setUserData({ token, user: { ...user, plan: null, subscription: null } });
    } catch (error) {
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setMessage(error.response.data.errors[0]);
      } else {
        setMessage(GENERIC_ERROR_MESSAGE);
      }
    } finally {
      setPlanSelected(null);
      setIsLoading(false);
      setTimeout(() => setMessage(null), 3000);
    }
  };

  const onSubscribePress = (plan) => {
    if (!userData.user?.plan) {
      navigation.navigate('PaymentScreen', { priceId: plan.stripe_price_id });
    } else {
      setPlanSelected(plan);
      setIsSubscribeModalVisible(true);
    }
  };

  const updateSubscription = () => {
    if (planSelected) {
      navigation.navigate('PaymentScreen', { priceId: planSelected.stripe_price_id });
      setPlanSelected(null);
      setIsSubscribeModalVisible(false);
    }
  };

  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={[globalStyles.scrollViewContainer, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}
      >
        <View style={globalStyles.viewContainer}>
          <View style={globalStyles.headerContainer}>
            <TouchableOpacity disabled={isLoading} onPress={navigation.goBack}>
              <Icon name="arrow-back" color="white" size={20} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
            <Text style={[globalStyles.headerTitle, !isMediumScreen && globalStyles.headerTitleMobile]}>
              Suscripciones
            </Text>
            <TouchableOpacity disabled={isLoading} onPress={getPlansCallback}>
              <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
            </TouchableOpacity>
          </View>
          {!!message && <Text style={globalStyles.normalText}>{message}</Text>}
          <PricingContainer
            filterByRole
            isLoading={isLoading}
            navigation={navigation}
            onCancelPress={onCancelPress}
            onSubscribePress={onSubscribePress}
            plans={plans}
          />
        </View>
      </ScrollView>
      <ConfirmationModal
        visible={isCancelModalVisible}
        title="Estás seguro de cancelar la suscripción?"
        description="Se realizará la cancelación de la suscripción y no podrá contar más con los beneficios de la misma"
        onDismiss={() => {
          setIsCancelModalVisible(false);
          setPlanSelected(null);
        }}
        onConfirm={cancelSubscription}
      />
      <ConfirmationModal
        visible={isSubscribeModalVisible}
        title="Atención"
        description="Está cancelando la suscripción actual y creando una nueva, desea continuar?"
        onDismiss={() => {
          setIsSubscribeModalVisible(false);
          setPlanSelected(null);
        }}
        onConfirm={updateSubscription}
      />
    </SafeAreaView>
  );
};

export default Subscriptions;
