import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  exerciseTitle: {
    fontSize: 18,
    fontFamily: 'gilroy-semibold',
  },
  textContainer: {
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    marginHorizontal: 16,
    marginVertical: 15,
  },
  isPreviewStyle: {
    width: '100%',
    fontWeight: 'bold',
  },
  exerciseColor: {
    color: '#c33e1d',
  },
  infoContainer: {
    marginHorizontal: 'auto',
    width: '50%',
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    marginTop: 10,
  },
});
