import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import useMediumScreen from 'hooks/useMediumScreen';
import Spinner from 'components/Spinner';
import FormInviteUser from 'forms/FormInviteUser';
import AcceptedInvitation from 'components/AcceptedInvitation';
import appContext from 'contexts/appContext';
import { isMobileDevice } from 'helpers/devices';
import CustomButton from 'components/CustomButton';
import { styles } from 'styles/screens/auth/Register';
import { globalStyles } from 'styles/global';
import { GENERIC_ERROR_MESSAGE } from 'constants/generic';
import axiosInstance from 'services/axios';

export default ({ navigation, route }) => {
  const isMediumScreen = useMediumScreen();
  const { isSubmitting, setIsSubmitting } = useContext(appContext);
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [trainerAlreadyRegistered, setTrainerAlreadyRegistered] = useState(false);
  const formikForm = useRef(null);
  const token = route.params?.token;
  const isMobile = isMobileDevice();

  const checkTrainerAlreadyRegister = async () => {
    try {
      setSuccess(false);
      setIsSubmitting(true);
      const { data } = await axiosInstance({
        method: 'get',
        url: `gym/check-trainer-already-registered/${token}`,
      });
      setMessage(data.message);
      setTrainerAlreadyRegistered(data.result);
      setSuccess(data.result);
    } catch (error) {
      setSuccess(false);
      setTrainerAlreadyRegistered(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (values) => {
    try {
      setSuccess(false);
      setIsSubmitting(true);
      await axiosInstance({
        method: 'post',
        url: '/gym/accept-invitation',
        data: {
          email: values.email,
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          token,
        },
      });
      setFormError(false);
      setMessage(
        '¡Genial! Te hemos enviado un correo de verificación para finalizar tu registro, comprueba tu bandeja de entrada.' +
          (isMobile ? ' Por favor vuelva a ingresar a la aplicación desde su dispositivo.' : ''),
      );
      setSuccess(true);
    } catch (error) {
      setFormError(true);
      const response = error?.response;
      if (response?.status === 422 && !!response?.data?.errors?.length) {
        setMessage(error.response.data.errors[0]);
      } else {
        setMessage(GENERIC_ERROR_MESSAGE);
      }
      setSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    checkTrainerAlreadyRegister();
  }, []);

  useFocusEffect(
    useCallback(() => {
      return () => {
        formikForm.current?.resetForm();
        setMessage('');
        setFormError(false);
        setIsSubmitting(false);
      };
    }, [setIsSubmitting]),
  );

  return (
    <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
      <View style={styles.titleInfoContainer}>
        <Text style={globalStyles.title}>Invitación confirmada</Text>
        <Text style={styles.registerDescription}>
          Bienvenido/a a met app, la plataforma que te ayudará a aumentar tu productividad elaborando programaciones
          para tus clientes de forma profesional en poco tiempo. Regístrate y haz crecer tu negocio.
        </Text>
      </View>
      {isSubmitting ? (
        <View style={styles.registerContainerSpinner}>
          <Spinner />
        </View>
      ) : (
        <View
          style={[
            globalStyles.contentCenteredColumn,
            isMediumScreen ? styles.registerContainer : styles.registerContainerMobile,
          ]}
        >
          {success ? (
            <>
              {trainerAlreadyRegistered ? (
                <>
                  <AcceptedInvitation message={message} navigation={navigation} />
                </>
              ) : (
                <>
                  <Text style={globalStyles.successFormText}>{message}</Text>
                  {!isMobile && (
                    <CustomButton
                      title="Inicia sesión"
                      buttonColor="#bbebeb"
                      textColor="#3f3a40"
                      alignSelf="center"
                      onPress={() => navigation.navigate('Main', { screen: 'Login' })}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <FormInviteUser
              message={message}
              formError={formError}
              formikForm={formikForm}
              isSubmitting={isSubmitting}
              onSubmit={onSubmit}
            />
          )}
        </View>
      )}
    </ScrollView>
  );
};
