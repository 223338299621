import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  studentsSection: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  studentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  studentContainerSpinner: {
    height: 200,
  },
  titleInvite: {
    fontFamily: 'gilroy-regular',
    fontSize: 24,
    marginBottom: 8,
    textAlign: 'center',
  },
  inputContainer: {
    width: '100%',
  },
  studentsContainerSection: {
    flexDirection: 'column',
    alignSelf: 'center',
    width: '50%',
  },
  studentsContainerSectionMobile: {
    flexDirection: 'column',
    alignSelf: 'center',
    width: '80%',
  },
  sendInvitationContainer: {
    flex: 1,
    flexDirection: 'column',
    marginTop: 30,
  },
  flexIcons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexInputs: {
    flexDirection: 'column',
  },
  buttonsContainer: {
    flexDirection: 'row',
  },
  tableContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 50,
    width: '100%',
  },
  webTableContainer: {
    width: '90%',
  },
  icon: {
    marginLeft: 10,
  },
  disabledInvitationAlertContainer: {
    backgroundColor: '#FFF3CD',
    borderColor: '#FFEEBA',
    borderEndColor: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    marginTop: 10,
    marginBottom: 1,
    paddingTop: 5,
    paddingRight: 5,
    paddingLeft: 10,
    paddingBottom: 5,
  },
  disabledInvitationAlertText: {
    color: '#856404',
  },
  myStudentsTitle: {
    marginTop: 30,
    fontSize: 18,
  },
  searchInputContainer: {
    marginTop: 10,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  searchWebContainer: {
    width: '40%',
  },
  searchMobileContainer: {
    width: '100%',
  },
  searchInput: {
    height: 40,
    borderBottomWidth: 1,
    fontFamily: 'gilroy-regular',
    color: '#3f3a40',
    fontSize: 18,
    borderColor: '#8a8a8a',
  },
  iconAndDescriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    minWidth: 150,
  },
});
