import React from 'react';
import { View, ActivityIndicator, Text } from 'react-native';
import { styles } from 'styles/components/spinner';

export default ({ message }) => (
  <View style={styles.spinner}>
    <ActivityIndicator size="large" color="#c33e1d" />
    {!!message && <Text style={styles.spinnerMessage}>{message}</Text>}
  </View>
);
