const sortByOrder = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};

export const reSortItemsByOrder = (array, index) => {
  const previousIndex = index - 1;
  array[index].order -= 1;
  array[previousIndex].order += 1;
  const orderedArray = array.sort(sortByOrder);
  return orderedArray.map((item) => item);
};

export const getRequestToReOrder = (array) => {
  return array.map((item) => ({
    id: item.id,
    order: item.order,
  }));
};
