import React, { useContext } from 'react';
import { ScrollView, View, Text, TouchableOpacity, Platform, Linking } from 'react-native';
import useMediumScreen from 'hooks/useMediumScreen';
import FormLogin from 'forms/FormLogin';
import appContext from 'contexts/appContext';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/screens/auth/Login';

export default ({ navigation }) => {
  const isMediumScreen = useMediumScreen();
  const { isSubmitting, setIsSubmitting } = useContext(appContext);

  return (
    <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={globalStyles.scrollViewContainer}>
      <View style={[isMediumScreen ? globalStyles.flexRow : globalStyles.flexColumn, styles.loginContainer]}>
        <View style={[styles.container, isMediumScreen && styles.containerWeb]}>
          <FormLogin navigation={navigation} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
          <TouchableOpacity
            onPress={() => navigation.navigate('Main', { screen: 'OlvideMiContrasena' })}
            style={styles.bottomTextContainer}
          >
            <Text style={[globalStyles.normalText, styles.bottomText]}>He olvidado mi contraseña</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              const polyticsUrl = 'https://mettheapp.com/policy.html';
              if (Platform.OS === 'web') {
                window.open(polyticsUrl, '_blank');
              } else {
                Linking.openURL(polyticsUrl);
              }
            }}
            style={styles.bottomTextContainer}
          >
            <Text style={[globalStyles.normalText, styles.bottomText, styles.polyticsLinkText]}>
              Políticas de privacidad de Met APP
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
};
