import React, { useState } from 'react';
import { Platform, View, Text, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import { Picker } from '@react-native-picker/picker';
import Modal from 'react-native-modal';
import { Button, Dialog } from 'react-native-paper';
import useMediumScreen from 'hooks/useMediumScreen';
import { globalStyles } from 'styles/global';
import { styles } from 'styles/components/customDropDown';

export default ({ title, items, pickerValue, setPickerValue }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(null);

  const isMediumScreen = useMediumScreen();
  const isWeb = Platform.OS === 'web';
  const isIOS = Platform.OS === 'ios';
  const isAndroid = Platform.OS === 'android';
  const currentItem = items.find((item) => item.value === pickerValue);

  const onOpen = () => {
    setValue(pickerValue);
    setModalOpen(true);
  };

  const onDismiss = () => {
    setModalOpen(false);
    setValue(null);
  };

  const onConfirm = () => {
    setPickerValue(value);
    onDismiss();
  };

  const renderPicker = () => (
    <Picker
      style={[isWeb && styles.pickerWeb, globalStyles.normalText, isAndroid && styles.pickerAndroid]}
      itemStyle={globalStyles.normalText}
      selectedValue={isIOS ? value : pickerValue}
      onValueChange={isIOS ? setValue : setPickerValue}
    >
      <Picker.Item key={0} value="" label={`${title}...`} />
      {items.map((item, index) => (
        <Picker.Item key={index + 1} label={item.label} value={item.value} />
      ))}
    </Picker>
  );

  return (
    <>
      <View style={isMediumScreen ? styles.containerMedium : styles.container}>
        <View style={[!isWeb && styles.picker, isAndroid && styles.pickerAndroidContainer]}>
          {isIOS ? (
            <TouchableOpacity style={styles.pickerIOS} onPress={onOpen}>
              <Text style={globalStyles.normalText}>{currentItem?.label || `${title}...`}</Text>
              <Icon name="expand-more" color="#3f3a40" />
            </TouchableOpacity>
          ) : (
            renderPicker()
          )}
        </View>
      </View>

      {isIOS && (
        <Modal isVisible={isModalOpen} onBackdropPress={onDismiss}>
          <View style={styles.modalContainer}>
            {renderPicker()}
            <Dialog.Actions>
              <Button onPress={onDismiss} labelStyle={globalStyles.normalText}>
                Cancelar
              </Button>
              <Button onPress={onConfirm} labelStyle={globalStyles.normalText}>
                Aceptar
              </Button>
            </Dialog.Actions>
          </View>
        </Modal>
      )}
    </>
  );
};
