import React from 'react';
import { View, Text, TextInput } from 'react-native';
import { Formik } from 'formik';
import CustomButton from 'components/CustomButton';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/forms/formRegister';
import inviteUserSchema from './schemas/formInviteUserSchema';

export default ({ message, formError, formikForm, isSubmitting, onSubmit }) => (
  <View style={styles.formContainer}>
    <Formik
      innerRef={formikForm}
      validationSchema={inviteUserSchema}
      initialValues={{
        email: '',
        password: '',
        passwordConfirmation: '',
      }}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
        <>
          <View>
            <View style={styles.firstContainer}>
              {formError ? (
                <Text style={globalStyles.inputErrorText}>{message}</Text>
              ) : (
                <Text style={globalStyles.successFormText}>{message}</Text>
              )}
              <TextInput
                style={[globalStyles.textInput, outlineStyle]}
                name="email"
                placeholder="Email"
                onChangeText={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                keyboardType="email-address"
              />
              {errors.email && touched.email && <Text style={globalStyles.inputErrorText}>{errors.email}</Text>}
              <TextInput
                style={[globalStyles.textInput, outlineStyle]}
                name="password"
                placeholder="Contraseña"
                onChangeText={handleChange('password')}
                onBlur={handleBlur('password')}
                value={values.password}
                secureTextEntry
              />
              {errors.password && touched.password && (
                <Text style={globalStyles.inputErrorText}>{errors.password}</Text>
              )}
              <TextInput
                style={[globalStyles.textInput, outlineStyle]}
                name="passwordConfirmation"
                placeholder="Confirmar contraseña"
                onChangeText={handleChange('passwordConfirmation')}
                onBlur={handleBlur('passwordConfirmation')}
                value={values.passwordConfirmation}
                secureTextEntry
              />
              {errors.passwordConfirmation && touched.passwordConfirmation && (
                <Text style={globalStyles.inputErrorText}>{errors.passwordConfirmation}</Text>
              )}
              <View style={[styles.submitButton, globalStyles.contentCenteredRow]}>
                <CustomButton
                  title="Registrarse"
                  buttonColor="#bbebeb"
                  textColor="#3f3a40"
                  marginTop={20}
                  alignSelf="center"
                  onPress={handleSubmit}
                  disabled={!isValid || isSubmitting}
                />
              </View>
            </View>
          </View>
        </>
      )}
    </Formik>
  </View>
);
