import React, { useContext, useState, useEffect, useCallback } from 'react';
import { ScrollView, Text, TouchableOpacity, View, Image, SafeAreaView, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Switch } from 'react-native-paper';
import { Icon } from 'react-native-elements';
import appContext from 'contexts/appContext';
import axiosInstance from 'services/axios';
import { globalStyles } from 'styles/global';
import { styles } from 'src/styles/screens/private/publications';
import Spinner from 'src/components/Spinner';
import { BASE_URL } from '@env';
import useMediumScreen from 'src/hooks/useMediumScreen';
import { isGym, isStudent, isTrainer } from 'helpers/roles';
import ConfirmationModal from 'src/components/ConfirmationModal';

const Publications = (props) => {
  const { navigation } = props;
  const isMediumScreen = useMediumScreen();
  const [publications, setPublications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPublication, setSelectedPublication] = useState(null);
  const [onlyMyPublications, setOnlyMyPublications] = useState(false);
  const { userData } = useContext(appContext);
  const [isConfirmationDeletePublicationModalVisible, setIsConfirmationDeletePublicationModalVisible] = useState(false);
  const isWeb = Platform.OS === 'web';

  useFocusEffect(
    React.useCallback(() => {
      getPublications();
    }, [getPublications]),
  );

  useEffect(() => {
    getPublications(onlyMyPublications);
  }, [getPublications, onlyMyPublications]);

  const getPublications = useCallback(async (onlyMyPublicationsFlag) => {
    setIsLoading(true);
    try {
      let url = 'user/publications';
      if (onlyMyPublicationsFlag) {
        url += '?onlyMyPublications=true';
      }
      const { data } = await axiosInstance({
        method: 'get',
        url,
      });
      setPublications(data.publications);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deletePublication = async () => {
    try {
      setIsConfirmationDeletePublicationModalVisible(false);
      setIsLoading(true);
      await axiosInstance({
        method: 'delete',
        url: 'publications/' + selectedPublication.id,
      });
      getPublications();
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedPublication(null);
    }
  };

  const showNewPublicationBtn = () => {
    if (isStudent(userData)) {
      return false;
    }
    return true;
  };

  const showOnlyMyPublicationsSwitch = () => {
    if (!isTrainer(userData)) {
      return false;
    }
    return true;
  };

  const getNoPublicationMessage = () => {
    if (onlyMyPublications || isGym(userData)) {
      return 'No tienes noticias creadas';
    } else if (isTrainer(userData)) {
      return 'No hay noticias por parte del gimnasio';
    } else if (isStudent(userData)) {
      return 'No hay noticias por parte de tus entrenadores';
    }
  };

  const PublicationItem = ({ publication }) => {
    return (
      <TouchableOpacity
        style={[isMediumScreen ? globalStyles.cardContainer : globalStyles.cardContainerMobile, styles.publicationItem]}
        onPress={() => {
          navigation.navigate('PublicationDetailScreen', {
            publicationId: publication.id,
            title: publication.title.replaceAll(' ', '-'),
          });
        }}
      >
        <View>
          <Image
            resizeMode="cover"
            source={{
              uri: BASE_URL + publication.image,
            }}
            style={styles.publicationImage}
          />
        </View>
        <View style={styles.publicationBody}>
          <View style={styles.publicationBodyItem}>
            <Text numberOfLines={2} style={styles.publicationTitleText}>
              {publication.title}
            </Text>
          </View>
          <View style={styles.publicationBodyItem}>
            <Text numberOfLines={5} style={styles.publicationContentText}>
              {publication.content}
            </Text>
          </View>
        </View>
        {userData?.user.id === publication.user_id && (
          <View style={styles.publicationActionsContainer}>
            <Icon
              name="delete"
              size={24}
              color="#f2a172"
              onPress={() => {
                setSelectedPublication(publication);
                setIsConfirmationDeletePublicationModalVisible(true);
              }}
            />
            <Icon
              name="edit"
              size={24}
              color="#f2a172"
              onPress={() => {
                navigation.navigate('EditPublicationScreen', {
                  publicationId: publication.id,
                });
              }}
            />
          </View>
        )}
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={[styles.containerForSafeArea, !isWeb && globalStyles.upperMarginForNoHeaderScreens]}>
      {isLoading ? (
        <View style={globalStyles.loadingContainer}>
          <Spinner />
        </View>
      ) : (
        <ScrollView keyboardShouldPersistTaps="handled">
          <View style={styles.publicationsHeader}>
            <View style={[styles.publicationsTitle]}>
              <Text style={globalStyles.headerTitle}>Noticias</Text>
              <TouchableOpacity
                disabled={isLoading}
                style={styles.refreshIcon}
                onPress={() => {
                  getPublications();
                }}
              >
                <Icon name="update" color="white" size={22} style={globalStyles.headerTitleIcon} />
              </TouchableOpacity>
            </View>
            {showNewPublicationBtn() && (
              <TouchableOpacity
                style={styles.newPublicationContainer}
                onPress={() => {
                  navigation.navigate('CreatePublicationScreen');
                }}
              >
                <Text style={styles.newPublicationText}>Nueva noticia</Text>
                <Icon name="add-circle" size={22} color="#f2a172" />
              </TouchableOpacity>
            )}
          </View>
          {showOnlyMyPublicationsSwitch() && (
            <View style={styles.onlyMyPublicationsContainer}>
              <View style={styles.onlyMyPublicationsText}>
                <Text style={globalStyles.normalText}>Solo mis publicaciones</Text>
              </View>
              <Switch
                value={onlyMyPublications}
                onValueChange={() => {
                  setOnlyMyPublications(!onlyMyPublications);
                }}
                style={styles.onlyMyPublicationsSwitch}
                color="#f2a172"
              />
            </View>
          )}
          <View style={[styles.publicationsContainer, isMediumScreen && styles.webContainer]}>
            {publications.map((publication, index) => (
              <PublicationItem key={index} publication={publication} />
            ))}
            {publications.length === 0 && <Text>{getNoPublicationMessage()}</Text>}
          </View>
        </ScrollView>
      )}
      <ConfirmationModal
        visible={isConfirmationDeletePublicationModalVisible}
        title="Atención"
        description="¿Está seguro que desea eliminar esta publicación?"
        onDismiss={() => {
          setIsConfirmationDeletePublicationModalVisible(false);
        }}
        onConfirm={deletePublication}
      />
    </SafeAreaView>
  );
};

export default Publications;
