import * as yup from 'yup';

export default () =>
  yup.object().shape({
    email: yup.string().email('Ingrese un email válido').required('El campo email es obligatorio'),
    password: yup
      .string()
      .min(8, ({ min }) => `Debe tener al menos ${min} caracteres`)
      .required('El campo contraseña es obligatorio'),
  });
