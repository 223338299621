import React, { useRef } from 'react';
import { Button, Dialog, Portal, Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import { Text, TextInput } from 'react-native';
import { Formik } from 'formik';
import useMediumScreen from 'hooks/useMediumScreen';
import formFolderSchema from 'forms/schemas/formFolderSchema';
import CustomButton from './CustomButton';
import { globalStyles, outlineStyle } from 'styles/global';
import { styles } from 'styles/components/folderFormModal';

const FolderFormModal = (props) => {
  const { folder, visible, title, onDismiss, onSubmit } = props;
  const isMediumScreen = useMediumScreen();
  const formikForm = useRef(null);
  const initialValues = { id: folder?.id ?? '', description: folder?.description ?? '' };

  return (
    <PaperProvider theme={DefaultTheme}>
      <Portal theme={DefaultTheme}>
        <Dialog
          onDismiss={onDismiss}
          visible={visible}
          style={isMediumScreen ? styles.containerWeb : styles.containerMobile}
        >
          <Dialog.Title style={globalStyles.normalText}>{title}</Dialog.Title>
          <Dialog.Content>
            <Formik
              innerRef={formikForm}
              validationSchema={formFolderSchema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values) => onSubmit(values)}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched, isValid }) => (
                <>
                  <TextInput
                    style={[globalStyles.textInput, outlineStyle]}
                    name="description"
                    placeholder="Nombre de la carpeta"
                    onChangeText={handleChange('description')}
                    onBlur={handleBlur('description')}
                    value={values.description}
                  />
                  {errors.description && touched.description && (
                    <Text style={globalStyles.inputErrorText}>{errors.description}</Text>
                  )}
                  <CustomButton
                    title={folder ? 'Editar' : 'Crear'}
                    buttonColor="#bbebeb"
                    textColor="#3f3a40"
                    marginTop={30}
                    alignSelf="center"
                    onPress={handleSubmit}
                    disabled={!isValid}
                  />
                </>
              )}
            </Formik>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={onDismiss} labelStyle={globalStyles.normalText}>
              Cancelar
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </PaperProvider>
  );
};

export default FolderFormModal;
