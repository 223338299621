import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: 300,
    backgroundColor: '#FFF',
    paddingTop: 20,
    alignSelf: 'center',
  },
  inputsCtn: {
    flexDirection: 'column',
    marginBottom: 20,
    width: '100%',
  },
  selectDropdown: {
    height: 45,
    width: '100%',
    marginHorizontal: 0,
    borderRadius: 3,
    backgroundColor: '#EFEFEF',
    fontFamily: 'gilroy-regular',
    fontSize: 16,
    color: '#3f3a40',
    textAlign: 'left',
    marginBottom: 3,
    paddingHorizontal: 14,
    cursor: 'pointer',
    border: 'none',
  },
  optionStyles: {
    borderBottomColor: '#3f3a40',
    fontSize: 14,
    paddingHorizontal: 10,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    borderRadius: 3,
    margin: 0,
    marginBottom: 0,
    fontSize: 16,
  },
  timeInput: {
    fontSize: 16,
    fontFamily: 'gilroy-regular',
  },
  inputMultiline: {
    padding: 10,
    borderRadius: 0,
    height: 60,
    width: '100%',
    borderRadius: 3,
    marginLeft: 0,
    fontSize: 16,
  },
  selectCtn: {
    width: '100%',
    borderRadius: 5,
    marginBottom: 5,
  },
  rowInputsCtn: {
    marginBottom: 20,
  },
  dropdownStyle: {
    height: 'auto',
    fontFamily: 'gilroy-regular',
  },
  buttonSelect: {
    textAlign: 'left',
    fontSize: 16,
  },
  rowTextStyle: {
    paddingVertical: 10,
    textTransform: 'capitalize',
    fontFamily: 'gilroy-regular',
    fontSize: 16,
  },

  timeInputsCtn: {
    flexDirection: 'row',
    gap: 3,
  },
  timeInputs: {
    width: 50,
    flex: 1,
    borderWidth: 1,
    borderColor: 'rgb(128, 128, 128)',
    padding: 5,
    color: 'rgb(63, 58, 64)',
  },
  otraActividadInputCtn: {
    marginBottom: 10,
    height: 35,
  },
  otraActividadInput: {
    padding: 5,
    paddingHorizontal: 12,
    width: '100%',
  },
  capitalizeText: {
    textTransform: 'capitalize',
  },
  errorText: {
    fontFamily: 'gilroy-regular',
    color: 'red',
    textAlign: 'center',
    paddingBottom: 5,
  },
});
